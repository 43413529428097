import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import COUNTRIES from "../countries"
import TYPECOUVERTURE from "../typeCourverture"
import { getEntity } from '../../services/api';


export default function SelectComponent({handleChangePays,defaultValue,placeholder}) {
  const [selectedOption, setSelectedOption] = useState(null);
 
  const countries = [];

  for (const key in COUNTRIES) {
    countries.push({ value: `${COUNTRIES[key]}`, label: `${COUNTRIES[key]}`})
  }


  return (
    <div className="App">
      <Select
        defaultValue={defaultValue}
        onChange={handleChangePays}
        options={countries}
        isSearchable={true}
        placeholder={placeholder}
      />
    </div>
  );
}

export function SelectComponentMulti({handleChangeMulti,defaultValue,placeholder}) {
  const [besoin, setBesoin] = useState([]);
  const [optionbesoins, setOptionbesoins] = useState([]);

  useEffect(()=>{
    //couvertureexistentes
    getEntity("/besoins").then((res) => {
      const nouvellesOptions = res.data.data.map((item) => ({
        value: item.id,
        label: item.titre,
      }));
      setOptionbesoins((prevOptions) => [
        ...prevOptions,
        ...nouvellesOptions,
      ]);

    }).catch((err) => {
      console.log(err.message);
    });
 },[]);
 /*  const typeCourverture = [];

  for (const item in besoin) {
    console.log(besoin);
    typeCourverture.push({ value: `${item.id}`, label: `${item.titre}`})
  }
 */
  return (
    <div className="App">
      <Select
        isMulti
        defaultValue={defaultValue}
        onChange={handleChangeMulti}
        options={optionbesoins}
        isSearchable={true}
        placeholder={placeholder}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    </div>
  );
}