import React, { useRef, Component, useState, useEffect } from 'react';
import { getEntity } from '../services/api';
import Autosuggest from 'react-autosuggest';
import { Link, useLocation } from 'react-router-dom';

export default function Search() {

    const [postes, setPostes] = useState([]);
    const [filteredPostes, setFilteredPostes] = useState([]);
    const [search, setSearch] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionsVisible, setSuggestionsVisible] = useState(true);
    const suggestionListRef = useRef(null);
    //const navigate = useNavigate();

    /*  const handleSearch = () => {
         if (search.trim() !== '') {
             navigate(`/Postes/bibliotheque?searchTitre=${encodeURIComponent(search)}`);
         }
     }; */

    useEffect(() => {
       
        if (search.trim() === '') {
            return;
        }

        getEntity(`/postes/search?titre=${search}`).then((res) => {
            setPostes(res.data.data)
            setFilteredPostes(res.data.data);
        }).catch((err) => {
            console.log(err.message);
        })
    }, [search])

    const handleFiltre = ({ search }) => {
        if (!search) {
            setFilteredPostes(postes); // Réinitialiser avec la liste complète
            return;
        }

        const filtered = postes.filter((item) => {
            const titleMatch = search ? item.titre.toLowerCase().includes(search.toLowerCase()) : true;
            /*  const authorMatch = searchAuteur ? item.auteur.toLowerCase().includes(search.toLowerCase()) : true; */
            return titleMatch/* , authorMatch */;
        });
        setFilteredPostes(filtered);
    };

    /* const handleFiltre = ({ search }) => {
      setSearch(search); // Mettre à jour search avec le titre de recherche
    }; */

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestionsVisible(true);
        // Mettre à jour les suggestions en fonction de la valeur de recherche actuelle
        // Vous pouvez également effectuer une requête API ici si nécessaire
        const filteredSuggestions = postes.filter((document) =>
            document.titre.toLowerCase().includes(value.toLowerCase())
        );
        console.log(filteredSuggestions);
        setSuggestions(filteredSuggestions);
    };

    const onSuggestionsClearRequested = () => {
        // Effacer les suggestions lorsque l'entrée de l'utilisateur est effacée
        setSuggestions([]);
    };

    const getSuggestionValue = (suggestion) => suggestion.titre;
    const renderSuggestion = (suggestion) => <div>{suggestion.titre}</div>;

    const inputProps = {
        placeholder: 'Rechercher...',
        value: search,
        //onChange: (e, { newValue }) => handleFiltre({ search: newValue }),
        onChange: (e, { newValue }) => setSearch(newValue),
        /*  onKeyDown: (e) => {
             if (e.key === 'Enter') {
                 handleSearch();
             }
         }, */
    };

    // Ajoutez un gestionnaire d'événements pour les clics sur le document
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionListRef.current && !suggestionListRef.current.contains(event.target)) {
                setSuggestionsVisible(false);
                
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [suggestionListRef]);
    return (

      
        <div className="centered-form">
            {/* Votre interface utilisateur */}
            <div className="searchBar">
                <div className="container_searchBar flex justify-between items-center">
                    <Autosuggest
                        suggestions={[]}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                    />
                    {/*  <span style={{marginTop:"-10px"}}  className="flex justify-center items-center">
                        <i  className={`fa fa-search ${suggestionsVisible ? 'visible' : 'hidden'}`} ref={suggestionListRef}></i>
                    </span>  */}
                </div>
            </div>

            {/* Affichez les Postes filtrés ici */}
            {filteredPostes.length > 0 && (
                <div className={`suggestion-container ${suggestionsVisible ? 'visible' : 'hidden'}`} ref={suggestionListRef}>
                    <div className=" bg-white suggestion_search ">
                        <ul className='p-2 suggestion_list'>
                            {filteredPostes.map((poste, index) => (
                                <span className='p-1' key={index}>
                                    <a style={{ color: "black" }} href={`/blogdetails/${poste.slug}`}>{poste.titre}</a>
                                    {index < filteredPostes.length - 1 && <hr className="suggestion-divider" />}
                                </span>
                            ))}
                        </ul>
                    </div>
                </div>
            )}

            {/* Affichez la pagination ici si nécessaire */}
        </div>
    );
}