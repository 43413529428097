import React, { Suspense, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import aurelie from "../../styles/img/image-aurelie.webp";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { apiURL, getEntity } from "../../../src/services/api";
import ReactHtmlParser from 'react-html-parser';

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import Carousel from "react-bootstrap/Carousel";
//import ExampleCarouselImage from "components/ExampleCarouselImage";

export default function HeroSection({dataphoto}) {

  //const [isLoading, setIsLoading] = useState(true);
  /* const [dataphoto, empdataphoto] = useState([]);

  useEffect(() => {

    getEntity("/acceuil").then((res) => {
      empdataphoto(res.data.data);


    }).catch((err) => {
      console.log(err.message);

    });


  }, []); */

  return (
    <div className="home-area home-slides-two ">


      <Swiper
        autoplay={{ delay: 3000, disableOnInteraction: true }}
        //observer={"true"}
        //observeslidechildren={"true"}
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={15}
        slidesPerView={1}
        navigation
        //pagination={{ clickable: true }}
        // scrollbar={{ draggable: true }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => {
          setTimeout(() => {
            swiper.update(); // ------> this solution
          }, 3000);
        }}
      >
        {dataphoto?.map((item, index) => (
          <SwiperSlide key={index} >
            <div className="banner-section banner" style={{ backgroundImage: `radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(0, 0, 0, 0) 0%),url(${apiURL}${item.photo})` }}>
              <div className="d-table">
                <div className="d-table-cell">
                  <div className="container">
                    <div className="main-banner-content">
                      <h1 data-aos="slide-up">
                        {item.titre}
                      </h1>
                      <div className="" data-aos="fade-left">
                        <span className="sub-title">
                          {ReactHtmlParser(item.description)} 

                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

        ))}
      </Swiper>

    </div>
  );
}
