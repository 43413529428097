import React from 'react';
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import '../styles/notfound.css';
export default function NotFound() {
    return (
        <>
            <div id="notfound">
                <div class="notfound">
                    <div class="notfound-404">
                        <h1>Oops!</h1>
                    </div>
                    <h2>404 - Pas non trouvée</h2>
                    <p>Il se peut que l'URL soit incorrecte ou que la page ait été supprimée.</p>
                    <Link to={'/'}>Aller à la page d'accueil</Link>
                </div>
            </div>
        </>
    )
}