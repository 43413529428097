import React from 'react'
import { useEffect, useState } from "react";
import aetna from '../../styles/img/partner-image/AETNA.png'
import allianz from '../../styles/img/partner-image/Allianz-NEW.jpg'
import cfe from '../../styles/img/partner-image/cfe-80x80.jpg'
import humanis from '../../styles/img/partner-image/Humanis-3-150x90.png'
import april from '../../styles/img/partner-image/logo-april-70x70.jpg'
import cigma from '../../styles/img/partner-image/logo-cigna-80x80.png'
import chapka from '../../styles/img/chapka.png'
import moncey from '../../styles/img/partner-image/MONCEY-150x90.jpg'
import asfe from '../../styles/img/partner-image/asfe-logo-70x70.jpg'
import { apiURL, getEntity } from "../../../src/services/api";
import moment from 'moment';
import 'moment/locale/fr';

import { Swiper, SwiperSlide } from 'swiper/react';
//import { EffectCoverflow, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";

export default function PartnerSection({partenaire}) {

  const [data, partenaireCharge] = useState([]);
  useEffect(() => {
    moment.locale('fr');
    console.log(partenaire);
    getEntity("/partenaires").then((res) => {

      partenaireCharge(res.data.data);

    }).catch((err) => {
      console.log(err.message);
    });

  }, []);

  return (


    <>
      <section className="team-area ptb-60">
        <div className="container"
          data-aos="fade-up"
          data-aos-duration="1500" slot=''
          data-aos-once="false">
          <div className="section-title" data-aos="zoom-in-down">
            <h2>Nos partenaires</h2>
            <p>Nous sommes fiers de collaborer avec les meilleurs assureurs et TPA spécialistes de la santé et de la prévoyance dans le monde.</p>
          </div>



          <Swiper
            autoplay={{ delay: 3000, disableOnInteraction: true }}
            // observer={false}
            // observeslidechildren={false}
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={0}
            slidesPerView={4}
            // navigation
            //pagination={{ clickable: true }}
             //scrollbar={{ draggable: false }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => {
              setTimeout(() => {
                swiper.update(); // ------> this solution
              }, 3000);
            }}
            loop={true}
            breakpoints={{
            200: {
              width: 200,
              slidesPerView: 1,
            },
            375: {
              width: 375,
              slidesPerView: 1,
            },
            300: {
              width: 300,
              slidesPerView: 1,
            },
            400: {
              width: 400,
              slidesPerView: 1,
            },
            640: {
              width: 600,
              slidesPerView: 2,
            },
            768: {
              width: 768,
              slidesPerView: 3,
            },
            900: {
              width: 900,
              slidesPerView: 4,
            },
          }}
          >
            {partenaire.map((item, index) => (
            
              <SwiperSlide key={index}>
                <div className="single-partner-item" >
                  <a href={`${item.lien}`}>
                    <img src={`${apiURL}${item.logo}`} alt={`${item.nom}`} />
                  </a>
                </div>
                
              </SwiperSlide>
            ))}
            
          </Swiper>
        </div>
       
      </section>
    </>
  )
}
