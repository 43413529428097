import React from "react";
import { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/fr";
import { Link, useParams } from "react-router-dom";
import { getEntityById, getEntity, apiURL } from "../services/api";
import dummyImage from '../img/dummy.jpg';

import { render } from "react-dom";
import ReactHtmlParser from "react-html-parser";
import Loader from "../components/Loader";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Search from "../components/Search";
export default function BlogDetails() {
  const [data, empdatachange] = useState([]);
  const [datarecentPub, recentPub] = useState([]);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [datacategorie, categorie] = useState([]);
  useEffect(() => {
    moment.locale("fr");
    setIsLoading(true);
    getEntityById("/poste/" + id)
      .then((res) => {

        empdatachange(res.data.data);
        setIsLoading(false);

      })
      .catch((err) => {
        console.log(err.message);
        setIsLoading(false);
      });

    //les trois dernière publication
    getEntity("/poste")
      .then((res) => {
        recentPub(res.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });

    //les categories publication
    getEntity("/categories")
      .then((res) => {
        categorie(res.data.data);

      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  // Fonction pour limiter le nombre de caractères de la description
  const limitDescription = (description, limit) => {
    if (description.length <= limit) {
      return description;
    } else {
      return description.slice(0, limit) + "...";
    }
  };

  return isLoading ?
    <Loader /> : (
      <>
        <div style={{ overflowX: "hidden" }}>
          <div>
            <Header />
          </div>
          <div className="page-title-area page-title-blog">
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="page-title-content">
                    <h2>blog & News</h2>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <section class="blog-details-area ptb-100">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 col-md-12">
                  <div class="blog-details-desc">
                    <div class="article-image">
                      {data.photo ? <img src={`${apiURL}${data.photo}`} height={'422px'} alt="image" /> : <img src={dummyImage} height={'422px'}
                      />}
                    </div>

                    <div class="article-content">
                      <div class="entry-meta">
                        <ul>
                          <li>
                            <div>
                              {" "}
                              <i class="far fa-calendar"></i>{" "}
                              {moment(data.date_post).format("MMM DD, YYYY")}
                            </div>
                          </li>
                          <li>
                            <i class="fas fa-user"></i> <a href="#">Admin</a>
                          </li>
                        </ul>
                      </div>

                      <h3>{data.titre}</h3>

                      <p>{ReactHtmlParser(data.description)}</p>
                    </div>

                    <div class="article-footer">

                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-12">
                  <aside class="widget-area" id="secondary">

                    <section class="widget widget_search" style={{ marginBottom: '20px' }}>
                      <form class="search-form">
                        <label>
                          <Search />
                        </label>
                        {/* <button type="submit"><i class="fas fa-search"></i></button> */}
                      </form>
                    </section>

                    <section class="widget widget_pearo_posts_thumb widget_search" style={{ marginBottom: '20px' }}>
                      <h3 class="widget-title">Récentes publications</h3>
                      <hr />
                      {datarecentPub.map((item, index) => (
                        <article class="recentePub" key={index}>
                          {item.photo ? <img src={`${apiURL}${item.photo}`} style={{ width: "70px", height: "50px" }} /> : <img src={dummyImage} style={{ width: "70px", height: "50px" }}
                          />}
                          <div class="info">
                            <time datetime="2021-06-30">
                              {moment(item.date_post).format("MMM DD, YYYY")}
                            </time>
                            <h6 class="title usmall">
                              <a href={`/blogdetails/${item.slug}`} target='_blank'>{item.titre}</a>
                            </h6>
                          </div>

                          {/*  <div class="clear"></div> */}
                        </article>
                      ))}
                    </section>

                   {/*  <section class="widget widget_categories widget_search">
                      <h3 class="widget-title">Catégories</h3>
                      {datacategorie.length > 0 ? (
                        <ul>
                          {datacategorie.map((item, index) => (
                            <li key={index}><a href={`/blog-categorie/${item.slug}`} target='_blank'>{item.titre}</a></li>
                          ))}
                        </ul>
                      ) : (
                        <p>Aucune donnée disponible.</p>
                      )}
                    </section> */}
                  </aside>
                </div>
              </div>
            </div>
          </section>
          <div>
            <Footer />
          </div>
        </div>
      </>
    );
}
