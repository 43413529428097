import React from "react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { apiURL, getEntityById } from "../services/api";
import Loader from "../components/Loader";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";

export default function Produit() {
  const [data, empdatachange] = useState([]);
  const [param, setParam] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    setIsLoading(true);
    const url = window.location.href;
    const paramValue = url.split("/").pop();

    setParam(paramValue);
    console.log("oop/" + id);

    getEntityById("/produit/" + id)
      .then((res) => {

        empdatachange(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err.message);
      });
  }, []);
  return isLoading ?
    <Loader /> : (
      <>
        <div>
          <Header />
        </div>
        <div>
          <div
            className="page-title-area page-title-bg-collectifs"
            style={{
              backgroundImage: `radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(0, 0, 0, 0) 0%),url(${apiURL}${data.photo})`,
              backgroundColor: "#ffffff",
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              objectFit: "contain"
            }}
          >
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="page-title-content">
                    <h2>{data.titre}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="about-area ptb-60 bg-f8f8f8">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  <div>
                    {ReactHtmlParser(data.description)}
                    {data.type_produit_id == 1 ? (
                      data.titre == 'ASSURANCES SCHENGEN' ? (
                        <a target="blank"
                        href="https://assurance-voyage-schengen.com/FR/?part=98"
                        data-aos="fade-right"
                        className="default-btn"
                      >
                        <i className="fa-solid fa-chart-column fa-1x fa-flip">
                          &nbsp;&nbsp;
                        </i>{" "}
                        Obtenez votre devis !<span></span>
                      </a>
                      ) : (<a
                        href="/demandededevis/particuliers"
                        data-aos="fade-right"
                        className="default-btn"
                      >
                        <i className="fa-solid fa-chart-column fa-1x fa-flip">
                          &nbsp;&nbsp;
                        </i>{" "}
                        Obtenez votre devis !<span></span>
                      </a>)


                    ) : <a
                      href="/demandededevis/entreprises"
                      data-aos="fade-right"
                      className="default-btn"
                    >
                      <i className="fa-solid fa-chart-column fa-1x fa-flip">
                        &nbsp;&nbsp;
                      </i>{" "}
                      Obtenez votre devis !<span></span>
                    </a>}


                    {/* <div className="row another-link" data-aos="fade-right">
                  <h3 className="mt-5">
                    Vous pourriez être intéressé par les produits suivants :
                  </h3>

                  <div className="col-md-4 mt-2">
                    <a href="/Kidnapping" className="">
                      <b>Assurance kidnapping, crise et sécurité</b>{" "}
                      <span></span>
                    </a>
                  </div>

                  <div className="col-md-4 mt-2">
                    <a href="/AuditRisquesEntreprises" className="">
                      <b>Audit risques entreprises</b> <span></span>
                    </a>
                  </div>

                  <div className="col-md-4 mt-2">
                    <a href="/ConseilCima" className="">
                      <b>Conseil CIMA</b> <span></span>
                    </a>
                  </div>
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
        <div>
          <Footer />
        </div>
      </>
    );
}
