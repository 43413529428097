import React, { useState, useEffect } from "react";
import ChildForm from "../FormTools/ChildForm";
import SelectComponent from "../../common/SelectComponent"
import CurrentDate from '../../common/getCurrentDate'
import {Old, OldCheck} from '../../../helpers/moment'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Select from 'react-select';


export default function Step4({CurrentTitle,Prev,Next,values,setValues,activeRadio,setActiveRadio,errors,setErrors,setRadioChild,radioChild,radioConjoint,setRadioConjoint}) {

  const data = ChildForm;
  const [disabled, setDisabled] = useState(true);
  const [radio3, setRadio3] = useState("");
  const [radio4, setRadio4] = useState("");
  const [old, setOld] = useState(null);
  const [oldConjoint, setOldConjoint] = useState(null);
  const [oldEnfant, setOldEnfant] = useState([]);



  useEffect( () => {

    
          if(values.dateNaissanceAssurePrincipal) {
            setOld(Old(values.dateNaissanceAssurePrincipal))
          }else {
            setOld(null)
          }

          if (values.dateNaissanceAssurePrincipal && values.sexeAssurePrincipal && values.nationaliteAssurePrincipal) {
            setDisabled(false)
          }else {
            setDisabled(true)
            setValues({...values, conjoint : {}, enfants: [] })
            setErrors({...errors, nationaliteConjoint: '', child: {}})
            setRadio3("");
            setRadio4("");
            setRadioConjoint(!radioConjoint);
            setRadioChild([]);
            setOldEnfant([]);
          }

          if(values.conjoint.dateNaissanceConjoint) {
            setOldConjoint(Old(values.conjoint.dateNaissanceConjoint))
          }else {
            setOldConjoint(null)
          }
          
           if (values.dateNaissanceAssurePrincipal) {
            /* if (Old(values.dateNaissanceAssurePrincipal) < 18) {
              setErrors({...errors, dateNaissanceAssurePrincipal: 'errorAge'})
            }else { */
              setErrors({...errors, dateNaissanceAssurePrincipal: ''})
           // }
          } 

          

  },[values.dateNaissanceAssurePrincipal, values.conjoint.dateNaissanceConjoint, values.sexeAssurePrincipal, values.nationaliteAssurePrincipal])
  

  const OnRadioConjoint = (event) => {
    if(radio3 === "") {
      setRadio3("form-check-input");
      setRadioConjoint(event.target.name);
    }else {
      onConjointClose();
    }
    
  };

  const OnRadioChild = (event) => {
    
    if(radio4 === "" ) {
      setRadio4("form-check-input");
      values.enfants.splice(0, values.enfants.length)
      setRadioChild([...radioChild, data]);
      setValues({...values, enfants : [...values.enfants , {} ] })

    }else {
      radioChild.splice(0, radioChild.length)
      values.enfants.splice(0, values.enfants.length)
      setRadio4("");
    }

  };

  const onConjointClose = (event) => {
    setRadio3("");
    setRadioConjoint(!radioConjoint);
    setValues({...values, conjoint : {}})
    setErrors({...errors, dateNaissanceConjoint: 'noMessage', nationaliteConjoint: 'noMessage'})
  };

  const onChildClose = (position) => {

    setRadioChild(radioChild.filter((v, i) => i !== position));
    setValues({...values, enfants : values.enfants.filter((v, i) => i !== position)})
    setOldEnfant(oldEnfant.filter((v, i) => i !== position));
  };

  const handleChangeConjoint = (e) => {

    if(!e) {
      setErrors({...errors, dateNaissanceConjoint: 'required'})
      setValues({...values, conjoint : { ...values.conjoint , dateNaissanceConjoint :  '' } });
    }else {
      setErrors({...errors, dateNaissanceConjoint: ''})
      setValues({...values, conjoint : { ...values.conjoint , dateNaissanceConjoint :  e } }); 
    } 
    
  }

  const handleChangeEnfant = (e,index) => {

    if(!e) {

      for(const r in values.enfants) {

        if(values.enfants[r]) {
          errors = ({...errors, child : { ...errors.child ,  [r] : '' } })
        }else {
          errors = ({ ...errors , child : { ...errors.child , [r] : 'required' }  })
        }
        setErrors(errors)

      }

    }else {
      

      const newNaissance = Object.assign({ ...values.enfants[index] }, { dateNaissanceEnfant : e });

      values.enfants[index] = newNaissance;

      setValues(values)

      // if(values.enfants[index]) {
      //   values.enfants[index] = { dateNaissanceEnfant: e };
      // }
      // else {
      //   values.enfants.push({dateNaissanceEnfant: e})
      // }

      // setValues({...values, enfants : values.enfants})
      errors = ({...errors, child : { ...errors.child ,  [index] : '' } })
      setErrors(errors)

    }

  }

  const handleChangeAssurePrincipal = (e) => {

    if(!e) {
      setErrors({...errors, dateNaissanceAssurePrincipal: 'required'})
      setValues({...values, dateNaissanceAssurePrincipal : ''}); 
    }else {
      setErrors({...errors, dateNaissanceAssurePrincipal: ''})
      setValues({...values, dateNaissanceAssurePrincipal : e}); 
    } 

  }

  const handleChangeSexeAssurePrincipal = (e) => {

    if(!e) {
      setErrors({...errors, sexeAssurePrincipal: 'required'})
    }else {
      setErrors({...errors, sexeAssurePrincipal: ''})
      setValues({...values, sexeAssurePrincipal : e}); 
    } 

  }

  const handleChangeNationaliteAssurePrincipal = (e) => {

    if(!e) {
      setErrors({...errors, nationaliteAssurePrincipal: 'required'})
    }else {
      setErrors({...errors, nationaliteAssurePrincipal: ''})
      setValues({...values, nationaliteAssurePrincipal : e.value}); 
    }  

  }

  const handleChangeNationaliteConjoint = (e) => {

    if(!e) {
      setErrors({...errors, nationaliteConjoint: 'required'})
      setValues({...values, conjoint : { ...values.conjoint , nationaliteConjoint :  '' } }); 
    }else {
      setErrors({...errors, nationaliteConjoint: ''})
      setValues({...values, conjoint : { ...values.conjoint , nationaliteConjoint :  e.value } }); 
    }  

  }

  const NextStep = () => {

    const checkChildExistance = (event) => {

      if (event === 'check') {

        if(radio4 === "form-check-input" && radioChild !== 'undefined' && radioChild.length > 0) {

          let pass = false;

          for(const r in radioChild) {
    
            if(values.enfants[r]) {
              errors = ({...errors, child : { ...errors.child ,  [r] : '' } })
            }else {
              errors = ({ ...errors , child : { ...errors.child , [r] : 'required' }  })
            }
            setErrors(errors)
    
          }

          for(const val in errors.child) {

            if ( errors.child[val] === 'required') {
              pass = true;
              break;
            }else {
              pass = false;
            }
          }

          if (pass === false) {
            setErrors({ child:{}, conjoint:{} })
            Next();
          }
    
        }else {
          setErrors({ child:{}, conjoint:{} })
          Next();
        }
        
      }

    }

    if(values.dateNaissanceAssurePrincipal && values.nationaliteAssurePrincipal && values.sexeAssurePrincipal) {

      if(radioConjoint === 'conjoint' && !values.conjoint.dateNaissanceConjoint && !values.conjoint.nationaliteConjoint) {
        setErrors({...errors, dateNaissanceConjoint: 'required', nationaliteConjoint: 'required'}) 
      }else {
        checkChildExistance('check');
      }

    }else {  

      for(const v in values) {
        if(values[v] === "") {
          errors = ({...errors, [v] : 'required'})
        }
       } 
       setErrors(errors) 
    }

    if(radio4 === "form-check-input" && radioChild !== 'undefined' && radioChild.length > 0) {

      for(const r in radioChild) {

        if(values.enfants[r]) {
          errors = ({...errors, child : { ...errors.child ,  [r] : '' } })
        }else {
          errors = ({ ...errors , child : { ...errors.child , [r] : 'required' }  })
        }
        setErrors(errors)

      }

    }

    if(radioConjoint === 'conjoint' && !values.conjoint.dateNaissanceConjoint && !values.conjoint.nationaliteConjoint) {
      setErrors({...errors, dateNaissanceConjoint: 'required', nationaliteConjoint: 'required'}) 
    }else {
      setErrors({...errors, dateNaissanceConjoint: 'noMessage', nationaliteConjoint: 'noMessage', autreInformations: 'noMessage', captcha:'noMessage'})
    }

    

  }

  /* const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div>
        L'assuré principale doit avoir au moins 18 ans.
      </div>
    </Tooltip> 
  );*/

              const optionSexe = [

                { value: 'Masculin', label: 'Masculin' },
                { value: 'Féminin', label: 'Féminin' },
                { value: 'Autre', label: 'Autre' }

              ]
  
  return (
    <>
      <h6 className="text-center">Assuré(e) principal(e)
      </h6>
      <div className="row">
        <div className="offset-md-1 col-lg-10 col-md-6 col-sm-12">
          <div className="form-group">
            <label htmlFor="">Date de naissance<abbr>*</abbr>{" "}

            <b>
              { (old >= 1) ? '(' + old + `${old > 1 ? ' ans' : old === 1 ? 'an' : ''})` : (old === 0) ? '( 0 an )' : '' }
            </b>
           {/*  <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <i   
                className="fa-solid fa-circle-info fa-fade" 
                style={{ fontSize: '24px', color:'#bd3636'}}
              >
              </i>
            </OverlayTrigger> */}

             {" "}{/* { window.innerWidth > 600 ? 'date' : 'text'}{  window.innerWidth < 720 && values.dateNaissanceAssurePrincipal === "" && <b className='fa-beat-fade' style={{ color: "#C14742"}}>Cliquez 02 fois 👇</b> } */}
            
            </label>

              <input
                type="date"
                max={CurrentDate()}
                onInput={(e) => handleChangeAssurePrincipal(e.target.value)}
                defaultValue={values.dateNaissanceAssurePrincipal}
                className="form-control"
                placeholder="jj/mm/aaaa"
                onFocus={(e) => (e.target.type = "date")}
                //onBlur={(e) => (e.target.type = "text")}
              />
          
            {
              errors.dateNaissanceAssurePrincipal === 'required' ?
              <div className="errorStyle">
                Le champ est obligatoire.
              </div>
                : errors.dateNaissanceAssurePrincipal === ''  
                ?
              <div className="successStyle">
                Correct ! 😊
              </div>
              
              : ''
            }{/*errors.dateNaissanceAssurePrincipal === 'errorAge'  
              ?
              <div className="errorStyle">
                L'assurée principale doit avoir au moins 18 ans.
              </div> */}
          </div>
        </div>

        <div className="offset-md-1 col-lg-10 col-md-6 col-sm-12" style={{ zIndex: '5000'}}>
          <div className="form-group">
            <label htmlFor="inputState">Sexe<abbr>*</abbr></label>
            
            <Select
              onChange={(e) => handleChangeSexeAssurePrincipal(e.value)}
              defaultValue={values.sexeAssurePrincipal}
              options={optionSexe}
              id="inputState"
              className="App"
              name=""
              placeholder={values.sexeAssurePrincipal ? values.sexeAssurePrincipal : "Sélectionnez"}
            />

            {
              errors.sexeAssurePrincipal === 'required' ?
              <div className="errorStyle">
                Le champ est obligatoire.
              </div>
                : errors.sexeAssurePrincipal === ''  
                ?
              <div className="successStyle">
                Correct ! 😊
              </div>
              : ''
            }
          </div>
        </div>

        <div style={{zIndex: '9'}} className="offset-md-1 col-lg-10 col-md-6 col-sm-12">
            <div className="form-group">
              <label for="inputState">Nationalité<abbr>*</abbr></label>
              <SelectComponent
                handleChangePays={handleChangeNationaliteAssurePrincipal} 
                defaultValue={values.nationaliteAssurePrincipal}
                placeholder={values.nationaliteAssurePrincipal ? values.nationaliteAssurePrincipal : 'Sélectionnez'}
              ></SelectComponent>
              {
              errors.nationaliteAssurePrincipal === 'required' ?
              <div className="errorStyle">
                Le champ est obligatoire.
              </div>
                : errors.nationaliteAssurePrincipal === ''  
                ?
              <div className="successStyle">
                Correct ! 😊
              </div>
              : ''
            }
            </div>
          </div>

          
      </div>

      <div className="container row">
        <div className="form-check col-6 ">
          <input
            className={ `form-check-dimensions form-check-input` }
            type="radio"
            name="conjoint"
            id="flexRadioDefault1"
            onClick={OnRadioConjoint}
            checked={radio3}
            disabled={disabled}
          />
          <label className="form-check-label" for="flexRadioDefault1">
            Conjoint(e)
          </label>
        </div>

        {  radioConjoint !== "conjoint" ? 
        <div className="form-check col-6">
          <input
            className={`form-check-dimensions form-check-input`}
            type="radio"
            name="child"
            id="flexRadioDefault2"
            onClick={OnRadioChild}
            checked={radio4}
            disabled={disabled}

          />
          <label className="form-check-label" for="flexRadioDefault2">
            Enfant(s)
          </label>
        </div>
        :""
        }

      </div>

      {radioConjoint === "conjoint" ? (
        <>
        <div className="row">
          <h6 className="offset-md-1 col-lg-10 col-md-6 col-sm-12">Conjoint(e)</h6>
          <div className="offset-md-9 offset-4 col-lg-2 col-md-6">
            <i onClick={onConjointClose} className="fas fa-trash" style={{fontSize:'15px',
              color: '#ffffff',
              background: 'rgb(220, 53, 69)',
              border: '1px solid rgb(220, 53, 69)',
              borderRadius:'50%',
              padding:'8px'}}>
            </i>
          </div>
          <div className="offset-md-1 col-lg-10 col-md-6 col-sm-12">
            <div className="form-group">
              <label>Date de naissance<abbr>*</abbr>

                  {/* { window.innerWidth > 600 ? 'date' : 'text'}{  window.innerWidth < 720 && values.conjoint.dateNaissanceConjoint === "" && <b className='fa-beat-fade' style={{ color: "#C14742"}}>Cliquez 02 fois 👇</b> } */}

                <b>
                  { (oldConjoint >= 1) ? '(' + oldConjoint + `${oldConjoint > 1 ? ' ans' : oldConjoint === 1 ? 'an' : ''})` : (oldConjoint === 0) ? '( 0 an )' : '' }
                </b>
              </label>

                <input
                  type="date"
                  id="date"
                  max={CurrentDate()}
                  onInput={(e) => handleChangeConjoint(e.target.value)}
                  defaultValue={values.conjoint.dateNaissanceConjoint}
                  className="form-control"
                  placeholder="jj/mm/aaaa"
                  onFocus={(e) => (e.target.type = "date")}
                  //onBlur={(e) => (e.target.type = "text")}
                />


                {
                  errors.dateNaissanceConjoint === 'required' ?
                  <div className="errorStyle">
                    Le champ est obligatoire.
                  </div>
                    : errors.dateNaissanceConjoint === ''  
                    ?
                  <div className="successStyle">
                    Correct ! 😊
                  </div>
                  : ''
                }
            </div>
          </div>

          <div style={{zIndex: '1000'}} className="offset-md-1 col-lg-10 col-md-6 col-sm-12">
            <div className="form-group">
              <label for="inputState">Nationalité<abbr>*</abbr></label>
              <SelectComponent
                handleChangePays={handleChangeNationaliteConjoint} 
                defaultValue={values.conjoint.nationaliteConjoint}
                placeholder={values.conjoint.nationaliteConjoint ? values.conjoint.nationaliteConjoint : 'Sélectionnez'}
              ></SelectComponent>
              {
              errors.nationaliteConjoint === 'required' ?
              <div className="errorStyle">
                Le champ est obligatoire.
              </div>
                : errors.nationaliteConjoint === ''  
                ?
              <div className="successStyle">
                Correct ! 😊
              </div>
              : ''
            }
            </div>
          </div>

          
        </div>
      </>


      ) : (
        ""
      )}

      {radioChild && radioChild.map((number, index) => (
       <>
          <ChildForm 
              oldEnfant={oldEnfant} 
              setOldEnfant={setOldEnfant} 
              values={values.enfants} 
              handleChangeEnfant={handleChangeEnfant} 
              position={index} 
              onChildClose={() => onChildClose(index)} 
              errors={errors}
              old={Old}
              key={index}>
          </ChildForm>
       </>
      ))}


      { typeof (radioChild !== 'undefined') && (radioChild.length >  0) || (radioConjoint === "conjoint") ? 
        <>
          <div
            className="offset-md-1 col-lg-6 col-md-6 col-sm-12 mt-3"
            onClick={(e) => {
              setRadioChild([...radioChild, data]);
            }}
            style={{ cursor: "pointer" }}
          >
            
            <span className="fas fa-user-plus" style={{fontSize:'15px',
                  color: '#ffffff',
                  background: '#002e5b',
                  border: '1px solid #002e5b',
                  borderRadius:'50%',
                  padding:'8px'}}></span>{" "}
            <span>
                <b>Ajouter un enfant</b>
            </span>{" "}
          </div>
        </>

        : ""
      }

      <div  style={{ color: 'red'}} className='offset-md-1 col-lg-6 col-md-6 col-sm-12 mt-5'>* Champ obligatoire</div>

      <div style={{ marginLeft: "3px"}} className={`col-lg-12 col-md-12 col-sm-12 form-footer-content-position 
      ${CurrentTitle===3 || CurrentTitle===4 || CurrentTitle===5 ? 'form-footer-margin' : ''}`}>

      <button type="button" onClick={Prev}  className="default-btn default-btn-annuler">
      Retour </button>

      <button type="button" onClick={NextStep}  style={{ marginLeft: "10px"}} className="default-btn">
      Suivant</button>
     
    </div>
  
    </>
  );
}
