import React from 'react'
import { useEffect, useState } from "react";
import { apiURL, getEntity } from "../services/api";
import { render } from 'react-dom';
import ReactHtmlParser from 'react-html-parser';
import { Accordion, AccordionTab } from 'primereact/accordion';
import Loader from '../components/Loader';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
//import { Accordion, AccordionTab } from 'primereact/accordion';


export default function Faq() {

    const [data, empdatachange] = useState([]);
    const [datafaqheader, empdatafaqheader] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(true)
        getEntity("/faq").then((res) => {
            empdatachange(res.data.data);
            setIsLoading(false);

        }).catch((err) => {
            console.log(err.message);
            setIsLoading(false)
        });

        //faq header
        getEntity("/faq-header").then((res) => {
            empdatafaqheader(res.data.data);

        }).catch((err) => {
            console.log(err.message);
        })
    }, []);
    return isLoading ?
        <Loader /> : (
            <>
                <div>
                    <Header />
                </div>
                {datafaqheader && datafaqheader.map((item, index) =>
                    <div className="page-title-area page-title-bg-Faq" style={{ backgroundImage: `radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(0, 0, 0, 0) 0%),url(${apiURL}${item.photo})`, backgroundColor: 'black' }}>
                        <div className="d-table">
                            <div className="d-table-cell">
                                <div className="container">
                                    <div className="page-title-content">
                                        <h2>{item.titre}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <section className="accordion_two_section ptb-60">
                    <div className="container">
                        <div className="row">
                            <div className="offset-lg-2 col-lg-8 col-md-8 col-12 accordionTwo">
                                <div className="panel-group" id="accordionTwoLeft">
                               {/*  activeIndex={0} */}
                                    <div className="card">
                                        <Accordion >
                                            {data.map((item, index) => (
                                                <AccordionTab className="faq-title" header={item.titre} key={index}>
                                                    <div className="faq-description" >
                                                       {ReactHtmlParser(item.description)}
                                                    </div>
                                                </AccordionTab>
                                            ))}
                                        </Accordion>
                                    </div>


                                </div>

                            </div>

                        </div>
                    </div>

                </section>
                <div>
                    <Footer />
                </div>

            </>
        )
}