import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../styles/css/devis.css";
import { useParams } from "react-router-dom";
import Step1 from "../components/ApplicationPage/FormEntrepriseStep/Step1";
import Step2 from "../components/ApplicationPage/FormEntrepriseStep/Step2";
import Step3 from "../components/ApplicationPage/FormEntrepriseStep/Step3";
import Step4 from "../components/ApplicationPage/FormEntrepriseStep/Step4";
import Step5 from "../components/ApplicationPage/FormEntrepriseStep/Step5";
import FormBottomText from "../components/common/FormBottomText";
// import {useDropzone} from 'react-dropzone';
import { postEntity } from "../services/api";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";

export default function DemandededevisEntreprise() {
  const { typecourverture } = useSelector((state) => state.enterpriseData);
  const data = useSelector((state) => state.enterpriseData);
  const [file, setFile] = useState([]);

  const [values, setValues] = useState({
    mainAssured: [],
    captcha: "",
    autreInformations: "",
  });

  const [errors, setErrors] = useState({});

  const [activeRadio, setActiveRadio] = useState({
    step1Radio1: "",
    step1Radio2: "",
    step4Radio1: "",
    step4Radio2: "",
  });

  const [checked, setChecked] = useState("");
  const [radioAssured, setRadioAssured] = useState([]);
  const [radioChild, setRadioChild] = useState({});
  const [radioConjoint, setRadioConjoint] = useState({});
  const [currentTitle, setCurrentTitle] = useState(1);

  const Next = () => {/* &&
    !typecourverture.includes("Collectifs santé et prévoyance") */
    if (
      currentTitle === 3 &&
      typecourverture !== []
    ) {
      setCurrentTitle(currentTitle + 2);
    } else {
      setCurrentTitle(currentTitle + 1);
    }
  };

  const Prev = () => {
    if (
      currentTitle === 5 &&
      typecourverture !== []
    ) {
      setCurrentTitle(currentTitle - 2);
    } else {
      setCurrentTitle(currentTitle - 1);
    }
  };

  const onSubmit = () => {
    // here is the code to send data to backend
    const mergedData = { ...data, ...values };
    //console.log(mergedData);
    postEntity("/demande-devis-entreprise", mergedData).then(
      (res) => {
        console.log(res.data);
        setCurrentTitle(6);
        setValues({});
        setErrors({});
      },
      (errors) => {
        console.log(errors);
      }
    );
  };

  return (
    <>
      <div>
        <Header />
      </div>
      <section className="insurance-details-area ptb-60">
        <div className="container">
          <div className="insurance-details-header">
            <div className="row papa align-items-center justify-content-center">
              <div className="col-lg-9 col-md-12">
                {currentTitle === 6 ? (
                  <div>
                    <div className="card" style={{ width: "60 rem" }}>
                      <div className="card-body">
                        <h4 className="card-title">
                          <b>Demande envoyée avec succès </b>😊
                        </h4>
                        {/* <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6> */}
                        {/* <p className="card-text mt-3">Nous vous répondrons rapidement {whatsapp ? "Whatsapp" : "Email"} !!! */}
                        <p className="card-text mt-3">
                          Nous vous répondrons rapidement.
                          <br />
                          Merci pour votre intérêt <span>&#128578;</span> !!!
                        </p>
                        <div className="mt-3">
                          <a
                            href="/demandededevis/entreprises"
                            className="default-btn"
                          >
                            Revenir au formulaire entreprise
                          </a>
                          {"  "}
                          <a href="/" className="default-btn-annuler">
                            Page d'accueil
                          </a>
                          {"  "}
                        </div>

                        {/* <a href="#" className="default-btn">Formulaire entreprise</a> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row papa">
                    <div className="row papa">
                      <div className="card cardStyle cardTitle title">
                        <h5 className="text-center">
                          Demandez-nous ce que vous voulez
                        </h5>
                      </div>
                    </div>

                    <div className="row container papa contentPadding">
                      <div className="col-lg-4 col-md-4 col-sm-12 col-12 background card cardStyle">
                        <div
                          className={`col-lg-12 formTitle ${currentTitle === 1 ? "vl activee" : ""
                            }`} /*onClick={ () => setCurrentTitle(1) }*/
                        >
                          {" "}
                          Contact{" "}
                        </div>
                        <div
                          className={`col-lg-12 formTitle ${currentTitle === 2 ? "vl activee" : ""
                            }`} /*onClick={ () => setCurrentTitle(2) }*/
                        >
                          {" "}
                          Identité
                        </div>
                        <div
                          className={`col-lg-12 formTitle ${currentTitle === 3 ? "vl activee" : ""
                            }`} /*onClick={ () => setCurrentTitle(3) }*/
                        >
                          {" "}
                          Besoins
                        </div>{/*onClick={ () => setCurrentTitle(4) }*/}
                        {/*  {typecourverture.includes(
                          "Collectifs santé et prévoyance"
                        ) && (
                          <div
                            className={`col-lg-12 formTitle ${
                              currentTitle === 4 ? "vl activee" : ""
                            }`} 
                          >
                            {" "}
                            Personne(s) à assurer
                          </div>
                        )} */}
                        <div
                          className={`col-lg-12 formTitle ${currentTitle === 5 ? "vl activee" : ""
                            }`} /*onClick={ () => setCurrentTitle(5) }*/
                        >
                          {" "}
                          Autres précisions
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-12 col-12 card cardStyle formContent">
                        <div className="contact-form">
                          <form id="contactForm">
                            {currentTitle === 1 ? (
                              <Step1
                                CurrentTitle={currentTitle}
                                Next={Next}
                                activeRadio={activeRadio}
                                setActiveRadio={setActiveRadio}
                                checked={checked}
                                setChecked={setChecked}
                                setErrors={setErrors}
                                errors={errors}
                              />
                            ) : currentTitle === 2 ? (
                              <Step2
                                CurrentTitle={currentTitle}
                                Next={Next}
                                Prev={Prev}
                                setErrors={setErrors}
                                errors={errors}
                              />
                            ) : currentTitle === 3 ? (
                              <Step3
                                CurrentTitle={currentTitle}
                                Next={Next}
                                Prev={Prev}
                                setErrors={setErrors}
                                errors={errors}
                              />
                            ) : currentTitle === 4 ? (
                              <Step4
                                CurrentTitle={currentTitle}
                                Next={Next}
                                Prev={Prev}
                                setErrors={setErrors}
                                errors={errors}
                                radioAssured={radioAssured}
                                setRadioAssured={setRadioAssured}
                                radioChild={radioChild}
                                setRadioChild={setRadioChild}
                                radioConjoint={radioConjoint}
                                setRadioConjoint={setRadioConjoint}
                                activeRadio={activeRadio}
                                setActiveRadio={setActiveRadio}
                                file={file}
                                setFile={setFile}
                              />
                            ) : currentTitle === 5 ? (
                              <Step5
                                CurrentTitle={currentTitle}
                                setValues={setValues}
                                setErrors={setErrors}
                                values={values}
                                errors={errors}
                                onSubmit={onSubmit}
                                Prev={Prev}
                              />
                            ) : (
                              ""
                            )}
                          </form>
                        </div>
                      </div>

                      <div className="offset-md-2 col-md-8 text-center">
                        <FormBottomText></FormBottomText>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <Footer />
      </div>
    </>
  );
}