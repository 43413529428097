import React, { useEffect, useState } from "react";
import FormBottomText from "../components/common/FormBottomText";
import brokerslinKSeeMoreFile from "../statics/brokerslink_files/Brokerslink.pdf";
import { Alert } from 'react-bootstrap';
import { postEntity, getEntity, apiURL } from "../services/api";
import ReactHtmlParser from 'react-html-parser';

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import TeamSection from "../components/Welcome/TeamSection";
import Loader from "../components/Loader";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
export default function Apropos() {

  const [data, setData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [button, setButton] = useState(<i className="fa fa-paper-plane"></i>);
  const [dataphoto, empdataphoto] = useState([]);
  const [datassurance, empdatassurance] = useState([]);
  const [datacontenu, empdatacontenu] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    //header
    getEntity("/apropos").then((res) => {
      empdataphoto(res.data.data);

    }).catch((err) => {
      console.log(err.message);
    });
    //contenu
    getEntity("/pageapropos").then((res) => {
      empdatacontenu(res.data.data);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err.message);
      setIsLoading(false);
    });

    //assurance
    getEntity("/assurance-confiant").then((res) => {
      empdatassurance(res.data.data);


    }).catch((err) => {
      console.log(err.message);
    });

  }, []);
  //Send message with contact form
  const onSubmit = (event) => {
    event.preventDefault();

    const buttonContent = <i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i>;

    try {

      if (data.length != []) {

        setButton(buttonContent)

        postEntity("/contact", data).then(() => {

          setAlert({ success: true, message: 'Votre méssage a été envoyé' })
          setButton(<i className="fa fa-paper-plane"></i>)

        }, () => {
          setAlert({ success: false, message: 'Erreur l\'ors de l\'envoie du méssage' });
        }).finally(() => {
          setTimeout(() => {
            setAlert(null);

            document.getElementById('nom').value = '';
            document.getElementById('email').value = '';
            document.getElementById('phone').value = '';
            document.getElementById('description').value = '';
          }, 2000);
        });

      } else {
        setAlert({ success: false, message: 'Veuillez renseigner tous les champs du formulaire' })
      }


    } catch (e) {
      console.log('Error')
    }

  }

  return isLoading ?
    <Loader /> : (
      <>
        <div>
          <Header />
        </div>


        {dataphoto && dataphoto.map((item, index) => (
          <div className="page-title-area1 page-title-bg1" style={{ backgroundImage: `radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(0, 0, 0, 0) 0%),url(${apiURL}${item.photo})`, backgroundColor: '#ffffff' }}>
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="page-title-content">
                    <h2>{item.titre}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}



        <div className="blog-details-area" style={{ overflowX: 'hidden',overflowY: 'hidden'}}>
          {datacontenu.map((item, index) => (
            item.photo ?
              <section className="blog-area ptb-60 pb-70">
                <div className="container" data-aos="fade-up" data-aos-once="false">
                  <div className="row">
                    <div
                      className="col-lg-6 col-md-12 col-sm-12"
                      data-aos="fade-left"
                      data-aos-once="false"
                    >
                      <img
                        src={`${apiURL}${item.photo}`}
                        style={{ marginTop: "-20px" ,borderRadius:"10px"}}
                       
                        height={'416'}
                        alt="image"
                      />
                    </div>
                    <div
                      className="col-lg-6 col-md-12 col-sm-12 section-title-left"
                      data-aos="fade-right"
                      data-aos-once="false"
                    >
                      <h2>{item.titre}</h2>
                      <p
                        className="mt-3 justify-text"
                        data-aos="fade-up"
                        data-aos-once="false"
                      >
                        {ReactHtmlParser(item.description)}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              :
              <section className="about-area ptb-120-60 bg-black-color" key={index}>
                <div className="container" data-aos="fade-up" data-aos-once="false">
                  <div className="row">
                    <div
                      className="col-lg-12 col-md-12"
                      data-aos="fade-left"
                      data-aos-once="false"
                    >
                      <div className="section-title-left">
                        <h2>
                          {item.titre}
                        </h2>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12" style={{ marginTop: "-30px" }}>
                        <p className="justify-text">
                          {ReactHtmlParser(item.description)}
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
              </section>


          ))}



          <section className="team-area ptb-60">
            {/* <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-once="false"
            >
              <div className="section-title" data-aos="zoom-in-down">
                <h2>Les associés BSI</h2>
               <p>Des spécialistes « métiers » à votre service. Et en plus on est sympa 😄</p>
              </div>

              <div className=" ">


              </div>
            </div> */}

            <TeamSection />
          </section>

          <section className="blog-area ptb-60 pb-70">
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-once="false"
            >
              <div className="section-title">
                <h2>Ils nous ont fait confiance</h2>
                <p className="">
                  Nous sommes fiers de collaborer avec les meilleurs assureurs et
                  gestionnaires tiers-payant spécialistes de la santé et de la
                  prévoyance dans le monde.
                </p>
              </div>
              <div
                className="col-lg-12 col-md-12 col-sm-12 text-center"
                style={{ marginTop: "-30px" }}
              >
                <div className="container">
                  <div className="partner-slides ">
                    <Swiper
                      autoplay={{ delay: 3000, disableOnInteraction: true }}
                      // observer={false}
                      // observeslidechildren={false}
                      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                      // spaceBetween={15}
                      slidesPerView={1}
                      // navigation
                      // pagination={{ clickable: true }}
                      // scrollbar={{ draggable: false }}
                      onSlideChange={() => console.log("slide change")}
                      onSwiper={(swiper) => {
                        setTimeout(() => {
                          swiper.update(); // ------> this solution
                        }, 3000);
                      }}

                      breakpoints={{
                        200: {
                          width: 200,
                          slidesPerView: 1,
                        },
                        375: {
                          width: 375,
                          slidesPerView: 1,
                        },
                        300: {
                          width: 300,
                          slidesPerView: 1,
                        },
                        400: {
                          width: 400,
                          slidesPerView: 1,
                        },
                        640: {
                          width: 600,
                          slidesPerView: 2,
                        },
                        768: {
                          width: 768,
                          slidesPerView: 3,
                        },
                        900: {
                          width: 900,
                          slidesPerView: 4,
                        },
                      }}

                    >

                      {
                        datassurance.map((item, index) => (
                          <SwiperSlide key={index}>
                            <a
                              href={`${item.lien}`}
                              className="single-assurance-item "
                            >
                              <img src={`${apiURL}${item.logo}`} alt={`image-${item.nom}`} />
                            </a>
                          </SwiperSlide>
                        ))
                      }

                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="blog-area ptb-60 pb-70 bg-black-color">
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-once="false"
            >
              <div className="section-title">
                <h2>Nous contacter</h2>
              </div>
              <div
                className="col-lg-12 col-md-12 col-sm-12 text-center"
                style={{ marginTop: "-30px" }}
              >
                <div className="offset-md-3 offset-lg-3 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <div className="contact-form">
                    {
                      alert && <Alert style={{ color: '#fff', backgroundColor: '#4AA340' }} variant={alert.success ? 'success' : 'danger'} >
                        {alert.message}</Alert>
                    }
                    <form id="" onSubmit={onSubmit} method="post">
                      <div className="row">
                        <div
                          className="col-lg-12 col-md-6"
                          data-aos="fade-left"
                          data-aos-delay="250"
                        >
                          <div className="form-group">
                            <input
                              type="text"
                              onInput={(e) => setData({ ...data, nom: e.target.value })}
                              name="nom"
                              id="nom"
                              className="form-control"
                              required
                              data-error="Saisissez votre nom"
                              placeholder="Nom"
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>

                        <div
                          className="col-lg-12 col-md-6"
                          data-aos="fade-left"
                          data-aos-delay="260"
                        >
                          <div className="form-group">
                            <input
                              type="email"
                              onInput={(e) => setData({ ...data, email: e.target.value })}
                              name="email"
                              id="email"
                              className="form-control"
                              required
                              data-error="Saisissez votre adresse email"
                              placeholder="Email"
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>

                        <div
                          className="col-lg-12 col-md-12"
                          data-aos="fade-left"
                          data-aos-delay="270"
                        >
                          <div className="form-group">
                            <input
                              type="text"
                              onInput={(e) => setData({ ...data, phone: e.target.value })}
                              name="phone"
                              id="phone"
                              required
                              data-error="Saisissez votre numéro de téléphone"
                              className="form-control"
                              placeholder="Téléphone"
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>

                        <div
                          className="col-lg-12 col-md-12"
                          data-aos="fade-left"
                          data-aos-delay="280"
                        >
                          <div className="form-group">
                            <textarea
                              onInput={(e) => setData({ ...data, description: e.target.value })}
                              name="description"
                              className="form-control"
                              id="description"
                              cols="30"
                              rows="6"
                              required
                              data-error="Saisissez le message"
                              placeholder="Votre Message"
                            ></textarea>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>

                        <div
                          className="col-lg-12 col-md-12 text-center"
                          data-aos="fade-left"
                        >
                          <button type="submit" className="default-btn">
                            Envoyer {button}<span></span>
                          </button>
                          {/* <div
                        id="msgSubmit"
                        className="h3 text-center hidden"
                      ></div> */}
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <FormBottomText></FormBottomText>
                </div>
              </div>
            </div>
          </section>

        </div>
        <div>
          <Footer />
        </div>
      </>
    );
}