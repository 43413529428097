import { useState,useEffect,useTransition } from "react";
import CurrentDate from '../../common/getCurrentDate'
import SelectComponent from "../../common/SelectComponent"
import ChildFormEntreprise from "./ChildFormEntreprise";
import {Old} from '../../../helpers/moment'
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';

import { 
  setMainAssured, 
  setNaissanceSexeNationnalite, 
  setSexeNationnaliteConjoint, 
  setEnfant, 
  removeConjointData,
  removeChildData
} from "../../../features/enterpriseForm/enterpriseFormSlice";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

export default function AssuredForm({old,setOld,Next,position,AssuredData,onAssuredClose,radioAssured,setRadioAssured,activeRadio,setActiveRadio,errors,setErrors,setRadioChild,radioChild,radioConjoint,setRadioConjoint}) {

  const {mainAssured} = useSelector((state) => state.enterpriseData);
  const dispatch = useDispatch()
  const data = ChildFormEntreprise;
  const [radio3, setRadio3] = useState("");
  const [disabled, setDisabled] = useState(true);


useEffect(() => {
  
  let isCancelled = false;

  const handleExecution = () => {

    if(mainAssured[position] && mainAssured[position].dateNaissanceAssurePrincipal) {

      const newOld = [...old]

        if(old[position]) {

          newOld[position] = Old(mainAssured[position].dateNaissanceAssurePrincipal);

        }else { 

            newOld.push(Old(mainAssured[position].dateNaissanceAssurePrincipal))

        }

        setOld(newOld);

    }


    if(mainAssured[position] && mainAssured[position].dateNaissanceAssurePrincipal !== ""  && 
      mainAssured[position].nationaliteAssurePrincipal !== "" &&
      mainAssured[position].sexeAssurePrincipal !== "") {
        setDisabled(false)     
    }else {
        setDisabled(true)             
    }  
    
    if (mainAssured[position].dateNaissanceAssurePrincipal) {
      if (Old(mainAssured[position].dateNaissanceAssurePrincipal) < 18) {
        setErrors({...errors, dateNaissanceAssurePrincipal: 'errorAge'})
      }else {
        setErrors({...errors, dateNaissanceAssurePrincipal: ''})
      }
    }

  }

  handleExecution();

  return () => { 
    isCancelled = true;
  };

},[mainAssured, old[position]])

  const OnRadioConjoint = (event) => {

    if(radio3 === "") {
      setRadio3("form-check-input");
      setRadioConjoint( {...radioConjoint, [position]: 'conjoint' } )
    }else {
      onConjointClose();
    }
    
  };


  const onConjointClose = (event) => {
    setRadio3("");
    setRadioConjoint(Object.keys(radioConjoint).filter((v, i) => i !== position));
    dispatch(removeConjointData(position))
  };

  const handleChildClose = (positionChild, positionAssured) => {
    setRadioChild(radioChild[positionAssured].filter((v, i) => i !== positionChild));
  };

  const handleChangeNaissanceAssurePrincipal = (e,index) => {

    if(!e) {
      setErrors({...errors, dateNaissanceAssurePrincipal: 'required'})
    }else {
      setErrors({...errors, dateNaissanceAssurePrincipal: ''})
      dispatch(setNaissanceSexeNationnalite([e,index,'dateNaissanceAssurePrincipal']))
    } 

  }

  const handleChangeSexeAssurePrincipal = (e,index) => {

    dispatch(setNaissanceSexeNationnalite([e,index,'sexeAssurePrincipal']))

  }

  const handleChangeNationaliteAssurePrincipal = (e,index) => {

    dispatch(setNaissanceSexeNationnalite([e,index,'nationaliteAssurePrincipal']))

  }

  const handleChangeConjoint = (e,index) => {

    dispatch(setSexeNationnaliteConjoint([e,index,'dateNaissanceConjoint']))
    
  }

  const handleChangeNationaliteConjoint = (e,index) => {

    dispatch(setSexeNationnaliteConjoint([e,index,'nationaliteConjoint']))

  }

  const handleChangeEnfant = (e,index,positionAssured) => {

    dispatch(setEnfant([e, positionAssured, index]))

  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div>
        L'assuré principale doit avoir au moins 18 ans.
      </div>
    </Tooltip>
  );

  const optionSexe = [

    { value: 'Masculin', label: 'Masculin' },
    { value: 'Féminin', label: 'Féminin' },
    { value: 'Autre', label: 'Autre' }

  ]

  return (
    <div>
      <h6 className="text-center">Assuré(e) principal(e) 
        {" "}<b style={{ 
                  color: '#ffffff',
                  background: '#C14742',
                  border: '1px solid #C14742',
                  borderRadius:'50%',
                  padding:'4px 10px'}}>
            { position + 1 }
        </b>
      </h6>

       <div className="offset-md-9 offset-4 col-lg-2 col-md-6 mt-5">
        <i onClick={() => onAssuredClose(position)} className="fas fa-trash" style={{fontSize:'15px',
            color: '#ffffff',
            background: 'rgb(220, 53, 69)',
            border: '1px solid rgb(220, 53, 69)',
            borderRadius:'50%',
            padding:'8px'}}>
        </i>
      </div>
     
      <div className="row">

          <div className="offset-md-1 col-lg-10 col-md-10 col-sm-12">
            <div className="form-group">
              <label htmlFor="date">Date de naissance<abbr>*</abbr>{" "} 
              <b>
                {
                  (old[position] >= 1) ? 
                  '(' + old[position] + `${old[position] > 1 ? 
                  ' ans' : old[position] === 1 ? 'an' : ''})` : 
                  (old[position] === 0) ? '( 0 an )' : ''
                }
              </b>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
              <i   
                className="fa-solid fa-circle-info fa-fade" 
                style={{ fontSize: '24px', color:'#bd3636'}}
              >
              </i>
            </OverlayTrigger>
              </label>

              {
                window.innerWidth > 600 ? 

                <input
                  type="date"
                  name="date"
                  id="date"
                  max={CurrentDate()}
                  onInput={(e) => handleChangeNaissanceAssurePrincipal(e.target.value,position)}
                  defaultValue={mainAssured[position].dateNaissanceAssurePrincipal}
                  className="form-control"
                  placeholder="Date de naissance"
                  key={position}
                />

                :

                <div className="input-group date" id="datepicker">
                  <input
                    type="text"
                    max={CurrentDate()}
                    onInput={(e) => handleChangeNaissanceAssurePrincipal(e.target.value,position)}
                    defaultValue={mainAssured[position].dateNaissanceAssurePrincipal}
                    className="form-control"
                    placeholder="Date de naissance"
                  />
                  <span className="input-group-append">
                    <span className="input-group-text" style={{height: '61px', background: '#ffffff'}}>
                    <i className="fa fa-calendar"></i>
                    </span>
                  </span>
                </div>
              }

                {
                  errors.dateNaissanceAssurePrincipal === 'required' ?
                  <div className="errorStyle">
                    Le champ est obligatoire.
                  </div>
                    : errors.dateNaissanceAssurePrincipal === ''  
                    ?
                  <div className="successStyle">
                    Correct ! 😊
                  </div>
                  : errors.dateNaissanceAssurePrincipal === 'errorAge'  
                  ?
                  <div className="errorStyle">
                    L'assurée principale doit avoir au moins 18 ans.
                  </div>
                  : ''
                }
            </div>
          </div>

              <div className="offset-md-1 col-lg-10 col-md-10 col-sm-12" style={{ zIndex: '5000'}}>
                <div className="form-group">
                  <label htmlFor="sexe">Sexe<abbr>*</abbr></label>

                  <Select
                    onChange={(e) => handleChangeSexeAssurePrincipal(e.value,position)}
                    defaultValue={mainAssured[position].sexeAssurePrincipal}
                    options={optionSexe}
                    id="inputState"
                    className="App"
                    name=""
                    placeholder={mainAssured[position].sexeAssurePrincipal ? mainAssured[position].sexeAssurePrincipal : "Sexe"}
                  />

                  {
                    errors.sexeAssurePrincipal === 'required' ?
                    <div className="errorStyle">
                      Le champ est obligatoire.
                    </div>
                      : errors.sexeAssurePrincipal === ''  
                      ?
                    <div className="successStyle">
                      Correct ! 😊
                    </div>
                    : ''
                  }
                </div>
              </div>

              <div style={{zIndex: '1000'}} className="offset-md-1 col-lg-10 col-md-10 col-sm-12">
                <div className="form-group">
                  <label htmlFor="nationalite">Nationalité<abbr>*</abbr></label>
                  <SelectComponent
                    handleChangePays={ (e) => handleChangeNationaliteAssurePrincipal(e.value,position)} 
                    defaultValue={mainAssured[position].nationaliteAssurePrincipal}
                    placeholder="Nationalité"
                  ></SelectComponent>
                  {
                    errors.nationaliteAssurePrincipal === 'required' ?
                    <div className="errorStyle">
                      Le champ est obligatoire.
                    </div>
                      : errors.nationaliteAssurePrincipal === ''  
                      ?
                    <div className="successStyle">
                      Correct ! 😊
                    </div>
                    : ''
                  }
                </div>
              </div>

      </div>

          <div className="container row d-flex justify-content-center offset-sm-1">

          <div className="form-check col-lg-4 col-md-4 col-sm-6">
            <input
              className={ `form-check-dimensions form-check-input` }
              type="radio"
              name="conjoint"
              id="flexRadioDefault1"
              onClick={OnRadioConjoint}
              defaultChecked={radio3}
              disabled={disabled}

            />
            <label htmlFor="conjointRadio" className="form-check-label">
              Conjoint(e)
            </label>
          </div>

  
        </div>

      

      {radioConjoint[position] ? (
        <>
        <div className="row">
          <h6 className="offset-md-1 col-lg-10 col-md-10 col-sm-12">Conjoint(e)</h6>
          <div className="offset-md-9 offset-4 col-lg-2 col-md-6">
            <i onClick={onConjointClose} className="fas fa-trash" style={{fontSize:'15px',
              color: '#ffffff',
              background: 'rgb(220, 53, 69)',
              border: '1px solid rgb(220, 53, 69)',
              borderRadius:'50%',
              padding:'8px'}}>
            </i>
          </div>
          <div className="offset-md-1 col-lg-10 col-md-6 col-sm-12">
            <div className="form-group">
              <label htmlFor="dateConjoint">Date de naissance<abbr>*</abbr>
                {/* <b>{ oldConjoint ? '(' + oldConjoint + `${oldConjoint > 1 ? 'ans' : oldConjoint === 1 ? 'an' : ''} )` : '' }</b> */}
              </label>

              {
                window.innerWidth > 600 ? 

                <input
                  type="date"
                  name="date"
                  id="date"
                  max={CurrentDate()}
                  onInput={ (e) => handleChangeConjoint(e.target.value,position)}
                  defaultValue={mainAssured && mainAssured[position].conjoint.dateNaissanceConjoint}
                  className="form-control"
                  placeholder="Date de naissance"
                />

                :

                <div className="input-group date" id="datepicker">
                  <input
                    type="text"
                    max={CurrentDate()}
                    onInput={ (e) => handleChangeConjoint(e.target.value,position)}
                    defaultValue={mainAssured && mainAssured[position].conjoint.dateNaissanceConjoint}
                    className="form-control"
                    placeholder="Date de naissance"
                  />
                  <span className="input-group-append">
                    <span className="input-group-text" style={{height: '61px', background: '#ffffff'}}>
                    <i className="fa fa-calendar"></i>
                    </span>
                  </span>
                </div>
              }
              
              {
              errors.dateNaissanceConjoint === 'required' ?
              <div className="errorStyle">
                Le champ est obligatoire.
              </div>
                : errors.dateNaissanceConjoint === ''  
                ?
              <div className="successStyle">
                Correct ! 😊
              </div>
              : ''
            }
            </div>
          </div>

          <div style={{zIndex: '1000'}} className="offset-md-1 col-lg-10 col-md-10 col-sm-12">
            <div className="form-group">
              <label htmlFor="nationaliteConjoint">Nationalité<abbr>*</abbr></label>
              <SelectComponent
                handleChangePays={(e) => handleChangeNationaliteConjoint(e.value,position)} 
                defaultValue={mainAssured && mainAssured[position].conjoint.nationaliteConjoint}
                placeholder="Nationalité"
              ></SelectComponent>
              {
              errors.nationaliteConjoint === 'required' ?
              <div className="errorStyle">
                Le champ est obligatoire.
              </div>
                : errors.nationaliteConjoint === ''  
                ?
              <div className="successStyle">
                Correct ! 😊
              </div>
              : ''
            }
            </div>
          </div>

        </div>
      </>


      ) : (
        ""
      )}

      {radioChild !== undefined && radioChild[position] !== undefined && radioChild[position].length > 0 ?
      
        radioChild[position].map((number, index) => ( 
        <>
            <ChildFormEntreprise 
                handleChangeEnfant={handleChangeEnfant} 
                position={index}
                positionAssured={position} 
                onChildClose={() => handleChildClose(index, position)} 
                >
            </ChildFormEntreprise>
        </>
        ))
      
        : ''
      }

      { disabled === false && (mainAssured[position].enfants.length === 0) || 
      (radioChild[position] && mainAssured[position].enfants.length > 0 && 
        mainAssured[position].enfants[radioChild[position].length-1] !== undefined) ?
        <>
          <div
            className="offset-md-1 col-lg-5 col-md-5 col-sm-12 mt-3"
            onClick={(e) => {
              radioChild[position] !== undefined && radioChild[position].length > 0 ? 
              setRadioChild( {...radioChild, [position]: [...radioChild[position], data] } )
              : 
              setRadioChild( {...radioChild, [position]: [data] } )
            }}
            style={{ cursor: "pointer" }}
          >
  
            <span className="fas fa-user-plus" 
              style={{fontSize:'12px',
                  color: '#ffffff',
                  background: '#BD3636',
                  border: '1px solid #BD3636',
                  borderRadius:'50%',
                  padding:'8px'}}></span>{" "}
            <span>
                <b style={{color: '#BD3636'}}>Ajouter un enfant</b>
            </span>{" "}
          </div>
          
        </>

        : ''

       } 

    <div className='offset-md-1 col-lg-10 col-md-10 col-sm-12 mt-4'><hr/></div>
    
    </div>
  ); 
}
