import React, {useState,useEffect} from "react";
import PhoneInput from 'react-phone-input-2'
import { useSelector, useDispatch } from 'react-redux';
import fr from 'react-phone-input-2/lang/fr.json'
import 'react-phone-input-2/lib/style.css'
import { setWhatsapp, setEmail, removeAllData } from "../../../features/enterpriseForm/enterpriseFormSlice";

export default function Step1({CurrentTitle,Next,activeRadio,setActiveRadio,checked,setChecked,errors,setErrors}) {

  const {whatsapp, email} = useSelector((state) => state.enterpriseData);
  const dispatch = useDispatch()


    const OnChecked = (event) => {

        if(event.target.name === "telephone") {
            setActiveRadio({...activeRadio, step1Radio1: 'checked', step1Radio2: ''});
            dispatch( setEmail(''))
        }else if(event.target.name === "email") {
            setActiveRadio({...activeRadio, step1Radio2: 'checked', step1Radio1: ''});
            dispatch( setWhatsapp(''))
        }else {
            setActiveRadio({...activeRadio, step1Radio1: "", step1Radio2: ""});
        }
        setChecked(event.target.name);      

    }

    const handleChangeWhatsapp = (value, data, event, formattedValue) => { 

      const valueWithoutDialCode = value.slice(data.dialCode.length)

      if(valueWithoutDialCode) {
        setErrors({...errors, whatsapp: '', email: 'successMessage'})
        dispatch( setWhatsapp(value))

      }else {
        setErrors({...errors, whatsapp: 'required'})
        dispatch( setWhatsapp(""))
      }

    }

    const handleChangeEmail = (e) => {

      if(!e) {
        setErrors({...errors, email: 'required'})
        dispatch( setEmail(""))

      }else {
        if (/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]{2,}[.][a-z]{2,3}$/.test(e.target.value)){ 
          setErrors({...errors, email: '', whatsapp: 'successMessage'})
          dispatch( setEmail(e.target.value))

        }else {

          dispatch( setEmail(""))
          setErrors({...errors, email: 'errorMessage'})
        }
      }

    }


    const NextStep = (e) => {

      if(whatsapp){
          Next();
      }else if (email){
          Next(); 
      }else {

        if(activeRadio.step1Radio1 === 'checked') {
          setErrors({...errors, whatsapp: 'required', email: 'noMessage'})
        }else if(activeRadio.step1Radio2 === 'checked'){
          setErrors({...errors, email: 'required', whatsapp: 'noMessage'})
        }else {

        }

      }

    }

    const rollBackAllData = () => {
      window.location.assign("/")
    }

  return (

    <div className="container">
          <h6 className="text-center">
            Par quel moyen souhaitez-vous obtenir votre devis ?
          </h6>
          <div className="row">
            <div className="form-check col-6">
              <input
                className="form-check-dimensions form-check-input"
                type="radio"
                name="telephone"
                id="flexRadioDefault1"
                onChange={OnChecked}
                checked={activeRadio.step1Radio1}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                WhatsApp
              </label>
            </div>

            <div className="form-check col-6">
              <input
                className="form-check-dimensions form-check-input"
                type="radio"
                name="email"
                id="flexRadioDefault2"
                onClick={OnChecked}
                checked={activeRadio.step1Radio2}
                
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                E-mail
              </label>
            </div>
          </div>


          <div className="row mt-4">

        {
        checked === 'email' ?

        <div className="form-group col-lg-12 col-md-12 col-sm-12">
            <div className="form-group">
                <label htmlFor="">E-mail<abbr>*</abbr></label>
                <input
                  type="email"
                  name="email"
                  onChange={handleChangeEmail}
                  defaultValue={email}
                  id="email"
                  className="form-control"
                  required
                  placeholder="Email"
                />
                {
                  errors.email === 'required' ?
                    <div className="errorStyle">
                      Le champ est obligatoire.
                    </div>
                    : errors.email === ''  
                    ?
                    <div className="successStyle">
                      Correct ! 😊
                    </div>
                    : errors.email === 'errorMessage' 
                    ?
                    <div className="errorStyle">
                      Veuillez renseigner une adresse e-mail valide.
                    </div>
                    : ''
                }
                
            </div>

            <div className="mt-5" style={{ color: 'red'}}>* Champ obligatoire</div>


            <div className={`form-footer-content-position 
            ${CurrentTitle===3 || CurrentTitle===4 || CurrentTitle===5 ? 'form-footer-margin' : ''}`}>

            {
              email && <button type="button" onClick={rollBackAllData}  className="default-btn default-btn-annuler">
              Retour </button>
            }

            <button type="button" onClick={NextStep}  style={{ marginLeft: "10px"}} className="default-btn">
            Suivant</button>
            <div className="clearfix"></div>
          </div>

          </div>
        

          : checked === 'telephone' ?
            <div className="form-group col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                <label htmlFor="">Whatsapp<abbr>*</abbr></label>
                <PhoneInput
                    country="fr"
                    enableTerritories={true}
                    value={whatsapp}
                    enableSearch={true}
                    defaultValue={whatsapp}
                    // localization={fr}
                    preserveOrder={['onlyCountries']}
                    // enableAreaCodes={true}
                    onChange={(value, data, event, formattedValue) => handleChangeWhatsapp(value, data, event, formattedValue)}
                  />
                  {
                    errors.whatsapp === 'required' ?
                      <div className="errorStyle">
                        Le champ est obligatoire.
                      </div>
                      : errors.whatsapp === ''  
                      ?
                      <div className="successStyle">
                        Correct ! 😊
                      </div>
                      : ''
                  }
                  
                </div>

                
                <div className="mt-5" style={{ color: 'red'}}>* Champ obligatoire</div>

                <div className={`form-footer-content-position 
                ${CurrentTitle ===3 || CurrentTitle ===4 || CurrentTitle ===5 ? 'form-footer-margin' : ''}`}> 

                {
                  whatsapp && <button type="button" onClick={rollBackAllData}  className="default-btn-annuler">
                  Retour </button>
                }

                <button type="button" onClick={NextStep}  style={{ marginLeft: "10px"}} className="default-btn">
                Suivant</button><span></span>
                <div className="clearfix"></div>
                </div>

            </div>
        : ""
        }

        
      </div>
          
    </div>
  );
}
