import React from 'react';

// const COUNTRIES = [
const COUNTRIES = {
    "AF": "Afghanistan",
    "ZA": "Afrique du Sud",
    "AL": "Albanie",
    "DZ": "Algérie",
    "DE": "Allemagne",
    "AD": "Andorre",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctique",
    "AG": "Antigua-et-Barbuda",
    "AN": "Antilles néerlandaises",
    "SA": "Arabie saoudite",
    "AR": "Argentine",
    "AM": "Arménie",
    "AW": "Aruba",
    "AU": "Australie",
    "AT": "Autriche",
    "AZ": "Azerbaïdjan",
    "BS": "Bahamas",
    "BH": "Bahreïn",
    "BD": "Bangladesh",
    "BB": "Barbade",
    "BY": "Bélarus",
    "BE": "Belgique",
    "BZ": "Belize",
    "BJ": "Bénin",
    "BM": "Bermudes",
    "BT": "Bhoutan",
    "BO": "Bolivie",
    "BA": "Bosnie-Herzégovine",
    "BW": "Botswana",
    "BR": "Brésil",
    "BN": "Brunéi Darussalam",
    "BG": "Bulgarie",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Cambodge",
    "CM": "Cameroun",
    "CA": "Canada",
    "CV": "Cap-Vert",
    "EA": "Ceuta et Melilla",
    "CL": "Chili",
    "CN": "Chine",
    "CY": "Chypre",
    "CO": "Colombie",
    "KM": "Comores",
    "CG": "Congo-Brazzaville",
    "KP": "Corée du Nord",
    "KR": "Corée du Sud",
    "CR": "Costa Rica",
    "CI": "Côte d’Ivoire",
    "HR": "Croatie",
    "CU": "Cuba",
    "DK": "Danemark",
    "DG": "Diego Garcia",
    "DJ": "Djibouti",
    "DM": "Dominique",
    "EG": "Égypte",
    "SV": "El Salvador",
    "AE": "Émirats arabes unis",
    "EC": "Équateur",
    "ER": "Érythrée",
    "ES": "Espagne",
    "EE": "Estonie",
    "VA": "État de la Cité du Vatican",
    "FM": "États fédérés de Micronésie",
    "US": "États-Unis",
    "ET": "Éthiopie",
    "FJ": "Fidji",
    "FI": "Finlande",
    "FR": "France",
    "GA": "Gabon",
    "GM": "Gambie",
    "GE": "Géorgie",
    "GS": "Géorgie du Sud et les îles Sandwich du Sud",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Grèce",
    "GD": "Grenade",
    "GL": "Groenland",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernesey",
    "GN": "Guinée",
    "GQ": "Guinée équatoriale",
    "GW": "Guinée-Bissau",
    "GY": "Guyana",
    "GF": "Guyane française",
    "HT": "Haïti",
    "HN": "Honduras",
    "HU": "Hongrie",
    "BV": "Île Bouvet",
    "CX": "Île Christmas",
    "CP": "Île Clipperton",
    "AC": "Île de l'Ascension",
    "IM": "Île de Man",
    "NF": "Île Norfolk",
    "AX": "Îles Åland",
    "KY": "Îles Caïmans",
    "IC": "Îles Canaries",
    "CC": "Îles Cocos - Keeling",
    "CK": "Îles Cook",
    "FO": "Îles Féroé",
    "HM": "Îles Heard et MacDonald",
    "FK": "Îles Malouines",
    "MP": "Îles Mariannes du Nord",
    "MH": "Îles Marshall",
    "UM": "Îles Mineures Éloignées des États-Unis",
    "SB": "Îles Salomon",
    "TC": "Îles Turks et Caïques",
    "VG": "Îles Vierges britanniques",
    "VI": "Îles Vierges des États-Unis",
    "IN": "Inde",
    "ID": "Indonésie",
    "IQ": "Irak",
    "IR": "Iran",
    "IE": "Irlande",
    "IS": "Islande",
    "IL": "Israël",
    "IT": "Italie",
    "JM": "Jamaïque",
    "JP": "Japon",
    "JE": "Jersey",
    "JO": "Jordanie",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KG": "Kirghizistan",
    "KI": "Kiribati",
    "KW": "Koweït",
    "LA": "Laos",
    "LS": "Lesotho",
    "LV": "Lettonie",
    "LB": "Liban",
    "LR": "Libéria",
    "LY": "Libye",
    "LI": "Liechtenstein",
    "LT": "Lituanie",
    "LU": "Luxembourg",
    "MK": "Macédoine",
    "MG": "Madagascar",
    "MY": "Malaisie",
    "MW": "Malawi",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malte",
    "MA": "Maroc",
    "MQ": "Martinique",
    "MU": "Maurice",
    "MR": "Mauritanie",
    "YT": "Mayotte",
    "MX": "Mexique",
    "MD": "Moldavie",
    "MC": "Monaco",
    "MN": "Mongolie",
    "ME": "Monténégro",
    "MS": "Montserrat",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibie",
    "NR": "Nauru",
    "NP": "Népal",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigéria",
    "NU": "Niue",
    "NO": "Norvège",
    "NC": "Nouvelle-Calédonie",
    "NZ": "Nouvelle-Zélande",
    "OM": "Oman",
    "UG": "Ouganda",
    "UZ": "Ouzbékistan",
    "PK": "Pakistan",
    "PW": "Palaos",
    "PA": "Panama",
    "PG": "Papouasie-Nouvelle-Guinée",
    "PY": "Paraguay",
    "NL": "Pays-Bas",
    "PE": "Pérou",
    "PH": "Philippines",
    "PN": "Pitcairn",
    "PL": "Pologne",
    "PF": "Polynésie française",
    "PR": "Porto Rico",
    "PT": "Portugal",
    "QA": "Qatar",
    "HK": "R.A.S. chinoise de Hong Kong",
    "MO": "R.A.S. chinoise de Macao",
    "QO": "régions éloignées de l’Océanie",
    "CF": "République centrafricaine",
    "CD": "République démocratique du Congo",
    "DO": "République dominicaine",
    "CZ": "République tchèque",
    "RE": "Réunion",
    "RO": "Roumanie",
    "GB": "Royaume-Uni",
    "RU": "Russie",
    "RW": "Rwanda",
    "EH": "Sahara occidental",
    "BL": "Saint-Barthélémy",
    "KN": "Saint-Kitts-et-Nevis",
    "SM": "Saint-Marin",
    "MF": "Saint-Martin",
    "PM": "Saint-Pierre-et-Miquelon",
    "VC": "Saint-Vincent-et-les Grenadines",
    "SH": "Sainte-Hélène",
    "LC": "Sainte-Lucie",
    "WS": "Samoa",
    "AS": "Samoa américaines",
    "ST": "Sao Tomé-et-Principe",
    "SN": "Sénégal",
    "RS": "Serbie",
    "CS": "Serbie-et-Monténégro",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapour",
    "SK": "Slovaquie",
    "SI": "Slovénie",
    "SO": "Somalie",
    "SD": "Soudan",
    "LK": "Sri Lanka",
    "SE": "Suède",
    "CH": "Suisse",
    "SR": "Suriname",
    "SJ": "Svalbard et Île Jan Mayen",
    "SZ": "Swaziland",
    "SY": "Syrie",
    "TJ": "Tadjikistan",
    "TW": "Taïwan",
    "TZ": "Tanzanie",
    "TD": "Tchad",
    "TF": "Terres australes françaises",
    "IO": "Territoire britannique de l'océan Indien",
    "PS": "Territoire palestinien",
    "TH": "Thaïlande",
    "TL": "Timor oriental",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinité-et-Tobago",
    "TA": "Tristan da Cunha",
    "TN": "Tunisie",
    "TM": "Turkménistan",
    "TR": "Turquie",
    "TV": "Tuvalu",
    "UA": "Ukraine",
    "EU": "Union européenne",
    "UY": "Uruguay",
    "VU": "Vanuatu",
    "VE": "Venezuela",
    "VN": "Viêt Nam",
    "WF": "Wallis-et-Futuna",
    "YE": "Yémen",
    "ZM": "Zambie",
    "ZW": "Zimbabwe"
  } 
//     {
//         "id": 1,
//         "code": "AF",
//         "name": "Afghanistan",
//         "phonecode": 93
//     },
//     {
//         "id": 2,
//         "code": "AL",
//         "name": "Albania",
//         "phonecode": 355
//     },
//     {
//         "id": 3,
//         "code": "DZ",
//         "name": "Algeria",
//         "phonecode": 213
//     },
//     {
//         "id": 4,
//         "code": "AS",
//         "name": "American Samoa",
//         "phonecode": 1684
//     },
//     {
//         "id": 5,
//         "code": "AD",
//         "name": "Andorra",
//         "phonecode": 376
//     },
//     {
//         "id": 6,
//         "code": "AO",
//         "name": "Angola",
//         "phonecode": 244
//     },
//     {
//         "id": 7,
//         "code": "AI",
//         "name": "Anguilla",
//         "phonecode": 1264
//     },
//     {
//         "id": 8,
//         "code": "AQ",
//         "name": "Antarctica",
//         "phonecode": 0
//     },
//     {
//         "id": 9,
//         "code": "AG",
//         "name": "Antigua And Barbuda",
//         "phonecode": 1268
//     },
//     {
//         "id": 10,
//         "code": "AR",
//         "name": "Argentina",
//         "phonecode": 54
//     },
//     {
//         "id": 11,
//         "code": "AM",
//         "name": "Armenia",
//         "phonecode": 374
//     },
//     {
//         "id": 12,
//         "code": "AW",
//         "name": "Aruba",
//         "phonecode": 297
//     },
//     {
//         "id": 13,
//         "code": "AU",
//         "name": "Australia",
//         "phonecode": 61
//     },
//     {
//         "id": 14,
//         "code": "AT",
//         "name": "Austria",
//         "phonecode": 43
//     },
//     {
//         "id": 15,
//         "code": "AZ",
//         "name": "Azerbaijan",
//         "phonecode": 994
//     },
//     {
//         "id": 16,
//         "code": "BS",
//         "name": "Bahamas The",
//         "phonecode": 1242
//     },
//     {
//         "id": 17,
//         "code": "BH",
//         "name": "Bahrain",
//         "phonecode": 973
//     },
//     {
//         "id": 18,
//         "code": "BD",
//         "name": "Bangladesh",
//         "phonecode": 880
//     },
//     {
//         "id": 19,
//         "code": "BB",
//         "name": "Barbados",
//         "phonecode": 1246
//     },
//     {
//         "id": 20,
//         "code": "BY",
//         "name": "Belarus",
//         "phonecode": 375
//     },
//     {
//         "id": 21,
//         "code": "BE",
//         "name": "Belgium",
//         "phonecode": 32
//     },
//     {
//         "id": 22,
//         "code": "BZ",
//         "name": "Belize",
//         "phonecode": 501
//     },
//     {
//         "id": 23,
//         "code": "BJ",
//         "name": "Benin",
//         "phonecode": 229
//     },
//     {
//         "id": 24,
//         "code": "BM",
//         "name": "Bermuda",
//         "phonecode": 1441
//     },
//     {
//         "id": 25,
//         "code": "BT",
//         "name": "Bhutan",
//         "phonecode": 975
//     },
//     {
//         "id": 26,
//         "code": "BO",
//         "name": "Bolivia",
//         "phonecode": 591
//     },
//     {
//         "id": 27,
//         "code": "BA",
//         "name": "Bosnia and Herzegovina",
//         "phonecode": 387
//     },
//     {
//         "id": 28,
//         "code": "BW",
//         "name": "Botswana",
//         "phonecode": 267
//     },
//     {
//         "id": 29,
//         "code": "BV",
//         "name": "Bouvet Island",
//         "phonecode": 0
//     },
//     {
//         "id": 30,
//         "code": "BR",
//         "name": "Brazil",
//         "phonecode": 55
//     },
//     {
//         "id": 31,
//         "code": "IO",
//         "name": "British Indian Ocean Territory",
//         "phonecode": 246
//     },
//     {
//         "id": 32,
//         "code": "BN",
//         "name": "Brunei",
//         "phonecode": 673
//     },
//     {
//         "id": 33,
//         "code": "BG",
//         "name": "Bulgaria",
//         "phonecode": 359
//     },
//     {
//         "id": 34,
//         "code": "BF",
//         "name": "Burkina Faso",
//         "phonecode": 226
//     },
//     {
//         "id": 35,
//         "code": "BI",
//         "name": "Burundi",
//         "phonecode": 257
//     },
//     {
//         "id": 36,
//         "code": "KH",
//         "name": "Cambodia",
//         "phonecode": 855
//     },
//     {
//         "id": 37,
//         "code": "CM",
//         "name": "Cameroon",
//         "phonecode": 237
//     },
//     {
//         "id": 38,
//         "code": "CA",
//         "name": "Canada",
//         "phonecode": 1
//     },
//     {
//         "id": 39,
//         "code": "CV",
//         "name": "Cape Verde",
//         "phonecode": 238
//     },
//     {
//         "id": 40,
//         "code": "KY",
//         "name": "Cayman Islands",
//         "phonecode": 1345
//     },
//     {
//         "id": 41,
//         "code": "CF",
//         "name": "Central African Republic",
//         "phonecode": 236
//     },
//     {
//         "id": 42,
//         "code": "TD",
//         "name": "Chad",
//         "phonecode": 235
//     },
//     {
//         "id": 43,
//         "code": "CL",
//         "name": "Chile",
//         "phonecode": 56
//     },
//     {
//         "id": 44,
//         "code": "CN",
//         "name": "China",
//         "phonecode": 86
//     },
//     {
//         "id": 45,
//         "code": "CX",
//         "name": "Christmas Island",
//         "phonecode": 61
//     },
//     {
//         "id": 46,
//         "code": "CC",
//         "name": "Cocos (Keeling) Islands",
//         "phonecode": 672
//     },
//     {
//         "id": 47,
//         "code": "CO",
//         "name": "Colombia",
//         "phonecode": 57
//     },
//     {
//         "id": 48,
//         "code": "KM",
//         "name": "Comoros",
//         "phonecode": 269
//     },
//     {
//         "id": 49,
//         "code": "CG",
//         "name": "Congo",
//         "phonecode": 242
//     },
//     {
//         "id": 50,
//         "code": "CD",
//         "name": "Congo The Democratic Republic Of The",
//         "phonecode": 242
//     },
//     {
//         "id": 51,
//         "code": "CK",
//         "name": "Cook Islands",
//         "phonecode": 682
//     },
//     {
//         "id": 52,
//         "code": "CR",
//         "name": "Costa Rica",
//         "phonecode": 506
//     },
//     {
//         "id": 53,
//         "code": "CI",
//         "name": "Cote D Ivoire (Ivory Coast)",
//         "phonecode": 225
//     },
//     {
//         "id": 54,
//         "code": "HR",
//         "name": "Croatia (Hrvatska)",
//         "phonecode": 385
//     },
//     {
//         "id": 55,
//         "code": "CU",
//         "name": "Cuba",
//         "phonecode": 53
//     },
//     {
//         "id": 56,
//         "code": "CY",
//         "name": "Cyprus",
//         "phonecode": 357
//     },
//     {
//         "id": 57,
//         "code": "CZ",
//         "name": "Czech Republic",
//         "phonecode": 420
//     },
//     {
//         "id": 58,
//         "code": "DK",
//         "name": "Denmark",
//         "phonecode": 45
//     },
//     {
//         "id": 59,
//         "code": "DJ",
//         "name": "Djibouti",
//         "phonecode": 253
//     },
//     {
//         "id": 60,
//         "code": "DM",
//         "name": "Dominica",
//         "phonecode": 1767
//     },
//     {
//         "id": 61,
//         "code": "DO",
//         "name": "Dominican Republic",
//         "phonecode": 1809
//     },
//     {
//         "id": 62,
//         "code": "TP",
//         "name": "East Timor",
//         "phonecode": 670
//     },
//     {
//         "id": 63,
//         "code": "EC",
//         "name": "Ecuador",
//         "phonecode": 593
//     },
//     {
//         "id": 64,
//         "code": "EG",
//         "name": "Egypt",
//         "phonecode": 20
//     },
//     {
//         "id": 65,
//         "code": "SV",
//         "name": "El Salvador",
//         "phonecode": 503
//     },
//     {
//         "id": 66,
//         "code": "GQ",
//         "name": "Equatorial Guinea",
//         "phonecode": 240
//     },
//     {
//         "id": 67,
//         "code": "ER",
//         "name": "Eritrea",
//         "phonecode": 291
//     },
//     {
//         "id": 68,
//         "code": "EE",
//         "name": "Estonia",
//         "phonecode": 372
//     },
//     {
//         "id": 69,
//         "code": "ET",
//         "name": "Ethiopia",
//         "phonecode": 251
//     },
//     {
//         "id": 70,
//         "code": "XA",
//         "name": "External Territories of Australia",
//         "phonecode": 61
//     },
//     {
//         "id": 71,
//         "code": "FK",
//         "name": "Falkland Islands",
//         "phonecode": 500
//     },
//     {
//         "id": 72,
//         "code": "FO",
//         "name": "Faroe Islands",
//         "phonecode": 298
//     },
//     {
//         "id": 73,
//         "code": "FJ",
//         "name": "Fiji Islands",
//         "phonecode": 679
//     },
//     {
//         "id": 74,
//         "code": "FI",
//         "name": "Finland",
//         "phonecode": 358
//     },
//     {
//         "id": 75,
//         "code": "FR",
//         "name": "France",
//         "phonecode": 33
//     },
//     {
//         "id": 76,
//         "code": "GF",
//         "name": "French Guiana",
//         "phonecode": 594
//     },
//     {
//         "id": 77,
//         "code": "PF",
//         "name": "French Polynesia",
//         "phonecode": 689
//     },
//     {
//         "id": 78,
//         "code": "TF",
//         "name": "French Southern Territories",
//         "phonecode": 0
//     },
//     {
//         "id": 79,
//         "code": "GA",
//         "name": "Gabon",
//         "phonecode": 241
//     },
//     {
//         "id": 80,
//         "code": "GM",
//         "name": "Gambia The",
//         "phonecode": 220
//     },
//     {
//         "id": 81,
//         "code": "GE",
//         "name": "Georgia",
//         "phonecode": 995
//     },
//     {
//         "id": 82,
//         "code": "DE",
//         "name": "Germany",
//         "phonecode": 49
//     },
//     {
//         "id": 83,
//         "code": "GH",
//         "name": "Ghana",
//         "phonecode": 233
//     },
//     {
//         "id": 84,
//         "code": "GI",
//         "name": "Gibraltar",
//         "phonecode": 350
//     },
//     {
//         "id": 85,
//         "code": "GR",
//         "name": "Greece",
//         "phonecode": 30
//     },
//     {
//         "id": 86,
//         "code": "GL",
//         "name": "Greenland",
//         "phonecode": 299
//     },
//     {
//         "id": 87,
//         "code": "GD",
//         "name": "Grenada",
//         "phonecode": 1473
//     },
//     {
//         "id": 88,
//         "code": "GP",
//         "name": "Guadeloupe",
//         "phonecode": 590
//     },
//     {
//         "id": 89,
//         "code": "GU",
//         "name": "Guam",
//         "phonecode": 1671
//     },
//     {
//         "id": 90,
//         "code": "GT",
//         "name": "Guatemala",
//         "phonecode": 502
//     },
//     {
//         "id": 91,
//         "code": "XU",
//         "name": "Guernsey and Alderney",
//         "phonecode": 44
//     },
//     {
//         "id": 92,
//         "code": "GN",
//         "name": "Guinea",
//         "phonecode": 224
//     },
//     {
//         "id": 93,
//         "code": "GW",
//         "name": "Guinea-Bissau",
//         "phonecode": 245
//     },
//     {
//         "id": 94,
//         "code": "GY",
//         "name": "Guyana",
//         "phonecode": 592
//     },
//     {
//         "id": 95,
//         "code": "HT",
//         "name": "Haiti",
//         "phonecode": 509
//     },
//     {
//         "id": 96,
//         "code": "HM",
//         "name": "Heard and McDonald Islands",
//         "phonecode": 0
//     },
//     {
//         "id": 97,
//         "code": "HN",
//         "name": "Honduras",
//         "phonecode": 504
//     },
//     {
//         "id": 98,
//         "code": "HK",
//         "name": "Hong Kong S.A.R.",
//         "phonecode": 852
//     },
//     {
//         "id": 99,
//         "code": "HU",
//         "name": "Hungary",
//         "phonecode": 36
//     },
//     {
//         "id": 100,
//         "code": "IS",
//         "name": "Iceland",
//         "phonecode": 354
//     },
//     {
//         "id": 101,
//         "code": "IN",
//         "name": "India",
//         "phonecode": 91
//     },
//     {
//         "id": 102,
//         "code": "ID",
//         "name": "Indonesia",
//         "phonecode": 62
//     },
//     {
//         "id": 103,
//         "code": "IR",
//         "name": "Iran",
//         "phonecode": 98
//     },
//     {
//         "id": 104,
//         "code": "IQ",
//         "name": "Iraq",
//         "phonecode": 964
//     },
//     {
//         "id": 105,
//         "code": "IE",
//         "name": "Ireland",
//         "phonecode": 353
//     },
//     {
//         "id": 106,
//         "code": "IL",
//         "name": "Israel",
//         "phonecode": 972
//     },
//     {
//         "id": 107,
//         "code": "IT",
//         "name": "Italy",
//         "phonecode": 39
//     },
//     {
//         "id": 108,
//         "code": "JM",
//         "name": "Jamaica",
//         "phonecode": 1876
//     },
//     {
//         "id": 109,
//         "code": "JP",
//         "name": "Japan",
//         "phonecode": 81
//     },
//     {
//         "id": 110,
//         "code": "XJ",
//         "name": "Jersey",
//         "phonecode": 44
//     },
//     {
//         "id": 111,
//         "code": "JO",
//         "name": "Jordan",
//         "phonecode": 962
//     },
//     {
//         "id": 112,
//         "code": "KZ",
//         "name": "Kazakhstan",
//         "phonecode": 7
//     },
//     {
//         "id": 113,
//         "code": "KE",
//         "name": "Kenya",
//         "phonecode": 254
//     },
//     {
//         "id": 114,
//         "code": "KI",
//         "name": "Kiribati",
//         "phonecode": 686
//     },
//     {
//         "id": 115,
//         "code": "KP",
//         "name": "Korea North",
//         "phonecode": 850
//     },
//     {
//         "id": 116,
//         "code": "KR",
//         "name": "Korea South",
//         "phonecode": 82
//     },
//     {
//         "id": 117,
//         "code": "KW",
//         "name": "Kuwait",
//         "phonecode": 965
//     },
//     {
//         "id": 118,
//         "code": "KG",
//         "name": "Kyrgyzstan",
//         "phonecode": 996
//     },
//     {
//         "id": 119,
//         "code": "LA",
//         "name": "Laos",
//         "phonecode": 856
//     },
//     {
//         "id": 120,
//         "code": "LV",
//         "name": "Latvia",
//         "phonecode": 371
//     },
//     {
//         "id": 121,
//         "code": "LB",
//         "name": "Lebanon",
//         "phonecode": 961
//     },
//     {
//         "id": 122,
//         "code": "LS",
//         "name": "Lesotho",
//         "phonecode": 266
//     },
//     {
//         "id": 123,
//         "code": "LR",
//         "name": "Liberia",
//         "phonecode": 231
//     },
//     {
//         "id": 124,
//         "code": "LY",
//         "name": "Libya",
//         "phonecode": 218
//     },
//     {
//         "id": 125,
//         "code": "LI",
//         "name": "Liechtenstein",
//         "phonecode": 423
//     },
//     {
//         "id": 126,
//         "code": "LT",
//         "name": "Lithuania",
//         "phonecode": 370
//     },
//     {
//         "id": 127,
//         "code": "LU",
//         "name": "Luxembourg",
//         "phonecode": 352
//     },
//     {
//         "id": 128,
//         "code": "MO",
//         "name": "Macau S.A.R.",
//         "phonecode": 853
//     },
//     {
//         "id": 129,
//         "code": "MK",
//         "name": "Macedonia",
//         "phonecode": 389
//     },
//     {
//         "id": 130,
//         "code": "MG",
//         "name": "Madagascar",
//         "phonecode": 261
//     },
//     {
//         "id": 131,
//         "code": "MW",
//         "name": "Malawi",
//         "phonecode": 265
//     },
//     {
//         "id": 132,
//         "code": "MY",
//         "name": "Malaysia",
//         "phonecode": 60
//     },
//     {
//         "id": 133,
//         "code": "MV",
//         "name": "Maldives",
//         "phonecode": 960
//     },
//     {
//         "id": 134,
//         "code": "ML",
//         "name": "Mali",
//         "phonecode": 223
//     },
//     {
//         "id": 135,
//         "code": "MT",
//         "name": "Malta",
//         "phonecode": 356
//     },
//     {
//         "id": 136,
//         "code": "XM",
//         "name": "Man (Isle of)",
//         "phonecode": 44
//     },
//     {
//         "id": 137,
//         "code": "MH",
//         "name": "Marshall Islands",
//         "phonecode": 692
//     },
//     {
//         "id": 138,
//         "code": "MQ",
//         "name": "Martinique",
//         "phonecode": 596
//     },
//     {
//         "id": 139,
//         "code": "MR",
//         "name": "Mauritania",
//         "phonecode": 222
//     },
//     {
//         "id": 140,
//         "code": "MU",
//         "name": "Mauritius",
//         "phonecode": 230
//     },
//     {
//         "id": 141,
//         "code": "YT",
//         "name": "Mayotte",
//         "phonecode": 269
//     },
//     {
//         "id": 142,
//         "code": "MX",
//         "name": "Mexico",
//         "phonecode": 52
//     },
//     {
//         "id": 143,
//         "code": "FM",
//         "name": "Micronesia",
//         "phonecode": 691
//     },
//     {
//         "id": 144,
//         "code": "MD",
//         "name": "Moldova",
//         "phonecode": 373
//     },
//     {
//         "id": 145,
//         "code": "MC",
//         "name": "Monaco",
//         "phonecode": 377
//     },
//     {
//         "id": 146,
//         "code": "MN",
//         "name": "Mongolia",
//         "phonecode": 976
//     },
//     {
//         "id": 147,
//         "code": "MS",
//         "name": "Montserrat",
//         "phonecode": 1664
//     },
//     {
//         "id": 148,
//         "code": "MA",
//         "name": "Morocco",
//         "phonecode": 212
//     },
//     {
//         "id": 149,
//         "code": "MZ",
//         "name": "Mozambique",
//         "phonecode": 258
//     },
//     {
//         "id": 150,
//         "code": "MM",
//         "name": "Myanmar",
//         "phonecode": 95
//     },
//     {
//         "id": 151,
//         "code": "NA",
//         "name": "Namibia",
//         "phonecode": 264
//     },
//     {
//         "id": 152,
//         "code": "NR",
//         "name": "Nauru",
//         "phonecode": 674
//     },
//     {
//         "id": 153,
//         "code": "NP",
//         "name": "Nepal",
//         "phonecode": 977
//     },
//     {
//         "id": 154,
//         "code": "AN",
//         "name": "Netherlands Antilles",
//         "phonecode": 599
//     },
//     {
//         "id": 155,
//         "code": "NL",
//         "name": "Netherlands The",
//         "phonecode": 31
//     },
//     {
//         "id": 156,
//         "code": "NC",
//         "name": "New Caledonia",
//         "phonecode": 687
//     },
//     {
//         "id": 157,
//         "code": "NZ",
//         "name": "New Zealand",
//         "phonecode": 64
//     },
//     {
//         "id": 158,
//         "code": "NI",
//         "name": "Nicaragua",
//         "phonecode": 505
//     },
//     {
//         "id": 159,
//         "code": "NE",
//         "name": "Niger",
//         "phonecode": 227
//     },
//     {
//         "id": 160,
//         "code": "NG",
//         "name": "Nigeria",
//         "phonecode": 234
//     },
//     {
//         "id": 161,
//         "code": "NU",
//         "name": "Niue",
//         "phonecode": 683
//     },
//     {
//         "id": 162,
//         "code": "NF",
//         "name": "Norfolk Island",
//         "phonecode": 672
//     },
//     {
//         "id": 163,
//         "code": "MP",
//         "name": "Northern Mariana Islands",
//         "phonecode": 1670
//     },
//     {
//         "id": 164,
//         "code": "NO",
//         "name": "Norway",
//         "phonecode": 47
//     },
//     {
//         "id": 165,
//         "code": "OM",
//         "name": "Oman",
//         "phonecode": 968
//     },
//     {
//         "id": 166,
//         "code": "PK",
//         "name": "Pakistan",
//         "phonecode": 92
//     },
//     {
//         "id": 167,
//         "code": "PW",
//         "name": "Palau",
//         "phonecode": 680
//     },
//     {
//         "id": 168,
//         "code": "PS",
//         "name": "Palestinian Territory Occupied",
//         "phonecode": 970
//     },
//     {
//         "id": 169,
//         "code": "PA",
//         "name": "Panama",
//         "phonecode": 507
//     },
//     {
//         "id": 170,
//         "code": "PG",
//         "name": "Papua new Guinea",
//         "phonecode": 675
//     },
//     {
//         "id": 171,
//         "code": "PY",
//         "name": "Paraguay",
//         "phonecode": 595
//     },
//     {
//         "id": 172,
//         "code": "PE",
//         "name": "Peru",
//         "phonecode": 51
//     },
//     {
//         "id": 173,
//         "code": "PH",
//         "name": "Philippines",
//         "phonecode": 63
//     },
//     {
//         "id": 174,
//         "code": "PN",
//         "name": "Pitcairn Island",
//         "phonecode": 0
//     },
//     {
//         "id": 175,
//         "code": "PL",
//         "name": "Poland",
//         "phonecode": 48
//     },
//     {
//         "id": 176,
//         "code": "PT",
//         "name": "Portugal",
//         "phonecode": 351
//     },
//     {
//         "id": 177,
//         "code": "PR",
//         "name": "Puerto Rico",
//         "phonecode": 1787
//     },
//     {
//         "id": 178,
//         "code": "QA",
//         "name": "Qatar",
//         "phonecode": 974
//     },
//     {
//         "id": 179,
//         "code": "RE",
//         "name": "Reunion",
//         "phonecode": 262
//     },
//     {
//         "id": 180,
//         "code": "RO",
//         "name": "Romania",
//         "phonecode": 40
//     },
//     {
//         "id": 181,
//         "code": "RU",
//         "name": "Russia",
//         "phonecode": 70
//     },
//     {
//         "id": 182,
//         "code": "RW",
//         "name": "Rwanda",
//         "phonecode": 250
//     },
//     {
//         "id": 183,
//         "code": "SH",
//         "name": "Saint Helena",
//         "phonecode": 290
//     },
//     {
//         "id": 184,
//         "code": "KN",
//         "name": "Saint Kitts And Nevis",
//         "phonecode": 1869
//     },
//     {
//         "id": 185,
//         "code": "LC",
//         "name": "Saint Lucia",
//         "phonecode": 1758
//     },
//     {
//         "id": 186,
//         "code": "PM",
//         "name": "Saint Pierre and Miquelon",
//         "phonecode": 508
//     },
//     {
//         "id": 187,
//         "code": "VC",
//         "name": "Saint Vincent And The Grenadines",
//         "phonecode": 1784
//     },
//     {
//         "id": 188,
//         "code": "WS",
//         "name": "Samoa",
//         "phonecode": 684
//     },
//     {
//         "id": 189,
//         "code": "SM",
//         "name": "San Marino",
//         "phonecode": 378
//     },
//     {
//         "id": 190,
//         "code": "ST",
//         "name": "Sao Tome and Principe",
//         "phonecode": 239
//     },
//     {
//         "id": 191,
//         "code": "SA",
//         "name": "Saudi Arabia",
//         "phonecode": 966
//     },
//     {
//         "id": 192,
//         "code": "SN",
//         "name": "Senegal",
//         "phonecode": 221
//     },
//     {
//         "id": 193,
//         "code": "RS",
//         "name": "Serbia",
//         "phonecode": 381
//     },
//     {
//         "id": 194,
//         "code": "SC",
//         "name": "Seychelles",
//         "phonecode": 248
//     },
//     {
//         "id": 195,
//         "code": "SL",
//         "name": "Sierra Leone",
//         "phonecode": 232
//     },
//     {
//         "id": 196,
//         "code": "SG",
//         "name": "Singapore",
//         "phonecode": 65
//     },
//     {
//         "id": 197,
//         "code": "SK",
//         "name": "Slovakia",
//         "phonecode": 421
//     },
//     {
//         "id": 198,
//         "code": "SI",
//         "name": "Slovenia",
//         "phonecode": 386
//     },
//     {
//         "id": 199,
//         "code": "XG",
//         "name": "Smaller Territories of the UK",
//         "phonecode": 44
//     },
//     {
//         "id": 200,
//         "code": "SB",
//         "name": "Solomon Islands",
//         "phonecode": 677
//     },
//     {
//         "id": 201,
//         "code": "SO",
//         "name": "Somalia",
//         "phonecode": 252
//     },
//     {
//         "id": 202,
//         "code": "ZA",
//         "name": "South Africa",
//         "phonecode": 27
//     },
//     {
//         "id": 203,
//         "code": "GS",
//         "name": "South Georgia",
//         "phonecode": 0
//     },
//     {
//         "id": 204,
//         "code": "SS",
//         "name": "South Sudan",
//         "phonecode": 211
//     },
//     {
//         "id": 205,
//         "code": "ES",
//         "name": "Spain",
//         "phonecode": 34
//     },
//     {
//         "id": 206,
//         "code": "LK",
//         "name": "Sri Lanka",
//         "phonecode": 94
//     },
//     {
//         "id": 207,
//         "code": "SD",
//         "name": "Sudan",
//         "phonecode": 249
//     },
//     {
//         "id": 208,
//         "code": "SR",
//         "name": "Suriname",
//         "phonecode": 597
//     },
//     {
//         "id": 209,
//         "code": "SJ",
//         "name": "Svalbard And Jan Mayen Islands",
//         "phonecode": 47
//     },
//     {
//         "id": 210,
//         "code": "SZ",
//         "name": "Swaziland",
//         "phonecode": 268
//     },
//     {
//         "id": 211,
//         "code": "SE",
//         "name": "Sweden",
//         "phonecode": 46
//     },
//     {
//         "id": 212,
//         "code": "CH",
//         "name": "Switzerland",
//         "phonecode": 41
//     },
//     {
//         "id": 213,
//         "code": "SY",
//         "name": "Syria",
//         "phonecode": 963
//     },
//     {
//         "id": 214,
//         "code": "TW",
//         "name": "Taiwan",
//         "phonecode": 886
//     },
//     {
//         "id": 215,
//         "code": "TJ",
//         "name": "Tajikistan",
//         "phonecode": 992
//     },
//     {
//         "id": 216,
//         "code": "TZ",
//         "name": "Tanzania",
//         "phonecode": 255
//     },
//     {
//         "id": 217,
//         "code": "TH",
//         "name": "Thailand",
//         "phonecode": 66
//     },
//     {
//         "id": 218,
//         "code": "TG",
//         "name": "Togo",
//         "phonecode": 228
//     },
//     {
//         "id": 219,
//         "code": "TK",
//         "name": "Tokelau",
//         "phonecode": 690
//     },
//     {
//         "id": 220,
//         "code": "TO",
//         "name": "Tonga",
//         "phonecode": 676
//     },
//     {
//         "id": 221,
//         "code": "TT",
//         "name": "Trinidad And Tobago",
//         "phonecode": 1868
//     },
//     {
//         "id": 222,
//         "code": "TN",
//         "name": "Tunisia",
//         "phonecode": 216
//     },
//     {
//         "id": 223,
//         "code": "TR",
//         "name": "Turkey",
//         "phonecode": 90
//     },
//     {
//         "id": 224,
//         "code": "TM",
//         "name": "Turkmenistan",
//         "phonecode": 7370
//     },
//     {
//         "id": 225,
//         "code": "TC",
//         "name": "Turks And Caicos Islands",
//         "phonecode": 1649
//     },
//     {
//         "id": 226,
//         "code": "TV",
//         "name": "Tuvalu",
//         "phonecode": 688
//     },
//     {
//         "id": 227,
//         "code": "UG",
//         "name": "Uganda",
//         "phonecode": 256
//     },
//     {
//         "id": 228,
//         "code": "UA",
//         "name": "Ukraine",
//         "phonecode": 380
//     },
//     {
//         "id": 229,
//         "code": "AE",
//         "name": "United Arab Emirates",
//         "phonecode": 971
//     },
//     {
//         "id": 230,
//         "code": "GB",
//         "name": "United Kingdom",
//         "phonecode": 44
//     },
//     {
//         "id": 231,
//         "code": "US",
//         "name": "United States",
//         "phonecode": 1
//     },
//     {
//         "id": 232,
//         "code": "UM",
//         "name": "United States Minor Outlying Islands",
//         "phonecode": 1
//     },
//     {
//         "id": 233,
//         "code": "UY",
//         "name": "Uruguay",
//         "phonecode": 598
//     },
//     {
//         "id": 234,
//         "code": "UZ",
//         "name": "Uzbekistan",
//         "phonecode": 998
//     },
//     {
//         "id": 235,
//         "code": "VU",
//         "name": "Vanuatu",
//         "phonecode": 678
//     },
//     {
//         "id": 236,
//         "code": "VA",
//         "name": "Vatican City State (Holy See)",
//         "phonecode": 39
//     },
//     {
//         "id": 237,
//         "code": "VE",
//         "name": "Venezuela",
//         "phonecode": 58
//     },
//     {
//         "id": 238,
//         "code": "VN",
//         "name": "Vietnam",
//         "phonecode": 84
//     },
//     {
//         "id": 239,
//         "code": "VG",
//         "name": "Virgin Islands (British)",
//         "phonecode": 1284
//     },
//     {
//         "id": 240,
//         "code": "VI",
//         "name": "Virgin Islands (US)",
//         "phonecode": 1340
//     },
//     {
//         "id": 241,
//         "code": "WF",
//         "name": "Wallis And Futuna Islands",
//         "phonecode": 681
//     },
//     {
//         "id": 242,
//         "code": "EH",
//         "name": "Western Sahara",
//         "phonecode": 212
//     },
//     {
//         "id": 243,
//         "code": "YE",
//         "name": "Yemen",
//         "phonecode": 967
//     },
//     {
//         "id": 244,
//         "code": "YU",
//         "name": "Yugoslavia",
//         "phonecode": 38
//     },
//     {
//         "id": 245,
//         "code": "ZM",
//         "name": "Zambia",
//         "phonecode": 260
//     },
//     {
//         "id": 246,
//         "code": "ZW",
//         "name": "Zimbabwe",
//         "phonecode": 263
//     }
// ]

    // {label: 'Afghanistan', value: 'AF'}, 
    // {label: 'Åland Islands', value: 'AX'}, 
    // {label: 'Albania', value: 'AL'}, 
    // {label: 'Algeria', value: 'DZ'}, 
    // {label: 'American Samoa', value: 'AS'},
    // {label: 'Andorre', value: 'AD'}, 
    // {label: 'Angola', value: 'AO'}, 
    // {label: 'Anguilla', value: 'AI'}, 
    // {label: 'Antarctica', value: 'AQ'}, 
    // {label: 'Antigua and Barbuda', value: 'AG'}, 
    // {label: 'Argentina', value: 'AR'}, 
    // {label: 'Armenia', value: 'AM'}, 
    // {label: 'Aruba', value: 'AW'}, 
    // {label: 'Australia', value: 'AU'}, 
    // {label: 'Austria', value: 'AT'}, 
    // {label: 'Azerbaijan', value: 'AZ'}, 
    // {label: 'Bahamas', value: 'BS'}, 
    // {label: 'Bahrain', value: 'BH'}, 
    // {label: 'Bangladesh', value: 'BD'}, 
    // {label: 'Barbados', value: 'BB'}, 
    // {label: 'Belarus', value: 'BY'}, 
    // {label: 'Belgium', value: 'BE'}, 
    // {label: 'Belize', value: 'BZ'}, 
    // {label: 'Benin', value: 'BJ'}, 
    // {label: 'Bermuda', value: 'BM'}, 
    // {label: 'Bhutan', value: 'BT'}, 
    // {label: 'Bolivia', value: 'BO'}, 
    // {label: 'Bosnia and Herzegovina', value: 'BA'}, 
    // {label: 'Botswana', value: 'BW'}, 
    // {label: 'Bouvet Island', value: 'BV'}, 
    // {label: 'Brazil', value: 'BR'}, 
    // {label: 'British Indian Ocean Territory', value: 'IO'}, 
    // {label: 'Brunei Darussalam', value: 'BN'}, 
    // {label: 'Bulgaria', value: 'BG'}, 
    // {label: 'Burkina Faso', value: 'BF'}, 
    // {label: 'Burundi', value: 'BI'}, 
    // {label: 'Cambodia', value: 'KH'}, 
    // {label: 'Cameroon', value: 'CM'}, 
    // {label: 'Canada', value: 'CA'}, 
    // {label: 'Cape Verde', value: 'CV'}, 
    // {label: 'Cayman Islands', value: 'KY'}, 
    // {label: 'Central African Republic', value: 'CF'}, 
    // {label: 'Chad', value: 'TD'}, 
    // {label: 'Chile', value: 'CL'}, 
    // {label: 'China', value: 'CN'}, 
    // {label: 'Christmas Island', value: 'CX'}, 
    // {label: 'Cocos (Keeling) Islands', value: 'CC'}, 
    // {label: 'Colombia', value: 'CO'}, 
    // {label: 'Comoros', value: 'KM'}, 
    // {label: 'Congo', value: 'CG'}, 
    // {label: 'Congo, The Democratic Republic of the', value: 'CD'}, 
    // {label: 'Cook Islands', value: 'CK'}, 
    // {label: 'Costa Rica', value: 'CR'}, 
    // {label: 'Cote D\'Ivoire', value: 'CI'}, 
    // {label: 'Croatia', value: 'HR'}, 
    // {label: 'Cuba', value: 'CU'}, 
    // {label: 'Cyprus', value: 'CY'}, 
    // {label: 'Czech Republic', value: 'CZ'}, 
    // {label: 'Denmark', value: 'DK'}, 
    // {label: 'Djibouti', value: 'DJ'}, 
    // {label: 'Dominica', value: 'DM'}, 
    // {label: 'Dominican Republic', value: 'DO'}, 
    // {label: 'Ecuador', value: 'EC'}, 
    // {label: 'Egypt', value: 'EG'}, 
    // {label: 'El Salvador', value: 'SV'}, 
    // {label: 'Equatorial Guinea', value: 'GQ'}, 
    // {label: 'Eritrea', value: 'ER'}, 
    // {label: 'Estonia', value: 'EE'}, 
    // {label: 'Ethiopia', value: 'ET'}, 
    // {label: 'Falkland Islands (Malvinas)', value: 'FK'}, 
    // {label: 'Faroe Islands', value: 'FO'}, 
    // {label: 'Fiji', value: 'FJ'}, 
    // {label: 'Finland', value: 'FI'}, 
    // {label: 'France', value: 'FR'}, 
    // {label: 'French Guiana', value: 'GF'}, 
    // {label: 'French Polynesia', value: 'PF'}, 
    // {label: 'French Southern Territories', value: 'TF'}, 
    // {label: 'Gabon', value: 'GA'}, 
    // {label: 'Gambia', value: 'GM'}, 
    // {label: 'Georgia', value: 'GE'}, 
    // {label: 'Germany', value: 'DE'}, 
    // {label: 'Ghana', value: 'GH'}, 
    // {label: 'Gibraltar', value: 'GI'}, 
    // {label: 'Greece', value: 'GR'}, 
    // {label: 'Greenland', value: 'GL'}, 
    // {label: 'Grenada', value: 'GD'}, 
    // {label: 'Guadeloupe', value: 'GP'}, 
    // {label: 'Guam', value: 'GU'}, 
    // {label: 'Guatemala', value: 'GT'}, 
    // {label: 'Guernsey', value: 'GG'}, 
    // {label: 'Guinea', value: 'GN'}, 
    // {label: 'Guinea-Bissau', value: 'GW'}, 
    // {label: 'Guyana', value: 'GY'}, 
    // {label: 'Haiti', value: 'HT'}, 
    // {label: 'Heard Island and Mcdonald Islands', value: 'HM'}, 
    // {label: 'Holy See (Vatican City State)', value: 'VA'}, 
    // {label: 'Honduras', value: 'HN'}, 
    // {label: 'Hong Kong', value: 'HK'}, 
    // {label: 'Hungary', value: 'HU'}, 
    // {label: 'Iceland', value: 'IS'}, 
    // {label: 'India', value: 'IN'}, 
    // {label: 'Indonesia', value: 'ID'}, 
    // {label: 'Iran, Islamic Republic Of', value: 'IR'}, 
    // {label: 'Iraq', value: 'IQ'}, 
    // {label: 'Ireland', value: 'IE'}, 
    // {label: 'Isle of Man', value: 'IM'}, 
    // {label: 'Israel', value: 'IL'}, 
    // {label: 'Italy', value: 'IT'}, 
    // {label: 'Jamaica', value: 'JM'}, 
    // {label: 'Japan', value: 'JP'}, 
    // {label: 'Jersey', value: 'JE'}, 
    // {label: 'Jordan', value: 'JO'}, 
    // {label: 'Kazakhstan', value: 'KZ'}, 
    // {label: 'Kenya', value: 'KE'}, 
    // {label: 'Kiribati', value: 'KI'}, 
    // {label: 'Korea, Democratic People\'S Republic of', value: 'KP'}, 
    // {label: 'Korea, Republic of', value: 'KR'}, 
    // {label: 'Kuwait', value: 'KW'}, 
    // {label: 'Kyrgyzstan', value: 'KG'}, 
    // {label: 'Lao People\'S Democratic Republic', value: 'LA'}, 
    // {label: 'Latvia', value: 'LV'}, 
    // {label: 'Lebanon', value: 'LB'}, 
    // {label: 'Lesotho', value: 'LS'}, 
    // {label: 'Liberia', value: 'LR'}, 
    // {label: 'Libyan Arab Jamahiriya', value: 'LY'}, 
    // {label: 'Liechtenstein', value: 'LI'}, 
    // {label: 'Lithuania', value: 'LT'}, 
    // {label: 'Luxembourg', value: 'LU'}, 
    // {label: 'Macao', value: 'MO'}, 
    // {label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK'}, 
    // {label: 'Madagascar', value: 'MG'}, 
    // {label: 'Malawi', value: 'MW'}, 
    // {label: 'Malaysia', value: 'MY'}, 
    // {label: 'Maldives', value: 'MV'}, 
    // {label: 'Mali', value: 'ML'}, 
    // {label: 'Malta', value: 'MT'}, 
    // {label: 'Marshall Islands', value: 'MH'}, 
    // {label: 'Martinique', value: 'MQ'}, 
    // {label: 'Mauritania', value: 'MR'}, 
    // {label: 'Mauritius', value: 'MU'}, 
    // {label: 'Mayotte', value: 'YT'}, 
    // {label: 'Mexico', value: 'MX'}, 
    // {label: 'Micronesia, Federated States of', value: 'FM'}, 
    // {label: 'Moldova, Republic of', value: 'MD'}, 
    // {label: 'Monaco', value: 'MC'}, 
    // {label: 'Mongolia', value: 'MN'}, 
    // {label: 'Montserrat', value: 'MS'}, 
    // {label: 'Morocco', value: 'MA'}, 
    // {label: 'Mozambique', value: 'MZ'}, 
    // {label: 'Myanmar', value: 'MM'}, 
    // {label: 'Namibia', value: 'NA'}, 
    // {label: 'Nauru', value: 'NR'}, 
    // {label: 'Nepal', value: 'NP'}, 
    // {label: 'Netherlands', value: 'NL'}, 
    // {label: 'Netherlands Antilles', value: 'AN'}, 
    // {label: 'New Caledonia', value: 'NC'}, 
    // {label: 'New Zealand', value: 'NZ'}, 
    // {label: 'Nicaragua', value: 'NI'}, 
    // {label: 'Niger', value: 'NE'}, 
    // {label: 'Nigeria', value: 'NG'}, 
    // {label: 'Niue', value: 'NU'}, 
    // {label: 'Norfolk Island', value: 'NF'}, 
    // {label: 'Northern Mariana Islands', value: 'MP'}, 
    // {label: 'Norway', value: 'NO'}, 
    // {label: 'Oman', value: 'OM'}, 
    // {label: 'Pakistan', value: 'PK'}, 
    // {label: 'Palau', value: 'PW'}, 
    // {label: 'Palestinian Territory, Occupied', value: 'PS'}, 
    // {label: 'Panama', value: 'PA'}, 
    // {label: 'Papua New Guinea', value: 'PG'}, 
    // {label: 'Paraguay', value: 'PY'}, 
    // {label: 'Peru', value: 'PE'}, 
    // {label: 'Philippines', value: 'PH'}, 
    // {label: 'Pitcairn', value: 'PN'}, 
    // {label: 'Poland', value: 'PL'}, 
    // {label: 'Portugal', value: 'PT'}, 
    // {label: 'Puerto Rico', value: 'PR'}, 
    // {label: 'Qatar', value: 'QA'}, 
    // {label: 'Reunion', value: 'RE'}, 
    // {label: 'Romania', value: 'RO'}, 
    // {label: 'Russian Federation', value: 'RU'}, 
    // {label: 'RWANDA', value: 'RW'}, 
    // {label: 'Saint Helena', value: 'SH'}, 
    // {label: 'Saint Kitts and Nevis', value: 'KN'}, 
    // {label: 'Saint Lucia', value: 'LC'}, 
    // {label: 'Saint Pierre and Miquelon', value: 'PM'}, 
    // {label: 'Saint Vincent and the Grenadines', value: 'VC'}, 
    // {label: 'Samoa', value: 'WS'}, 
    // {label: 'San Marino', value: 'SM'}, 
    // {label: 'Sao Tome and Principe', value: 'ST'}, 
    // {label: 'Saudi Arabia', value: 'SA'}, 
    // {label: 'Senegal', value: 'SN'}, 
    // {label: 'Serbia and Montenegro', value: 'CS'}, 
    // {label: 'Seychelles', value: 'SC'}, 
    // {label: 'Sierra Leone', value: 'SL'}, 
    // {label: 'Singapore', value: 'SG'}, 
    // {label: 'Slovakia', value: 'SK'}, 
    // {label: 'Slovenia', value: 'SI'}, 
    // {label: 'Solomon Islands', value: 'SB'}, 
    // {label: 'Somalia', value: 'SO'}, 
    // {label: 'South Africa', value: 'ZA'}, 
    // {label: 'South Georgia and the South Sandwich Islands', value: 'GS'}, 
    // {label: 'Spain', value: 'ES'}, 
    // {label: 'Sri Lanka', value: 'LK'}, 
    // {label: 'Sudan', value: 'SD'}, 
    // {label: 'Surilabel', value: 'SR'}, 
    // {label: 'Svalbard and Jan Mayen', value: 'SJ'}, 
    // {label: 'Swaziland', value: 'SZ'}, 
    // {label: 'Sweden', value: 'SE'}, 
    // {label: 'Switzerland', value: 'CH'}, 
    // {label: 'Syrian Arab Republic', value: 'SY'}, 
    // {label: 'Taiwan, Province of China', value: 'TW'}, 
    // {label: 'Tajikistan', value: 'TJ'}, 
    // {label: 'Tanzania, United Republic of', value: 'TZ'}, 
    // {label: 'Thailand', value: 'TH'}, 
    // {label: 'Timor-Leste', value: 'TL'}, 
    // {label: 'Togo', value: 'TG'}, 
    // {label: 'Tokelau', value: 'TK'}, 
    // {label: 'Tonga', value: 'TO'}, 
    // {label: 'Trinidad and Tobago', value: 'TT'}, 
    // {label: 'Tunisia', value: 'TN'}, 
    // {label: 'Turkey', value: 'TR'}, 
    // {label: 'Turkmenistan', value: 'TM'}, 
    // {label: 'Turks and Caicos Islands', value: 'TC'}, 
    // {label: 'Tuvalu', value: 'TV'}, 
    // {label: 'Uganda', value: 'UG'}, 
    // {label: 'Ukraine', value: 'UA'}, 
    // {label: 'Émirats Arabes Unis', value: 'AE'}, 
    // {label: 'United Kingdom', value: 'GB'}, 
    // {label: 'United States', value: 'US'}, 
    // {label: 'United States Minor Outlying Islands', value: 'UM'}, 
    // {label: 'Uruguay', value: 'UY'}, 
    // {label: 'Uzbekistan', value: 'UZ'}, 
    // {label: 'Vanuatu', value: 'VU'}, 
    // {label: 'Venezuela', value: 'VE'}, 
    // {label: 'Viet Nam', value: 'VN'}, 
    // {label: 'Virgin Islands, British', value: 'VG'}, 
    // {label: 'Virgin Islands, U.S.', value: 'VI'}, 
    // {label: 'Wallis and Futuna', value: 'WF'}, 
    // {label: 'Western Sahara', value: 'EH'}, 
    // {label: 'Yemen', value: 'YE'}, 
    // {label: 'Zambia', value: 'ZM'}, 
    // {label: 'Zimbabwe', value: 'ZW'} 

  export default COUNTRIES;