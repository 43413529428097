import React from "react";
import { useEffect, useState } from "react";
import { apiURL, getEntity } from "../services/api";
import ReactHtmlParser from "react-html-parser";
import Loader from "../components/Loader";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
export default function Bsimmobilier() {
  const [data, empdatachange] = useState([]);
  const [dataphoto, empdataphoto] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getEntity("/pagebsimmobilier")
      .then((res) => {
        empdatachange(res.data.data);

      })
      .catch((err) => {
        console.log(err.message);

      });
    //header
    getEntity("/ebsimmobilier")
      .then((res) => {
        empdataphoto(res.data.data);
        setIsLoading(false);


      })
      .catch((err) => {
        console.log(err.message);
        setIsLoading(false);

      });
  }, []);

  console.log('isLoading:', isLoading);
  return isLoading ?
    <Loader /> : (
      <>
        <di>
          <Header />
        </di>
        {
          dataphoto.map((item, index) => (
            <div
              className="page-title-area page-title-bsimmobiler"
              style={{
                backgroundImage: `radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(0, 0, 0, 0) 0%),url(${apiURL}${item.photo})`,
                backgroundColor: "#ffffff",
              }}
              key={index}>
              <div className="d-table">
                <div className="d-table-cell">
                  <div className="container">
                    <div className="page-title-content">
                      <h2>{item.titre}</h2>
                      <p className="mt-2">{ReactHtmlParser(item.description)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

        <section className="insurance-details-area ptb-60">
          <div className="container">
            <div className="insurance-details-header">
              {
                data.map((item, index) => (
                  item.photo ?
                    <div
                      className="row align-items-center"
                      data-aos="fade-right"
                      data-aos-once="false" key={index}
                    >

                      <div className="section-title-left mt-5">
                        <h2>{item.titre}</h2>
                      </div>
                      <div className="col-lg-8 col-md-12 col-12">
                        <div className="content">
                          <p className="justify-text">
                            {ReactHtmlParser(item.description)}
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-12">
                        <div className="image text-center">
                         
                            <img
                              className="fa-beat-fade"
                              style={{ "--fa-animation-duration": "3s"}}
                              src={`${apiURL}${item.photo}`}
                              alt="bsi-immobilier"
                            />
                         
                        </div>
                      </div>





                    </div>
                    :
                    <div key={index}>
                      <div className="section-title-left mt-5">
                        <h2>{item.titre}</h2>
                      </div>

                      <div className="content">
                        <p className="justify-text">
                          {ReactHtmlParser(item.description)}
                        </p>
                      </div>
                    </div>
                ))}


            </div>
          </div>
        </section>
        <di>
          <Footer />
        </di>
      </>
    );
}