import React from 'react'
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import { apiURL, getEntityById } from "../services/api";
import Loader from '../components/Loader';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

export default function Mentionlegales() {

    const [data, empdatachange] = useState([]);
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(true)
        getEntityById("/liens-utils/" + id).then((res) => {
            //return res.data.data;
            empdatachange(res.data.data);
            setIsLoading(false);
        }).catch((err) => {
            console.log(err.message);
            setIsLoading(false);
        });


    }, []);

    return isLoading ?
        <Loader /> : (
            <>
                <div>
                    <Header />
                </div>
                <div className="page-title-area page-title-bg-mentions" style={{ backgroundImage: `radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(0, 0, 0, 0) 0%),url(${apiURL}${data.photo})`, backgroundColor: '#ffffff' }}>
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="page-title-content">
                                    <h2>{data.titre}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="blog-area ptb-60 pb-70">
                    <div className="container" data-aos="fade-up"
                        data-aos-duration="1500"
                        data-aos-once="false">
                        <div className="col-lg-12 col-md-12 col-sm-12 justify-text" style={{ marginTop: "-30px" }}>
                            {ReactHtmlParser(data.description)}

                        </div>
                    </div>
                </section>
                <div>
                    <Footer />
                </div>
            </>
        )
}
