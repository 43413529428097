import React, { useEffect, useState } from 'react'
import FormBottomText from '../components/common/FormBottomText'
import { postEntity, getEntity, apiURL } from '../services/api';
import { Alert } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import Loader from '../components/Loader';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

export default function Partner() {

    const [data, setData] = useState({
        nom: '',
        email: '',
        phone: '',
        description: ''
    });
    const [alert, setAlert] = useState(null);
    const [dataphoto, empdataphoto] = useState([]);
    const [datacontenu, empdatacontenu] = useState([]);
    const [button, setButton] = useState(<i className="fa fa-paper-plane"></i>);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        //header
        getEntity("/devenirpartener").then((res) => {
            empdataphoto(res.data.data);
            setIsLoading(false);
        }).catch((err) => {
            console.log(err.message);
            setIsLoading(false);
        });
        //contenu
        getEntity("/page-devenir-partenaires").then((res) => {
            empdatacontenu(res.data.data);
        }).catch((err) => {
            console.log(err.message);
        });


    }, []);


    const onSubmit = (event) => {
        event.preventDefault();

        setButton(<i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>);

        postEntity("/devenir-partenaire", data).then(() => {

            setAlert({ success: true, message: 'Votre soumission a été prise en compte' })
            setButton(<i className="fa fa-paper-plane"></i>)

        }, () => {
            setAlert({ success: false, message: 'Erreur l\'ors de l\'envoie' });
        }).finally(() => {
            setTimeout(() => {
                setAlert(null);
                document.getElementById('nom').value = '';
                document.getElementById('email').value = '';
                document.getElementById('phone').value = '';
                document.getElementById('description').value = '';
            }, 2000);
        });

    }

    return isLoading ?
        <Loader /> : (
            <>
                <div style={{ overflowX: 'hidden' }}>
                    <div>
                        <Header />
                    </div>
                    {dataphoto && dataphoto.map((item, index) => (
                        <div className="page-title-area page-title-bg-partner" style={{ backgroundImage: `radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(0, 0, 0, 0) 0%),url(${apiURL}${item.photo})`, backgroundColor: '#ffffff' }} key={index}>
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="container">
                                        <div className="page-title-content">
                                            <h2>{item.titre}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className='container'  >
                        {datacontenu && datacontenu.map((item, index) => (
                            item.photo == null ?
                                <section className="insurance-details-area mt-60 ptb-60" key={index}>
                                    <div className="container" data-aos="fade-up" data-aos-once="true">
                                        <div className="insurance-details-header">
                                            <div className="row align-items-center">
                                                <div className="section-title-left">
                                                    <h2>{item.titre}</h2>
                                                </div>
                                                <div className="">
                                                    <div className="content">
                                                        <p className='justify-text' >
                                                            {ReactHtmlParser(item.description)}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                :
                                <section className="insurance-details-area ptb-60 bg-black-color" key={index}>
                                    <div className="container" data-aos="fade-up" data-aos-once="false">
                                        <div className="insurance-details-header">
                                            <div className="row align-items-center">
                                                <div className="section-title-left">
                                                    <h2>{item.titre}</h2>
                                                </div>
                                                <div className="col-lg-4 col-md-12 col-12" data-aos="fade-left" data-aos-once="false">
                                                    <div className="image text-center">
                                                        <img src={`${apiURL}${item.photo}`} height={400} alt="image" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-8 " data-aos="fade-right" data-aos-once="false">
                                                    <div className="content">
                                                        <p className='justify-text'  >
                                                            {ReactHtmlParser(item.description)}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                        ))}
                    </div>

                    <section className="blog-area ptb-60 pb-70">
                        <div className="container" data-aos="fade-up"
                            data-aos-duration="1500"
                            data-aos-once="false">
                            <div className="section-title" data-aos="slide-up">
                                <h2>Envie de nous rejoindre ?</h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 text-center" style={{ marginTop: "-30px" }}>
                                {
                                    alert && <Alert style={{ color: '#fff', backgroundColor: '#4AA340' }} variant={alert.success ? 'success' : 'danger'} >
                                        {alert.message}
                                    </Alert>
                                }
                                <div className="offset-md-3 offset-lg-3 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="contact-form">
                                        <form onSubmit={onSubmit} method="post">
                                            <div className="row">
                                               {/*  <div className="col-lg-12 col-md-6" data-aos="fade-left" data-aos-delay="250">
                                                    <div className="form-group">
                                                        <input type="text" onInput={(e) => setData({ ...data, sujet: e.target.value })} name="sujet" id="sujet" className="form-control" required data-error="Entrer un sujet" placeholder="Sujet" />
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div> */}

                                                <div className="col-lg-12 col-md-6" data-aos="fade-left" data-aos-delay="250">
                                                    <div className="form-group">
                                                        <input type="text" onInput={(e) => setData({ ...data, nom: e.target.value })} name="nom" id="nom" className="form-control" required data-error="Entrer votre nom" placeholder="Nom" />
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 col-md-6" data-aos="fade-left" data-aos-delay="260">
                                                    <div className="form-group">
                                                        <input type="email" onInput={(e) => setData({ ...data, email: e.target.value })} name="email" id="email" className="form-control" required data-error="Entrer votre email" placeholder="Email" />
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 col-md-12" data-aos="fade-left" data-aos-delay="270">
                                                    <div className="form-group">
                                                        <input type="text" onInput={(e) => setData({ ...data, phone: e.target.value })} name="phone" id="phone" required data-error="Entrer votre téléphone" className="form-control" placeholder="Tel" />
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 col-md-12" data-aos="fade-left" data-aos-delay="280">
                                                    <div className="form-group">
                                                        <textarea name="description" onInput={(e) => setData({ ...data, description: e.target.value })} className="form-control" id="description" cols="30" rows="6" required data-error="Saisissez un message" placeholder="Votre Message"></textarea>
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 col-md-12 text-center" data-aos="fade-left" >
                                                    <button type="submit" className="default-btn">Soumettre ma demande {button}<span></span></button>
                                                    <div id="msgSubmit" className="h3 text-center hidden"></div>
                                                    <div className="clearfix"></div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <FormBottomText></FormBottomText>

                                </div>
                            </div>
                        </div>
                    </section>
                    <div>
                        <Footer />
                    </div>
                </div>
            </>
        )
}
