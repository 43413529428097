import React from 'react';
import { useEffect, useState } from "react";
import '../styles/loader.css';

export default function Loader() {
  return (
    <>
      <div className='bodyloader'>
        <div class="ringloader">Chargement...
          <span className='spanloader'></span>
        </div>
      </div>

    </>
  )
}
