import React from 'react'
import Header from '../layouts/Header'
import Footer from '../layouts/Footer'

export default function PasExpatries() {
    return (
        <>
            <div>
                <Header />
            </div>
            <div className="page-title-area page-title-area-non-expatries page-title-bg-pas-expatries">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>Assurances pour non expatriés</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="about-area ptb-60 bg-f8f8f8">
                <div className="container">

                    <div className='row'>
                        <div className='col-lg-8 col-md-8 col-12'>
                            <div>
                                <p className='justify-text' data-aos="fade-up">
                                    <b>Votre régime d’assurance maladie local ne vous convient pas ?</b> Ou peut-être n’avez-vous pas de
                                    couverture maladie ou évacuation sanitaire ? N’avez-vous jamais songé à souscrire à une assurance
                                    santé privée ? Une <b>assurance santé privée au 1er euro</b> (ou 1er USD) est parfaitement adaptée pour
                                    bénéficier d’une prise en charge à <b>100% de ses dépenses</b> en cas d’hospitalisation ou de soins, en Europe,
                                    dans son pays de résidence ou de nationalité… ou ailleurs (<b>libre choix des prestataires médicaux</b> pour
                                    la plupart des contrats).
                                </p>


                                <p className='justify-text' data-aos="fade-up">
                                    Nous collaborons avec les <b>meilleures compagnies internationales</b> spécialisées dans l’assurance “au 1er euro”
                                    partout dans le monde.
                                </p>

                                <p className='justify-text' data-aos="fade-up">
                                    Le tarif sera fonction de votre âge, de votre profil de risque (déterminé dans le <b>questionnaire médical</b>),
                                    de la composition de votre famille, de votre projet de vie (avec ou sans maternité ?…), de la zone de couverture.
                                    Par exemple si vous souhaitez pouvoir être soigné aux USA,
                                    sachez que la prime à régler sera quasiment le double que si vous excluez cette zone.
                                </p>

                                <p className='justify-text' data-aos="fade-up">
                                    La plupart des compagnies proposent de construire votre contrat d’assurance santé au 1er euro avec :
                                    <ul>
                                        <li>une garantie de base obligatoire qui prendra en charge les frais en cas d’hospitalisation ;</li>
                                        <li> des garanties optionnelles :
                                            <ul>
                                                <li>médecine courante (hors hospitalisation) : consultations chez un généraliste ou un spécialiste, pharmacie, laboratoire, etc. ;</li>
                                                <li>soins dentaires ;</li>
                                                <li>optique ;</li>
                                                <li>maternité ;</li>
                                                <li>rapatriement sanitaire (comment une évacuation ou un rapatriement sanitaire sont-ils décidés ?) ;</li>
                                                <li>d’autres options telles que des garanties prévoyance (décès – invalidité)…</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </p>

                                <p className='justify-text' data-aos="fade-up">
                                    En fonction de l’option retenue, vous aurez accès ou non à des prestations particulières : bilan de santé annuel, médecine douce…
                                </p>

                                <p className='justify-text' data-aos="fade-up">
                                    Assurez-vous tant que vous êtes en bonne santé et répondez sincèrement au questionnaire médical : nous vous aiderons à le compléter.
                                </p>

                                <p className='justify-text' data-aos="fade-up">
                                    Un entretien téléphonique vous permettra souvent d’y voir plus clair donc n’hésitez pas à nous contacter.
                                </p>

                                <p className='justify-text'>Demandez-nous votre projet, sans engagement ! La souscription est ensuite
                                    très simple, avec nos conseils en prime !</p>

                                <a href='/demandededevis/particuliers' data-aos="fade-right" className="default-btn"><i className="fa-solid fa-chart-column fa-1x fa-flip">&nbsp;&nbsp;</i> Demandez votre devis !<span></span></a>


                                <div className='row another-link' data-aos="fade-right" >
                                    <h3 className='mt-5'>Vous pourriez être intéressé par les produits suivants :</h3>

                                    <div className='col-md-4 mt-2'>
                                        <a href="/expatries" className=""><b>Assurances expatriés</b> <span></span></a>
                                    </div>

                                    <div className='col-md-4 mt-2'>
                                        <a href="/globe-trotters" className=""><b>Globe-trotters et étudiants</b> <span></span></a>
                                    </div>

                                    <div className='col-md-4 mt-2'>
                                        <a href="/Schengen" className=""><b>Assurances Schengen</b> <span></span></a>
                                    </div>

                                </div>

                            </div>



                        </div>
                        <div className='col-lg-4 col-md-8 col-12'>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-12">
                                    <div className="single-about-box page-particulier">

                                        <img src="assets/img/logo-cigna.png" width={100} alt="" />

                                        <h3>Une offre faite pour vous</h3>
                                        <p><i className="fa-solid fa-star fa-2x" style={{ color: "#C14742" }}></i></p>
                                        <p> Vous êtes pressé ? Votre tarif Allianz Care est ici !</p>
                                        <span><a href="https://demo.bilansanteinternational.com/demandededevis/particuliers" target="_blank"><b>En savoir plus</b></a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <Footer />
            </div>
        </>
    )
}
