import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    nom : '',
    prenom : '',
    fonction: '',
    company: '',
    typecourverture: [],
    courvertureSouhaite : '',
    courvertureExistante : '',
    mainAssured: [],
    whatsapp : '',
    email : '',
    captcha: '',
    autreInformations: '',
    pays: ''
}

export const enterpriseFormSlice = createSlice({
    name:'enterpriseData',
    initialState,
    reducers : {

        //remove all state data
        removeAllData : (state, action) => {
            state = action.payload  
        },

        //step 1
        setWhatsapp : (state, action) => {
            state.whatsapp = action.payload  
        },
        setEmail : (state, action) => {
            state.email = action.payload
        },

        //step 2
        setNom : (state, action) => {
            state.nom = action.payload  
        },
        setPrenom : (state, action) => {
            state.prenom = action.payload
        },
        setFonction: (state, action) => {
            state.fonction = action.payload  
        },
        setPaysResidence : (state, action) => {
            state.pays = action.payload
        },
        setCompany : (state, action) => {
            state.company = action.payload
        },

        //step 3
        setTypeCourverture : (state, action) => {
            state.typecourverture = action.payload  
        },
        setCouvertureSouhaite : (state, action) => {
            state.courvertureSouhaite = action.payload
        },
        setCouvertureExistante : (state, action) => {
            state.courvertureExistante = action.payload
        },

        //step 4
         setMainAssured: (state, action) => {
            state.mainAssured = action.payload  
        },
            // set Date de naissance, sexe, nationalité assuré pricipale
            setNaissanceSexeNationnalite: (state, action) => { 

                const newNaissance = Object.assign({ ...state.mainAssured[action.payload[1]] }, { [action.payload[2]] : action.payload[0] });

                state.mainAssured[action.payload[1]] = newNaissance;

            },

            // set Date de naissance, nationalité conjoint(e)
            setSexeNationnaliteConjoint: (state, action) => {
                
                const newNationaliteSexe = { ...state.mainAssured[action.payload[1]] , conjoint : { ...state.mainAssured[action.payload[1]].conjoint, [action.payload[2]] : action.payload[0] }  };

                state.mainAssured[action.payload[1]] = newNationaliteSexe;

            },

            //set Enfant date de naissance
            setEnfant: (state, action) => {

                if(state.mainAssured[action.payload[1]].enfants[action.payload[2]]) {
                    state.mainAssured[action.payload[1]].enfants[action.payload[2]] = { dateNaissanceEnfant: action.payload[0] };
                  }
                  else {
                    state.mainAssured[action.payload[1]].enfants.push({dateNaissanceEnfant: action.payload[0]})
                  }

            },

            //remove Conjoint Data
            removeConjointData: (state, action) => {

                state.mainAssured[action.payload].conjoint = {}

            },


            //remove Child Data
            removeChildData: (state, action) => {

                // state.mainAssured[action.payload[0]].enfants = state.mainAssured[action.payload[0]].enfants.filter((v, i) => i !== [action.payload[1]])

            },

    }
})

export const {

    setWhatsapp, 
    setEmail, 
    setNom, 
    setPrenom, 
    setFonction, 
    setPaysResidence, 
    setCompany,
    setTypeCourverture,
    setCouvertureSouhaite,
    setCouvertureExistante,
    setMainAssured,
    setNaissanceSexeNationnalite,
    setSexeNationnaliteConjoint,
    setEnfant,
    removeChildData,
    removeConjointData,
    removeAllData

} = enterpriseFormSlice.actions

export default enterpriseFormSlice.reducer