import React, { useState, useEffect } from 'react'
import logo_bsi from '../styles/img/logo-bsi.png'
import french_tech from '../styles/img/french-tech.webp'
import { Alert } from 'react-bootstrap';
import { postEntity, getEntity } from '../services/api';
import { render } from 'react-dom';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';


export default function Footer() {


    const [datas, empdatachange] = useState([]);
    const [dataContact, empdatacontact] = useState([]);

    useEffect(() => {
        getEntity("/liens-utils").then((res) => {

            empdatachange(res.data.data);
        }).catch((err) => {
            console.log(err.message);
        });

        getEntity("/contact").then((res) => {

            empdatacontact(res.data.data);

        }).catch((err) => {
            console.log(err.message);
        });

    }, []);
    var today = new Date();
    var year = today.getFullYear()
    const [nom, setNom] = useState('');
    const [data, setData] = useState([]);
    const [alert, setAlert] = useState(null);
    const [button, setButton] = useState(<i className="fa fa-paper-plane"></i>);

    const OnSubmit = (event) => {
        event.preventDefault();
        console.log(data);
        const buttonContent = <i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i>;

        try {

            if (data.nom && data.email) {

                if (/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]{2,}[.][a-z]{2,3}$/.test(data.email)) {
                    setButton(buttonContent)
                    postEntity("/newsletter", data).then(() => {
                        setButton(<i className="fa fa-paper-plane"></i>); // Arrêter le loader en rétablissant le contenu du bouton

                        setAlert({ success: true, message: 'Abonnement réussi ! 😊' })

                    }, () => {
                        setAlert({ success: false, message: 'Erreur l\'ors de l\'envoie' });
                    }).finally(() => {
                        setTimeout(() => {
                            setAlert(null);
                            document.getElementById('nom').value = '';
                            document.getElementById('email').value = '';
                            setButton(<i className="fa fa-paper-plane"></i>); // Arrêter le loader en rétablissant le contenu du bouton
                            setNom('');
                        }, 2000);

                    });
                } else {
                    setAlert({ success: false, message: 'Veuillez entrer une adresse email valide' });
                    setButton(<i className="fa fa-paper-plane"></i>); // Arrêter le loader en rétablissant le contenu du bouton

                }


            } else {
                setAlert({ success: false, message: 'Veuillez renseigner tous les champs du formulaire' })
            }


        } catch (e) {
            console.log('Error')
        }

    }
    return (
        <>
            <footer className="footer-area">
                <div className="container">
                    <div className="subscribe-area">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="subscribe-content">
                                    <h5> <span>Vous avez une question ?</span> Nous vous répondrons avec plaisir !</h5>
                                </div>
                            </div>
                            <div className="offset-md-2 col-lg-4 col-md-4 col-sm-12 subscribe-content">
                                <h2><a style={{ fontWeight: 'bold', color: '#ffffff', textDecoration: 'none' }} href="tel:+33144549132"><i className="fa fa-phone"></i> +33 1 44 54 91 32</a></h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="single-footer-widget">
                                <h3>NOTRE ENTREPRISE</h3>

                                <ul className="footer-quick-links">
                                    <li><Link to="/apropos">Qui sommes-nous ?</Link></li>
                                    <li><Link to="/devenir_partenaire">Devenir partenaire</Link></li>
                                    <li><Link to="/blog">Blog</Link></li>
                                    <li><Link to="/faq">FAQ</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="single-footer-widget">
                                <h3>LIENS UTILES</h3>

                                <ul className="footer-quick-links">
                                    {datas.map((item, index) => (
                                        <li key={index}><a href={`/lien-utile/${item.slug}`}>{item.titre}</a></li>

                                    ))}
                                    {/* <li><a href="/Mentionlegales">Mentions légales</a></li>
                                    <li><a href="/politiquevieprivee">Politique vie privée</a></li>
                                    <li><a href="/politiquecookies">Politique cookies</a></li>    
                                    <li><a href="/plandusite">Plan du site</a></li> */}
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="single-footer-widget">
                                <h3>Contacts</h3>
                                {dataContact.map((item, index) => (
                                    <ul className="footer-contact-info" key={index}>

                                        <li><span><i className="fa fa-map-marker" aria-hidden="true"></i></span><a style={{ marginLeft: "15px", marginTop: "-23px" }} href={item.google_map}>{item.localisation}</a></li>
                                        <li><span><i className="fa fa-envelope" aria-hidden="true"></i></span> <a href={`mailto:${item.email}`}>{item.email}</a></li>
                                        <li><span><i className="fa fa-phone" aria-hidden="true"></i></span> <a href={`tel:${item.phone}`}>{item.phone}</a></li>

                                        {/*  <li><span><i className="fa fa-map-marker" aria-hidden="true"></i></span> 27 boulevard Saint-Martin, 75 003 Paris - France</li>
                                        <li><span><i className="fa fa-envelope" aria-hidden="true"></i></span> <a href="mailto:info@bilansanteinternational.com">info@bilansanteinternational.com</a></li>
                                        <li><span><i className="fa fa-phone" aria-hidden="true"></i></span> <a href="tel:+33144549132">+33 1 44 54 91 32</a></li> */}
                                    </ul>
                                ))}
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
                            <div className="single-footer-widget">
                                <h3>Newsletter</h3>

                                <ul className="footer-contact-info subscribe-form">
                                    <li>Keep in touch ! Demandez notre newsletter.</li>

                                    {
                                        // style={{color:'#fff', backgroundColor:'#4AA340'}} 
                                        alert && <Alert variant={alert.success ? 'success' : 'danger'} >
                                            {alert.message}</Alert>
                                    }

                                    <li>
                                        <form className="" method="post" onSubmit={OnSubmit}>

                                            <input onInput={(e) => setData({ ...data, nom: e.target.value })} type="text" className="input-newsletter" placeholder="Entrer votre nom" name="nom" id='nom' />

                                            <input onInput={(e) => setData({ ...data, email: e.target.value })} type="email" className="input-newsletter mt-2" placeholder="Entrer votre adresse email" name="email" id='email' />

                                            <button type="submit" className="btn btn-lg btn-block">
                                                JE M'ABONNE {button}<span></span>
                                            </button>
                                        </form>
                                    </li>

                                    {/* <li><button type="button" className="btn btn-lg btn-block">Je m'abonne <i className="flaticon-right-chevron"></i></button></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="copyright-area">
                        <div className="row align-items-center alignementMobile">
                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <p><i className="far fa-copyright"></i> {year} BSI Conseil - Insurance & Insurtech<br />Tous droits réservés <a href="#" target="_blank"></a></p>
                            </div>

                            <div className="col-lg-2 col-md-2 col-sm-6 logo" style={{ display: 'flex !important', justifyContent: 'center !important' }}>
                                <a href="/"><img src={logo_bsi} width="160" alt="image" /></a>
                            </div>

                            <div className="col-lg-2 col-md-2 col-sm-6 logo" style={{ display: 'flex !important', justifyContent: 'center !important' }}>
                                <a href="#"><img src={french_tech} width="160" alt="image" /></a>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="single-footer-widget">
                                    <ul className="social">
                                        <li><a href="https://www.facebook.com/bilansanteinternational/"><i className="fab fa-facebook-f fa-beat-fade"></i></a></li>
                                        <li><a href="https://www.linkedin.com/in/fran%C3%A7oismasson/"><i className="fab fa-linkedin-in fa-beat-fade"></i></a></li>
                                        <li><a href="https://twitter.com/bilansante"><i className="fab fa-twitter fa-beat-fade" ></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}

