import React from "react";
import logo_bsi from "../styles/img/logo-bsi.png";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/fr";
import { getEntity } from "../services/api";
import "../styles/css/style.css"
export default function Header() {
  const [data, empdatachange] = useState([]);
  const [toggle, setToggle] = useState(true)
  const [toggleItem, setToggleItem] = useState(true)
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    setToggle(false)

    moment.locale("fr");
    getEntity("/type-produit")
      .then((res) => {
        empdatachange(res.data.data);
        console.log(process.env.REACT_APP_CLE_API);
      })
      .catch((err) => {
        console.log(err.message);
      });

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 150; // Le niveau de défilement où vous souhaitez changer le style du header
     
      setIsScrolled(scrollPosition > scrollThreshold);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, []);

  const handleToggleMenu = (index) => {
    setToggleItem(prevIndex => (prevIndex === index ? null : index));
  }

  const trakerEventProduit = (event) => {
    ReactGA.event({
      category: "Produit",
      action: `Produit : ${event.target.innerText}`,
    });
  };


  return (
    <>

      <div className={`${isScrolled ? 'nav-background1' : 'nav-background'}`}>
        <nav className="container laptop-nav">
          <div className="row d-flex justify-content-center paddingHeader">
            <div className="col-md-10 nav_content_height_mobile">
              <div className="header" style={{ display: "flex", justifyContent: 'space-between', padding: '10px' }}>


                <Link className="col-2" to="/">
                  <img src={logo_bsi} className="logo" alt="image" height={'70px'} width={'60px'} style={{ objectFit: "contain" }} />
                </Link>

                <span onClick={() => setToggle(!toggle)} className="toggleButton">
                  <i className="fa-solid fa-bars"></i>
                </span>
                <div className="toggle">
                  {toggle && (
                    <ul class="list-group">
                      {data.map((item, index) => (
                        <li className="list-group-item deroulant" key={index}>
                          <a href="#" className="active" onClick={() => handleToggleMenu(index)}>
                            {item.titre} <i className="fas fa-angle-down"></i>{" "}
                          </a>

                          {toggleItem === index && (<ul className="list-group">
                            {item.produits.map((item, index) => (
                              <li className="list-group-item" key={index}>
                                <a
                                  href={`/produit/${item.slug}`}
                                  onClick={trakerEventProduit}
                                  className="nav-link" style={{ lineHeight: '15px' }}
                                >
                                  {item.titre}
                                </a>
                              </li>
                            ))}

                          </ul>)}
                        </li>
                      ))}
                      <li className="list-group-item">
                        <Link to="/e-bsi">e-bsi</Link>
                      </li>
                      <li className="list-group-item">
                        <Link to="/bsimmobilier">bsimmobilier</Link>
                      </li>
                      <li className="list-group-item">
                        <Link to="/apropos">À propos</Link>
                      </li>
                    </ul>
                  )}
                </div>



                <ul className="liste " id="displayNavItem">
                  {/* <li>
                  <a href="/">
                    <img src={logo_bsi} className="logo" />
                  </a>
                </li> */}

                  {data.map((item, index) => (
                    <li className="items" key={index}>
                      <a href="#" className="active" >
                        {item.titre} <i className="fas fa-angle-down"></i>{" "}
                      </a>

                      <ul className="drop">
                        {item.produits.map((item, index) => (
                          <li className="nav-item" key={index}>
                            <a
                              href={`/produit/${item.slug}`}
                              onClick={trakerEventProduit}
                              className="nav-link" style={{ lineHeight: '15px' }}
                            >
                              {item.titre}
                            </a>
                          </li>
                        ))}

                      </ul>
                    </li>
                  ))}

                  <li className="items">
                    <Link to="/e-bsi">e-bsi</Link>
                  </li>
                  <li className="items">
                    <Link to="/bsimmobilier">bsimmobilier</Link>
                  </li>
                  <li className="items">
                    <Link to="/apropos">À propos</Link>
                  </li>
                  <li className="items whatsappus">
                    <a
                      href="https://api.whatsapp.com/send?phone=33744890502"
                      target="_blank"
                    >
                      <i className="fab fa-whatsapp fa-2x fa-beat-fade"></i>
                      <span>
                        <span>WhatsApp us!</span>
                        <br /> <span className="nav-box">+33 7 44 89 05 02</span>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>

    </>
  );
}
