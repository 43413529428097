import React from 'react'
//import coronavirus from '../../styles/img/coronavirus.jpg'
//import brokerslink from '../../styles/img/brokerslink.jpg'
//import sportsante from '../../styles/img/sportsanté.jpg'
import { useEffect, useState } from "react";
import moment from 'moment';
import 'moment/locale/fr';
import { apiURL, getEntity } from "../../../src/services/api";
import ReactHtmlParser from 'react-html-parser';
import dummyImage from '../../../src/img/dummy.jpg';
export default function BlogSection() {

    const [blogData, blogCharge] = useState([]);
    useEffect(() => {
        moment.locale('fr');
        getEntity("/poste").then((res) => {
            blogCharge(res.data.data);
        }).catch((err) => {
            console.log(err.message);
        })
    }, []);


    // Fonction pour limiter le nombre de caractères de la description
    const limitDescription = (description, limit) => {
        if (description.length <= limit) {
            return description;
        } else {
            return description.slice(0, limit - 3) + '...';
        }
    };

    return (
        <section className="blog-area ptb-60">
            <div className="container"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-once="false">
                <div className="section-title" data-aos="slide-up" data-aos-offset="200"
                    data-aos-delay="10" data-aos-duration="2000">
                    <h2>Articles du blog</h2>
                    <p>Retrouvez toutes nos actualités ici !</p>
                </div>
                <section className="blog-area ptb-60">
                    <div className="container">
                        <div className="row">


                            {blogData.map((item, index) => (
                                <div key={index} className="col-lg-4 col-md-6">
                                    <div className="single-blog-post">
                                        <div className="post-image">
                                            <a href="#"> {item.photo?<img src={`${apiURL}${item.photo}`} height={'247px'} alt="image" />: <img src={dummyImage} height={'247px'}  alt="image"/>}</a>

                                            <div className="date"><i className="flaticon-timetable"></i> {' '}
                                                {moment(item.date_post).format('MMM DD, YYYY')}</div>
                                        </div>

                                        <div className="post-content">
                                            <h3><a href="#">{item.titre}</a></h3>
                                            {/* <p>{ReactHtmlParser(limitDescription(item.description,450))}</p> */}

                                            <a href={`/blogdetails/${item.slug}`} className="default-btn" target='_blank'>Lire plus <span></span></a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <div className="col-lg-12 col-md-12 text-center">
                    <a href="/blog" className="default-btn">Voir plus d'articles <i className="flaticon-right-chevron"></i><span></span></a>
                </div>
            </div>

        </section>
    )
}
