import React from 'react'
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  withRouter, Switch
} from "react-router-dom";

import Footer from "./layouts/Footer";
import Header from "./layouts/Header";
import Welcome from "./pages/Welcome";
import Ebsi from "./pages/Ebsi";
import BSImmobilier from "./pages/Bsimmobilier";
import Apropos from "./pages/Apropos";
import Partner from "./pages/Partner";
import Plandusite from "./pages/Plandusite";
import Politiquevieprivee from "./pages/Politiquevieprivee";
import Mentionlegales from "./pages/Mentionlegales";
import Politiquecookies from "./pages/Politiquecookies";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import DemandededevisPage from "./pages/DemandededevisPage";
import Expatries from "./pages/Expatries";
import PasExpatries from "./pages/PasExpatries";
import Faq from "./pages/Faq";
import NotFound from "./pages/NotFound";
import GlobeTrotters from "./pages/GlobeTrotters";
import Schengen from "./pages/Schengen";
import Kidnapping from "./pages/Kidnapping";
import ConseilCima from "./pages/ConseilCima";
import Produit from "./pages/Produit";
import BlogCategorie from "./pages/BlogCategorie";
import AuditRisquesEntreprises from "./pages/AuditRisquesEntreprises";
import ScrollToTop from './services/Scrolltop';
import Loader from './components/Loader';
import "./styles/css/style.css";

// import ReactGA from 'react-ga';



const SwitchRoutes = (props) => {
  return (
    <>
      {/* \ <Header /> */}
      <ScrollToTop />

      <Switch>
        <Route path="/" exact component={Welcome} />
        <Route path="/e-bsi" exact component={Ebsi} />
        <Route path="/bsimmobilier" exact component={BSImmobilier} />
        <Route path="/apropos" exact component={Apropos} />
        <Route path="/devenir_partenaire" exact component={Partner} />
        <Route path="/plandusite" exact component={Plandusite} />
        <Route path="/politiquevieprivee" exact component={Politiquevieprivee} />
        <Route path="/lien-utile/:id" exact component={Mentionlegales} />
        <Route path="/politiquecookies" exact component={Politiquecookies} />
        <Route path="/blog" exact component={Blog} />
        <Route path="/blogdetails/:id" exact component={BlogDetails} />
        <Route path="/demandededevis/:query" exact component={DemandededevisPage} />
        {/*  <Route path="/Expatries" exact component={Expatries} /> */}
        <Route path="/pas-expatries" exact component={PasExpatries} />
        <Route path="/faq" exact component={Faq} />
        <Route path="/globe-trotters" exact component={GlobeTrotters} />
        {/* <Route path="/Schengen" exact component={Schengen} />
       <Route path="/Kidnapping" exact component={Kidnapping} />
      <Route path="/ConseilCima" exact component={ConseilCima} /> */}
        <Route path="/produit/:id" exact component={Produit} />
        <Route path="/blog-categorie/:id" exact component={BlogCategorie} />
        {/* <Route path="/AuditRisquesEntreprises" exact component={AuditRisquesEntreprises} /> */}


        <Route exact component={NotFound} />
      </Switch>

      {/* <Footer /> */}
    </>
  )
}
const SwitchRoutesWithRouter = withRouter(SwitchRoutes)

const App = () => {

  //   useEffect( () => {
  //   ReactGA.initialize('UA-236913417-1')

  //   ReactGA.pageview(window.location.pathname + window.location.search)

  // }, [window.location]);

  return (
    <Router>

      <SwitchRoutesWithRouter />

    </Router>
  );
}

export default App;
