import React from 'react'
import { useEffect, useState } from "react";
import { getEntity } from "../../../src/services/api";
import ReactHtmlParser from 'react-html-parser';

export default function GuarantiSection() {

    const [engagementData, engagementCharge] = useState([]);
    useEffect(() => {
      getEntity("/engagements")
        .then((res) => {
          engagementCharge(res.data.data);
          console.log(res.data.data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }, []);


  return (
        <section className="about-area ptb-60 mt-5">
            <div className="container" 
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-once="false">
                <div className="section-title">
                    <h2>Notre engagement</h2>
                    <p>Nous vous assurons un service de qualité {" "}
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                    </p>
                </div>

                <div className="about-boxes-area mtreduce-35">
                    <div className="row justify-content-center">
                    {engagementData.map((item,index)=>(
                        <div className="col-lg-3 col-md-4 col-sm-4  col-6" key={index}>
                            <div className="single-about-box">
                                <div className="icon">
                                    {ReactHtmlParser(item.icone)}
                                </div>

                                <h3>{item.titre}</h3>
                                {
                                    window.innerWidth > 600 && <p>{ReactHtmlParser(item.description)}</p>
                                }
                                 
                                
                            </div>
                        </div>
                    ))}
                        
                    </div>
                </div>
            </div>
        </section>
  )
}
