import React, { useState } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';




export default function Step5({ CurrentTitle, Prev, values, setValues, errors, setErrors, onSubmit }) {

  const [disabledButton, setDisabledButton] = useState("");
  const [alert, setAlert] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => { setShow(false); setDisabledButton("") }

  const handleShow = () => {

    if (values.captcha) {

      setErrors({ ...errors, captcha: '', autreInformations: '', message: '' })
      setDisabledButton("disabled");
      setShow(true);

    } else {

      setErrors({ ...errors, captcha: 'required' })

    }

  }

  const handleSubmit = () => {
    setShow(false);
    // setAlert({ success: true, message: 'Votre demande a été prise en compte. Merci !!!' });
    // setTimeout(() => {
    // setAlert(null);
    // setValues({});
    // setErrors({});
    // window.location.reload();
    // }, 3000);

    // setDisabledButton('');

    if (values.captcha) {

      setErrors({ ...errors, captcha: '', autreInformations: '', message: '' })
      setDisabledButton("disabled");
      onSubmit();

    } else {

      setErrors({ ...errors, captcha: 'required' })

    }

    /*  setDisabledButton("disabled");
     onSubmit(); */
  }

  const handleChangeCaptcha = (e) => {

    if (!e) {
      setErrors({ ...errors, captcha: 'required' })
    } else {
      setErrors({ ...errors, captcha: '' })
      setValues({ ...values, captcha: e });
    }

  }

  const handleChangeAutreInformations = (e) => {

    if (!e) {
      setErrors({ ...errors, autreInformations: 'noMessage' })
    } else {
      setValues({ ...values, autreInformations: e });
      setErrors({ ...errors, autreInformations: '' })
    }

    if (e.stopPropagation) e.stopPropagation();
  }


  return (
    <>
      <h6 className="text-center">Un dernier mot avant d'envoyer ?</h6>
      <div className="row">
        <div className="offset-md-1 col-lg-10 col-md-6 col-sm-12">
          <div className="form-group">
            {/*  <label>C'est le moment :</label> */}
            <textarea
              rows={6}
              name="autreInformations"
              onInput={(e) => handleChangeAutreInformations(e.target.value)}
              defaultValue={values.autreInformations}
              id="autreInformations"
              className="form-control"
              required
              data-error=""
              placeholder="message"
            >
            </textarea>
            {
              errors.autreInformations === 'required' ?
                <div className="errorStyle">
                  Le champ est obligatoire.
                </div>
                : errors.autreInformations === 'noMessage'
                  ? ''
                  : errors.autreInformations === ''
                    ?
                    <div className="successStyle">
                      Correct ! 😊
                    </div>
                    : ''
            }
          </div>
        </div>

        <div className="offset-md-1 col-lg-10 col-md-6 col-sm-12 mt-3">
          <ReCAPTCHA
            className="form-control"
            sitekey='6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
            defaultValue={values.captcha}
            onChange={(e) => handleChangeCaptcha(e)}
          />

          {
            errors.captcha === 'required' ?
              <div className="errorStyle">
                Le champ est obligatoire.
              </div>
              : errors.captcha === ''
                ?
                <div className="successStyle">
                  Correct ! 😊
                </div>
                : ''
          }
        </div>

      </div>

      {
        alert && <Alert className="text-center" variant={alert.success ? 'success' : 'danger'} onClose={() => setAlert(null)} dismissible>
          {alert.message}
        </Alert>
      }


      <div style={{ marginLeft: "3px" }} className={`col-lg-12 col-md-12 col-sm-12 form-footer-content-position 
      ${CurrentTitle === 3 || CurrentTitle === 4 || CurrentTitle === 5 ? 'form-footer-margin' : ''}`}>

        {disabledButton === "disabled" ?
          <span></span>
          :
          <button type="button" onClick={Prev} className="default-btn default-btn-annuler">
            Retour </button>
        }


        {disabledButton === "disabled" ?
          <button disabled type="button" style={{ marginLeft: "10px" }} className="default-btn">
            En cours d'envoi<i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i></button>
          :
          <button type="button" onClick={handleSubmit} style={{ marginLeft: "10px" }} className="default-btn">
            Envoyer</button>
        }

      </div>



      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered={true}
        keyboard={false}
      >
        <Modal.Header >
          <Modal.Title>Confirmation !!!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Vous êtes sur le point de valider votre demande ! <br />
          Appuyez sur valider pour soumettre vos informations !
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={handleClose} style={{ marginLeft: "10px" }} className="default-btn-annuler">
            Annuler</button>
          <button type="button" onClick={handleShow} style={{ marginLeft: "10px" }} className="default-btn">
            Valider</button>
        </Modal.Footer>
      </Modal>

    </>
  )
}
