import React from "react";
import aurelie from "../../styles/img/image-aurelie.webp";
import gillus from "../../styles/img/image-gillus.webp";
import francois from "../../styles/img/image-françois.webp";
import { useEffect, useState, useRef } from "react";
import moment from "moment";
import "moment/locale/fr";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { Tag } from 'primereact/tag';


import { apiURL, getEntity } from "../../../src/services/api";

//import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactHtmlParser from 'react-html-parser';
import Slider from "react-slick";

export default function TeamSection() {
  const owlCarouselRef = useRef(null);
  const [data, empdatachange] = useState([]);
  const swiperRef = useRef(null);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (owlCarouselRef.current) {
      owlCarouselRef.current.onInitialized();
    }

    moment.locale("fr");
    getEntity("/associers")
      .then((res) => {

        empdatachange(res.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const handleMouseEnter = () => {
    setHovered(true);
    if (swiperRef.current !== null) {
      swiperRef.current.navigation.update();
    }
  };

  const handleMouseLeave = () => {
    setHovered(false);
    if (swiperRef.current !== null) {
      swiperRef.current.navigation.update();
    }
  };


  return (
    <section className="team-area ptb-60">
      <div
        className="container"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-once="false"
      >
        <div className="section-title" data-aos="zoom-in-down">
          <h2>LES ASSOCIÉS BSI</h2>
          {/* <p>Des spécialistes « métiers » à votre service !</p> */}
          <p>
            Des spécialistes « métiers » à votre service. Et en plus on est
            sympa 😄
          </p>
        </div>

        <div className=" " onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>


          <Swiper className="desktop"
            ref={swiperRef}
           autoplay={{ delay: 3000, disableOnInteraction: true }}
             //observer={false}
            // observeslidechildren={false}
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]} 
            spaceBetween={0}
            slidesPerView={3}
            //navigation={hovered}
            //pagination={{ clickable: true }}
            // scrollbar={{ draggable: false }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => {
              setTimeout(() => {
                swiper.update(); // ------> this solution
              }, 3000);
            }}
           loop={true}

           

          >

            {data.map((item, index) => (
              <SwiperSlide key={index}>

                <div

                  className="single-team-box"
                  data-aos-offset="200"
                  data-aos-delay="10"
                  data-aos-duration="2500"
                >
                  <div className="image" >
                    <img src={`${apiURL}${item.photo}`} alt={`image-${item.nom_prenom}`} />

                    <ul className="social">
                      <li><a href={`${item.linkdin}`}><i className="fab fa-linkedin-in"></i></a></li>
                    </ul>
                  </div>

                  <div className="content">
                    <h3>{item.nom_prenom}</h3>
                    <span>{item.status}</span>
                    <p style={{color:"black"}}>{ReactHtmlParser(item.description)}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <Swiper className="mobile"
            autoplay={{ delay: 3000, disableOnInteraction: true }}
            // observer={false}
            // observeslidechildren={false}
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={15}
            slidesPerView={1}
          //  navigation
           // pagination={{ clickable: true }}
            // scrollbar={{ draggable: false }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => {
              setTimeout(() => {
                swiper.update(); // ------> this solution
              }, 3000);
            }}
          >

            {data.map((item, index) => (
              <SwiperSlide key={index} className="image-mobile">

                <div

                  className="single-team-box"
                  data-aos-offset="200"
                  data-aos-delay="10"
                  data-aos-duration="2500"
                >
                  <div className="image" >
                    <img src={`${apiURL}${item.photo}`} alt={`image-${item.nom_prenom}`} />

                    <ul className="social">
                      <li><a href={`${item.linkdin}`}><i className="fab fa-linkedin-in"></i></a></li>
                    </ul>
                  </div>

                  <div className="content">
                    <h3>{item.nom_prenom}</h3>
                    <span>{item.status}</span>
                    <p style={{color:"black"}}>{ReactHtmlParser(item.description)}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}
