import React from 'react'
import florence from '../../styles/img/florence-90x90.jpg'
import georges from '../../styles/img/Georges-Karam-90x90.jpg'
import melanie from '../../styles/img/mélanie-besancenot-1-90x90.jpg'
import boris from '../../styles/img/boris-90x90.jpg'
import majorelle from '../../styles/img/PHOTOS-MAJORELLE8-90x90.jpg'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function TestimonialsSection() {

    
    return (
        <section className="feedback-area ptb-60">
            <div className="container"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-once="false">
                <div className="section-title">
                    <h2>Ce qu'en disent nos clients...</h2>
                    <p>Notre meilleure récompense : des assurés satisfaits !</p>
                    <div className='guillemet'>
                    <span ></span>
                </div>
                </div>

                
                <Swiper className='testimonie'
                    autoplay={{ delay: 3000, disableOnInteraction: true }}
                    // observer={false}
                    // observeslidechildren={false}
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    // spaceBetween={15}
                    slidesPerView={1}
                    // navigation
                    // pagination={{ clickable: true }}
                    // scrollbar={{ draggable: false }}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => {
                        setTimeout(() => {
                            swiper.update(); // ------> this solution
                        }, 3000);
                    }}
                >
                    <SwiperSlide>


                        <div className="item">
                            <div className="single-feedback">
                                
                                <p>“BSI a su trouver le contrat qui me convenait, signé dans un temps éclair. Vu le  contexte difficile, le processus a été des plus efficace et tranquillisant.”</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="item">
                            <div className="single-feedback">
                                
                                <p>“Comme expatrié, j'apprécie la disponibilité et l'écoute de l'équipe BSI.”</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>

                        <div className="item">
                            <div className="single-feedback">
                                
                                <p>“J'ai apprécié la clarté des explications de BSI avant de souscrire.”</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="item">
                            <div className="single-feedback">
                                
                                <p>“J'apprécie la réactivité et le professionnalisme de BSI lors de toutes mes démarches santé. On se sent plus serein lorsque l'on est bien conseillé.”</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                       
                        <div className="item">


                            <div className="single-feedback">

                                <p>“Quand je me suis installé à Londres pour étudier, BSI a compris ce qui me fallait et ma famille a été rassurée de me savoir bien couvert.”</p>
                            </div>
                        </div>


                    </SwiperSlide>
                </Swiper>
              {/*   <Swiper className='testymonie'
                    autoplay={{ delay: 3000, disableOnInteraction: true }}
                    // observer={false}
                    // observeslidechildren={false}
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    spaceBetween={0}
                    slidesPerView={3}
                    width={300}
                    centeredSlides={true}
                    // navigation
                    //pagination={{ clickable: true }}
                    // scrollbar={{ draggable: false }}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => {
                        setTimeout(() => {
                            swiper.update(); // ------> this solution
                        }, 3000);
                    }}
                >
                    <SwiperSlide className='client-thumbnails' width={90}>
                        <div className="itemtestimoni" >
                            <div className="img-fill"><img src={florence} alt="client" /></div>

                            <div className="title">
                                <h3>Florence Oberholzer</h3>
                                <span>Kananga en République Démocratique du Congo</span>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='client-thumbnails' width={90}>
                        <div className="item">
                            <div className="img-fill"><img src={georges} alt="client" /></div>

                            <div className="title">
                                <h3>Georges Karam</h3>
                                <span>Beyrouth au Liban</span>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='client-thumbnails'>
                        <div className="item">
                            <div className="img-fill"><img src={melanie} alt="client" /></div>

                            <div className="title">
                                <h3>Mélanie Besancenot</h3>
                                <span>Guangzhou en Chine</span>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='client-thumbnails'>
                        <div className="item">
                            <div className="img-fill"><img src={boris} alt="client" /></div>

                            <div className="title">
                                <h3>Boris Delmouly</h3>
                                <span>Antananarivo à Madagascar</span>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='client-thumbnails'>
                        <div className="item">
                            <div className="img-fill"><img src={majorelle} alt="client" /></div>

                            <div className="title">
                                <h3>Chadrac Ouedraogo</h3>
                                <span>Londres au Royaume-Uni</span>
                            </div>
                        </div>

                    </SwiperSlide>
                </Swiper> */}

            </div>
        </section >
    )
}
