const CurrentDate = param => {
    var today = new Date();
    if(param) {
      today.setDate(today.getDate() + 1)
    }
    var dd = today.getDate();
    var mm = today.getMonth()+1;
    var yyyy = today.getFullYear();
    if(dd<10){
      dd='0'+dd
    } 
    if(mm<10){
      mm='0'+mm
    } 
    today = yyyy+'-'+mm+'-'+dd;

    return today;
};

// export const DateAfterFirstDateChoosen = param => {
//   let d = param.replaceAll("-",",");
//   var day = new Date(param);
//   var day = day.setDate(day.getDate() + 1);
//   var dd = day.getDate();
//   var mm = day.getMonth()+1;
//   var yyyy = day.getFullYear();
//   if(dd<10){
//     dd='0'+dd
//   } 
//   if(mm<10){
//     mm='0'+mm
//   } 
//   day = yyyy+'-'+mm+'-'+dd;

//   return day;
// };

export default CurrentDate;