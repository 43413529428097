import React from 'react'
import Header from '../layouts/Header'
import Footer from '../layouts/Footer'

export default function Plandusite() {
    return (
        <>
            <div>
                <Header />
            </div>
            <div className="page-title-area page-title-bg-sitemap">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>Plan du site</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="sitemap-area ptb-60">
                <div className="container" data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-once="false">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 sitemap-area-items">

                            <ul>
                                <h2>Particuliers</h2>

                                <li><a href="/expatries">Assurances expatriés</a></li>
                                <li><a href="/Pas-expatries">Assurances non expatriés</a></li>
                                <li><a href="#">Globe-trotters et étudiants</a></li>
                                <li><a href="#">Assurances Schengen</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 sitemap-area-items">

                            <ul>
                                <h2>Entreprises</h2>

                                <li><a href="#">Collectifs santé et prévoyance</a></li>
                                <li><a href="#">Audit risques entreprises</a></li>
                                <li><a href="#">Assurance kidnapping, crise et sécurité</a></li>
                                <li><a href="#">Conseil CIMA</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 sitemap-area-items">

                            <ul>
                                <hr />

                                <li><a href="/e-bsi">e-bsi</a></li>
                                <li><a href="/bsimmobilier">bsimmobilier</a></li>
                                <li><a href="/apropos">À propos</a></li>
                                <li><a href="/devenir_partenaire">Devenir partenaire</a></li>
                                <li><a href="/blog">blog</a></li>
                                <li><a href="/Faq">FAQ</a></li>
                                <li><a href="/Mentionlegales">Mentions légales</a></li>
                                <li><a href="/politiquevieprivee">Politique vie privée</a></li>
                                <li><a href="/politiquecookies">Politique cookies</a></li>
                                <li><a href="/politiquecookies">Plan du site</a></li>

                            </ul>

                        </div>
                    </div>
                </div>
            </section>
            <div>
                <Footer />
            </div>
        </>
    )
}
