import React from 'react'
import { useEffect, useState } from "react";
import moment from 'moment';
import 'moment/locale/fr';
import { Link, useParams } from "react-router-dom";
import { apiURL, getEntity, getEntityById } from "../services/api";

import dummyImage from '../img/dummy.jpg';
import { render } from 'react-dom';
import ReactHtmlParser from 'react-html-parser';
import Loader from '../components/Loader';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Search from '../components/Search';
export default function Blog() {
    const [data, empdatachange] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [datarecentPub, recentPub] = useState([]);
    const [datacategorie, categorie] = useState([]);
    const { id } = useParams();
    useEffect(() => {
        moment.locale('fr');
        setIsLoading(true);
        getEntityById(`/poste-categories/${id}?page=${currentPage}`).then((res) => {
            //return res.data.data;
            console.log(res.data);
            empdatachange(res.data.data);
            setLastPage(res.data.last_page);
            setIsLoading(false);
        }).catch((err) => {
            console.log(err.message);
            setIsLoading(false);
        });

        //les trois derniière publication
        getEntity("/poste")
            .then((res) => {
                recentPub(res.data.data);
            })
            .catch((err) => {
                console.log(err.message);
            });

        //les categories publication
        getEntity("/categories")
            .then((res) => {
                categorie(res.data.data);

            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [currentPage]);
    //finction de pagination
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < lastPage) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Fonction pour limiter le nombre de caractères de la description
    const limitDescription = (description, limit) => {
        if (description.length <= limit) {
            return description;
        } else {
            return description.slice(0, limit - 3) + '...';
        }
    };


    return isLoading ?
        <Loader /> : (
            <>
                <div>
                    <Header />
                </div>
                <div style={{ overflowX: 'hidden' }}>
                    <div className="page-title-area page-title-blog">
                        <div className="d-table">
                            <div className="d-table-cell">
                                <div className="container">
                                    <div className="page-title-content">
                                        <h2>blog & News</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <section className="blog-area ptb-60">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-12">
                                    {
                                        data.length > 0 ? (
                                            <div className="row">
                                                {/* col-lg-4 */}

                                                {data.map((item, index) => (
                                                    <div key={index} className="col-md-6">
                                                        <div className="single-blog-post">
                                                            <div className="post-image">
                                                                <a href="#">{item.photo ? <img src={`${apiURL}${item.photo}`} height={'247px'} alt="image" /> : <img src={dummyImage} height={'247px'} alt="image" />}</a>

                                                                <div className="date"><i className="flaticon-timetable"></i> {' '}
                                                                    {moment(item.date_post).format('MMM DD, YYYY')}</div>
                                                            </div>

                                                            <div className="post-content">
                                                                <h3><a href="#">{item.titre}</a></h3>
                                                                {/*  <p>{ReactHtmlParser(limitDescription(item.description, 450))}</p> */}

                                                                <a href={`/blogdetails/${item.slug}`} className="default-btn" target='_blank'>Lire plus <span></span></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}




                                            </div>) : (
                                            <p style={{ textAlign: "center" }}>Aucune donnée disponible.</p>
                                        )
                                    }
                                </div>
                                <div class="col-lg-4 col-md-12">
                                    <aside class="widget-area" id="secondary">

                                        <section class="widget widget_search" style={{ marginBottom: '20px' }}>
                                            <form class="search-form">
                                                <label>
                                                    <Search />
                                                </label>
                                                {/* <button type="submit"><i class="fas fa-search"></i></button> */}
                                            </form>
                                        </section>

                                        <section class="widget widget_pearo_posts_thumb widget_search" style={{ marginBottom: '20px' }}>
                                            <h3 class="widget-title">Récentes publications</h3>
                                            <hr />
                                            {datarecentPub.map((item, index) => (
                                                <article class="recentePub" key={index}>
                                                    {item.photo ? <img src={`${apiURL}${item.photo}`} style={{ width: "70px", height: "50px" }} /> : <img src={dummyImage} style={{ width: "70px", height: "50px" }}
                                                    />}
                                                    <div class="info">
                                                        <time datetime="2021-06-30">
                                                            {moment(item.date_post).format("MMM DD, YYYY")}
                                                        </time>
                                                        <h6 class="title usmall">
                                                            <a href={`/blogdetails/${item.slug}`} target='_blank'>{item.titre}</a>
                                                        </h6>
                                                    </div>


                                                </article>
                                            ))}
                                        </section>

                                        <section class="widget widget_categories widget_search">
                                            <h3 class="widget-title">Catégories</h3>

                                            {datacategorie.length > 0 ? (
                                                <ul>
                                                    {datacategorie.map((item, index) => (
                                                        <li key={index}><a href={`/blog-categorie/${item.slug}`} target='_blank'>{item.titre}</a></li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>Aucune donnée disponible.</p>
                                            )}
                                        </section>
                                    </aside>
                                </div>

                            </div>
                        </div>
                    </section> {data.length > 0 ? (
                        <div className="d-flex justify-content-center">
                            <nav aria-label="Pagination">

                                <ul className="pagination">
                                    <button className="btn page-link btn  m-2" onClick={handlePreviousPage}>&lt; Previous</button>

                                    {Array.from(
                                        { length: lastPage },
                                        (_, index) =>
                                            ((index + 1 <= 4 && currentPage <= 4) ||
                                                (index + 1 >= lastPage - 4 &&
                                                    currentPage >= lastPage - 4) ||
                                                Math.abs(index + 1 - currentPage) <= 1) && (

                                                <button
                                                    key={index}
                                                    onClick={() => handlePageChange(index + 1)}
                                                    className={`${currentPage === index + 1 ? 'btn btn-primary' : 'btn page-link'}`}
                                                >
                                                    {index + 1}
                                                </button>

                                            )
                                    )}
                                    <button className="btn page-link  m-2" onClick={handleNextPage}>Next &gt;</button>
                                </ul>

                            </nav>
                        </div>
                    ) : ('')}

                    <div>
                        <Footer />
                    </div>
                </div>
            </>
        )
}
