import React from 'react'
import ReactGA from 'react-ga';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { apiURL, getEntity } from "../../../src/services/api";
import { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/fr";
import ReactHtmlParser from 'react-html-parser';

import CardGroup from 'react-bootstrap/CardGroup';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
export default function ServiceSection() {


  const [data, empdatachange] = useState([]);

  useEffect(() => {
    moment.locale("fr");
    getEntity("/type-produit")
      .then((res) => {
        empdatachange(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  // Fonction pour limiter le nombre de caractères de la description
  const limit = (description, limit) => {
    if (description.length <= limit) {

      return description;
    } else {
      return description.slice(0, limit) + '...';
    }
  };


  const trakerEventProduit = (event) => {

    ReactGA.event({
      category: 'Produit',
      action: `Produit : ${event.target.innerText}`,
    });

  };

  /*  const limitDescription = (description, limit) => {
       if (description.length <= limit) {
         return description;
       } else {
         return description.slice(0, limit) + '...';
       }
     }; */
  return (


    <section id="portfolio" className="portfolio ptb-60" >
      <div className="container"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-once="false">
        <div className="section-title">
          <h2>Nos services</h2>
          <p> <b style={{ color: "#C14742" }}>Particuliers</b> ou <b style={{ color: "#C14742" }}>entreprises</b>, expatriés ou non, découvrez ici nos assurances. Et aussi nos bons plans "assurances voyage" ou "Schengen".</p>
        </div>

        {/* <div className="row mtreduce-35" data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
            <div className="col-lg-12 d-flex justify-content-center">
              <ul id="portfolio-flters">
                <li data-filter=".filter-card" className="filter-active">Particuliers</li>
                <li data-filter=".filter-web">Entreprises</li>
                <li data-filter=".filter-bsi">Autres services</li>
              </ul>
            </div>
          </div>
  
          <div className="row justify-content-center align-content-center portfolio-container" data-aos="fade-up" data-aos-delay="200" data-aos-once="false">
  
            <div className="col-lg-4 col-md-6 col-6 portfolio-item" >
                <div className="services-box">
                    <div className="image">
                        <img loading="lazy" src="assets/img/entreprise/petit_format/contrat-collectif.webp"  alt="contrat-collectif"/>
                    </div>

                    <div className="content">
                        <h3><a href="/ContratCollectifPrevoyance" onClick={trakerEventProduit}>Collectifs santé et prévoyance</a></h3>


                        {
                            window.innerWidth < 600 
                            ? '' 
                            : <p>Courtier spécialiste de la protection santé et prévoyance des salariés expatriés ou locaux ...</p>
                        }

                        

                        <a href="/ContratCollectifPrevoyance" className="read-more-btn">Voir plus <i className="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>
  
            <div className="col-lg-4 col-md-6 col-6 portfolio-item filter-web" >
                <div className="services-box">
                    <div className="image">
                        <img loading="lazy" src="assets/img/entreprise/petit_format/audit.webp"  alt="image"/>
                    </div>

                    <div className="content">
                        <h3><a href="/AuditRisquesEntreprises" onClick={trakerEventProduit}>Audit risques entreprises</a></h3>

                        {
                            window.innerWidth < 600 
                            ? '' 
                            : window.innerWidth < 1395
                            ? <p>Nous pouvons convenir d’honoraires pour auditer vos garanties et risques ou collaborer à travers un “mandat d’étude </p>
                            : <p>Nous pouvons convenir d’honoraires pour auditer vos garanties et risques ou collaborer à travers un “mandat d’étude et de placement”.</p>
                        }

                        <a href="/AuditRisquesEntreprises" className="read-more-btn">Voir plus <i className="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 col-6 portfolio-item filter-web" >
                <div className="services-box">
                    <div className="image">
                        <img loading="lazy" src="assets/img/entreprise/petit_format/kidnapping.webp" alt="kidnapping"/>
                    </div>

                    <div className="content">
                        <h3><a href="/Kidnapping" onClick={trakerEventProduit}>Assurance kidnapping</a></h3>

                        {
                            window.innerWidth < 600 
                            ? '' 
                            : <p>Des formules d’assurance “crise et sécurité” pour les voyageurs ou expatriés y compris dans les “zones à risques”.</p>
                        }

                        <a href="/Kidnapping" className="read-more-btn">Voir plus <i className="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div className='row'>

                <div className="offset-md-2 col-lg-4 col-md-6 col-6 portfolio-item filter-card" >
                    <div className="services-box">
                        <div className="image">
                            <img loading="lazy" src="assets/img/particulier/petit_format/expatries.webp"  alt="expatries"/>
                        </div>

                        <div className="content">
                            <h3><a href="/expatries" onClick={trakerEventProduit}>Assurances expatriés</a></h3>

                            {
                                window.innerWidth < 600 
                                ? '' 
                                : window.innerWidth < 1395
                                ? <p>On est considéré comme expatrié quand on vit plus de six mois et un jour par an en dehors de son pays d’origine</p>
                                : <p>On est considéré comme expatrié quand on vit plus de six mois et un jour par an en dehors de son pays d’origine...</p>
                            }

                            <a href="/expatries" className="read-more-btn mobile-margin-top">Voir plus <i className="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-6 portfolio-item filter-card">
                    <div className="services-box">
                        <div className="image">
                            <img loading="lazy" src="assets/img/particulier/petit_format/pas-expatries.webp" alt="Pas-expatries"/>
                        </div>

                        <div className="content">
                            <h3><a href="/Pas-expatries" onClick={trakerEventProduit}>Assurances non expatriés</a></h3>

                            {
                                window.innerWidth < 600 
                                ? '' 
                                : window.innerWidth < 1395
                                ? <p>Découvrez nos plans de santé internationaux «1er euro» ...</p>
                                : <p>Découvrez nos plans de santé internationaux «1er euro» pour les personnes de toutes nationalités du monde entier.</p>
                            }

                            <a href="/Pas-expatries" className="read-more-btn">Voir plus <i className="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>

            </div>

            

            <div className='row'>
                <div className="offset-md-2 col-lg-4 col-md-6 col-6 portfolio-item filter-card" >
                    <div className="services-box">
                        <div className="image">
                            <img loading="lazy" src="assets/img/particulier/petit_format/etudiants.webp" alt="etudiants"/>
                        </div>

                        <div className="content">
                            <h3><a href="/globe-trotters" onClick={trakerEventProduit}>Globe-trotters et étudiants</a></h3>

                            {
                                window.innerWidth < 600 
                                ? '' 
                                : <p>Des assurances adaptées aux budgets étudiants avec notre sélection de bons plans pour vous accompagner ...</p>
                            }

                            <a href="/globe-trotters" className="read-more-btn">Voir plus <i className="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>
  
                <div className="col-lg-4 col-md-6 col-6 portfolio-item filter-card">
                    <div className="services-box">
                        <div className="image">
                            <img loading="lazy" src="assets/img/particulier/petit_format/schengen-voyage.webp" alt="schengen-voyage"/>
                        </div>

                        <div className="content">
                            <h3><a href="/Schengen" onClick={trakerEventProduit}>Assurances Schengen</a></h3>

                            {
                                window.innerWidth < 600 
                                ? '' 
                                : window.innerWidth < 1395
                                ? <p>Nos informations sur l’espace Schengen et les meilleures “assurances obligatoires” pour l’obtention du visa. </p>
                                : <p>Nos informations sur l’espace Schengen et les meilleures “assurances obligatoires” pour l’obtention du visa. Souscription ...</p>
                            }

                            <a href="/Schengen" className="read-more-btn mobile-margin-top">Voir plus <i className="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 col-6 portfolio-item filter-bsi" >
                <div className="services-box">
                    <div className="image">
                        <img loading="lazy" src="assets/img/entreprise/petit_format/conseil-cima.webp" alt="conseil-cima"/>
                    </div>

                    <div className="content">
                        <h3><a href="/ConseilCima" onClick={trakerEventProduit}>Conseil CIMA</a></h3>

                        {
                            window.innerWidth < 600 
                            ? ''
                            : <p>Nous connaissons particulièrement bien la zone CIMA où nous sommes également agréés à travers notre cabinet à Cotonou (Bénin).</p>
                        }

                        <a href="/ConseilCima" className="read-more-btn">Voir plus <i className="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 col-6 portfolio-item filter-bsi">
                <div className="services-box">
                    <div className="image">
                        <img loading="lazy" src="assets/img/Logo-ebsi.webp" alt="Logo-ebsi"/>
                    </div>

                    <div className="content">
                        <h3><a href="/e-bsi" onClick={trakerEventProduit}>Assurtech</a></h3>

                        {
                            window.innerWidth < 600 
                            ? ''
                            : <p>e-bsi est notre “assurtech” dédiée à la transformation digitale des assureurs : E-Business System for Insurance.</p>
                        }

                        <a href="/e-bsi" className="read-more-btn">Voir plus <i className="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 col-6 portfolio-item filter-bsi">
                <div className="services-box">
                    <div className="image">
                        <img loading="lazy" src="assets/img/logo-bsi-immobilier.webp" alt="logo-bsi-immobilier"/>
                    </div>

                    <div className="content">
                        <h3><a href="/bsimmobilier" onClick={trakerEventProduit}>Bsi Immobilier</a></h3>

                        {
                            window.innerWidth < 600 
                            ? ''
                            : <p>Notre département qui traite de tout ce qui a trait a l'Immobilier pour un accompagnement exeptionnel dans vos investissements.</p>
                        }

                        <a href="#" className="read-more-btn">Voir plus <i className="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>
  
          </div> */}
        <Tabs
          defaultActiveKey="Particuliers"
          id="fill-tab-example"
          className="mb-3"
          fill
        >
          {data.map((item, index) => (
            <Tab eventKey={item.titre} title={item.titre} key={index}>
              <Row xs={1} md={2} className="g-4">
                {item.produits.map((service, ind) => (

                  <Col key={ind}>
                    <Card className="services-box" >
                      <Card.Img className="image" variant="top" src={`${apiURL}${service.photo}`} style={{ objectFit: "contain" }} alt="contrat-collectif" />
                      <Card.Body>
                        <Card.Title><Link to={`/produit/${service.slug}`} onClick={trakerEventProduit}>{service.titre}</Link></Card.Title>
                        <Card.Text>
                          {/*  <p>{limit(ReactHtmlParser(service.description), 450)}</p> */}
                        </Card.Text>
                        <Link to={`/produit/${service.slug}`} className="default-btn">Voir plus <i className="flaticon-right-chevron"></i></Link>
                      </Card.Body>
                    </Card>
                  </Col>
                ))
                }
              </Row>
            </Tab>
          ))}
        </Tabs>

      </div>
    </section>
  )
}
