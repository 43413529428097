import { useState,useEffect,useRef } from "react";
import CurrentDate from '../../common/getCurrentDate'


export default function ChildFormEntreprise({position,onChildClose,handleChangeEnfant,positionAssured}) {


  const titleRef = useRef("");

  // const timeout = (ms) => {
  //     return new Promise((resolve) => {setTimeout(resolve,ms)})
  // }


  // useEffect(() => {
    
  //   let isCancelled = false;

  //   const handleExecution = async() => {

  //     await timeout(200)

  //     if(values[position]) {

  //       const newOldEnfant = [...oldEnfant]

  //         if (!isCancelled) {
          
  //           if(oldEnfant[position]) {

  //               newOldEnfant[position] = old(values[position].dateNaissanceEnfant);

  //           }else { 

  //               newOldEnfant.push(old(values[position].dateNaissanceEnfant))
    
  //           }

  //           setOldEnfant(newOldEnfant);

  //         }

  //     }    

  //   }

  //   handleExecution();

  //   return () => { 
  //     isCancelled = true;
  //   };

  // }, [values[position]])


  return (
    <>
      <div className="row">
        <h6 className="offset-md-1 col-lg-10 col-md-6 col-sm-12">
          Enfant {position + 1}
        </h6>
        <div className="offset-9 col-lg-2 col-md-6">
          <i 
            onClick={() => onChildClose()} 
            className="fas fa-trash" style={{fontSize:'15px',
              color: '#ffffff',
              background: 'rgb(220, 53, 69)',
              border: '1px solid rgb(220, 53, 69)',
              borderRadius:'50%',
              padding:'8px'}}>
          </i>
        </div>
        <div className="offset-md-1 col-lg-10 col-md-6 col-sm-12">
          <div className="form-group">
            <label>Date de naissance<abbr>*</abbr>
              <b>
                { 
                  // (oldEnfant[position] >= 1) ? '(' + oldEnfant[position] + `${oldEnfant[position] > 1 ? ' ans' : oldEnfant[position] === 1 ? 'an' : ''})` : (oldEnfant[position] === 0) ? '( 0 an )' : ''
                }
              </b>
            </label>

              {
                
                window.innerWidth > 600 ? 

                <input
                  ref={titleRef}
                  type="date"
                  name="date"
                  id="date"
                  max={CurrentDate()}
                  onInput={(e) => handleChangeEnfant(e.target.value,position,positionAssured)}
                  // defaultValue={values[position] ? values[position].dateNaissanceEnfant : ''}
                  key={position}
                  className="form-control"
                />

                :

                <div className="input-group date" id="datepicker">
                  <input
                    ref={titleRef}
                    type="text"
                    name="date"
                    id="date"
                    max={CurrentDate()}
                    onInput={(e) => handleChangeEnfant(e.target.value,position,positionAssured)}
                    // defaultValue={values[position] ? values[position].dateNaissanceEnfant : ''}
                    // key={key}
                    className="form-control"
                  />
                  <span className="input-group-append">
                    <span className="input-group-text" style={{height: '61px', background: '#ffffff'}}>
                    <i className="fa fa-calendar"></i>
                    </span>
                  </span>
                </div>
              }


            {/* {
              errors.child && errors.child[position] === 'required' ?
              <div className="errorStyle">
                Le champ est obligatoire.
              </div>
                : errors.child && errors.child[position] === ''  
                ?
              <div className="successStyle">
                Correct ! 😊
              </div>
              : ''
            } */}
          </div>
        </div>
      </div>
    </>
  );
}
