import React, { useState, useEffect } from 'react'
import HeroSection from '../components/Welcome/HeroSection'
import QuoteBoxSection from '../components/Welcome/QuoteBoxSection'
import GuarantiSection from '../components/Welcome/GuarantiSection'
import ServiceSection from '../components/Welcome/ServiceSection'
import StepSection from '../components/Welcome/StepSection'
import TarificateurSection from '../components/Welcome/TarificateurSection'
import TestimonialsSection from '../components/Welcome/TestimonialsSection'
import TeamSection from '../components/Welcome/TeamSection'
import PartnerSection from '../components/Welcome/PartnerSection'
import BlogSection from '../components/Welcome/BlogSection'
import Header from '../layouts/Header'
import Footer from '../layouts/Footer'
import Loader from '../components/Loader'
import { getEntity } from '../services/api'
import "../styles/css/style.css";


export default function Welcome() {
  const [isLoading, setIsLoading] = useState(true);
  const [dataphoto, empdataphoto] = useState([]);
  const [partenaire, partenaires] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getEntity("/acceuil").then((res) => {
      empdataphoto(res.data.data);
      setIsLoading(false);

    }).catch((err) => {
      console.log(err.message);
      setIsLoading(false);

    });

    getEntity("/partenaires").then((res) => {

      partenaires(res.data.data);
      
    }).catch((err) => {
      console.log(err.message);
    });

  }, []);
  return isLoading ?
    <Loader /> : (
      <div>
        <div>
          <Header />
        </div>

        <HeroSection dataphoto={dataphoto} />
        <QuoteBoxSection />
        <GuarantiSection />
        <ServiceSection />
        <StepSection />
        <TarificateurSection />
        <TestimonialsSection />
        <TeamSection />
        <PartnerSection partenaire={partenaire} />
        <BlogSection />
        <div>
          <Footer />
        </div>
      </div>
    )
}
