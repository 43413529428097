import React from 'react'
import { useEffect, useState, useRef } from "react";
import moment from 'moment';
import 'moment/locale/fr';
import { apiURL, getEntity } from "../services/api";
import Autosuggest from 'react-autosuggest';

import dummyImage from '../img/dummy.jpg';
import { render } from 'react-dom';
import ReactHtmlParser from 'react-html-parser';
import Loader from '../components/Loader';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Search from '../components/Search';
export default function Blog() {
  const [data, empdatachange] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [datarecentPub, recentPub] = useState([]);
  const [datacategorie, categorie] = useState([]);
  //search
  const [postes, setPostes] = useState([]);
  const [filteredPostes, setFilteredPostes] = useState([]);
  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsVisible, setSuggestionsVisible] = useState(true);
  const suggestionListRef = useRef(null);

  useEffect(() => {
    moment.locale('fr');
    setIsLoading(true);
    getEntity(`/postes?page=${currentPage}`).then((res) => {
      //return res.data.data;
      empdatachange(res.data.data);
      setLastPage(res.data.last_page);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err.message);
      setIsLoading(false);
    });

    //les trois derniière publication
    getEntity("/poste")
      .then((res) => {
        recentPub(res.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });

    //les categories publication
    getEntity("/categories")
      .then((res) => {
        categorie(res.data.data);

      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [currentPage]);
  //finction de pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Fonction pour limiter le nombre de caractères de la description
  const limitDescription = (description, limit) => {
    if (description.length <= limit) {
      return description;
    } else {
      return description.slice(0, limit - 3) + '...';
    }
  };

  //search

  useEffect(() => {

    if (search.trim() === '') {
      return;
    }

    getEntity(`/postes/search?titre=${search}`).then((res) => {
      setPostes(res.data.data)
      setFilteredPostes(res.data.data);
    }).catch((err) => {
      console.log(err.message);
    })
  }, [search])

  const handleFiltre = ({ search }) => {
    if (!search) {
      setFilteredPostes(postes); // Réinitialiser avec la liste complète
      return;
    }

    const filtered = postes.filter((item) => {
      const titleMatch = search ? item.titre.toLowerCase().includes(search.toLowerCase()) : true;
      /*  const authorMatch = searchAuteur ? item.auteur.toLowerCase().includes(search.toLowerCase()) : true; */
      return titleMatch/* , authorMatch */;
    });
    setFilteredPostes(filtered);
  };

  /* const handleFiltre = ({ search }) => {
    setSearch(search); // Mettre à jour search avec le titre de recherche
  }; */

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestionsVisible(true);
    // Mettre à jour les suggestions en fonction de la valeur de recherche actuelle
    // Vous pouvez également effectuer une requête API ici si nécessaire
    const filteredSuggestions = postes.filter((document) =>
      document.titre.toLowerCase().includes(value.toLowerCase())
    );
    console.log(filteredSuggestions);
    setSuggestions(filteredSuggestions);
  };

  const onSuggestionsClearRequested = () => {
    // Effacer les suggestions lorsque l'entrée de l'utilisateur est effacée
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) => suggestion.titre;
  const renderSuggestion = (suggestion) => <div>{suggestion.titre}</div>;

  const inputProps = {
    placeholder: 'Rechercher...',
    value: search,
    //onChange: (e, { newValue }) => handleFiltre({ search: newValue }),
    onChange: (e, { newValue }) => setSearch(newValue),
    /*  onKeyDown: (e) => {
         if (e.key === 'Enter') {
             handleSearch();
         }
     }, */
  };

  // Ajoutez un gestionnaire d'événements pour les clics sur le document
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionListRef.current && !suggestionListRef.current.contains(event.target)) {
        setSuggestionsVisible(false);

      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [suggestionListRef]);

  return isLoading ?
    <Loader /> : (
      <>
        <div>
          <Header />
        </div>
        <div style={{ overflowX: 'hidden' }}>
          <div className="page-title-area page-title-blog">
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="page-title-content">
                    <h2>blog & News</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <section className="blog-area ptb-60">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-12">
                  <div className="row">
                    {/* col-lg-4 */}
                    {filteredPostes.length>0?(filteredPostes.map((item, index) => (
                      <div key={index} className="col-md-6">
                        <div className="single-blog-post">
                          <div className="post-image">
                            <a href="#">{item.photo ? <img src={`${apiURL}${item.photo}`} height={'247px'} alt="image" /> : <img src={dummyImage} height={'247px'} alt="image" />}</a>

                            <div className="date"><i className="flaticon-timetable"></i> {' '}
                              {moment(item.date_post).format('MMM DD, YYYY')}</div>
                          </div>

                          <div className="post-content">
                            <h3><a href="#">{item.titre}</a></h3>
                            {/*  <p>{ReactHtmlParser(limitDescription(item.description, 450))}</p> */}

                            <a href={`/blogdetails/${item.slug}`} className="default-btn" target='_blank'>Lire plus <span></span></a>
                          </div>
                        </div>
                      </div>
                    ))):(data.map((item, index) => (
                      <div key={index} className="col-md-6">
                        <div className="single-blog-post">
                          <div className="post-image">
                            <a href="#">{item.photo ? <img src={`${apiURL}${item.photo}`} height={'247px'} alt="image" /> : <img src={dummyImage} height={'247px'} alt="image" />}</a>

                            <div className="date"><i className="flaticon-timetable"></i> {' '}
                              {moment(item.date_post).format('MMM DD, YYYY')}</div>
                          </div>

                          <div className="post-content">
                            <h3><a href="#">{item.titre}</a></h3>
                            {/*  <p>{ReactHtmlParser(limitDescription(item.description, 450))}</p> */}

                            <a href={`/blogdetails/${item.slug}`} className="default-btn" target='_blank'>Lire plus <span></span></a>
                          </div>
                        </div>
                      </div>
                    )))}
                    {}
                  </div>
                </div>
                <div class="col-lg-4 col-md-12">
                  <aside class="widget-area" id="secondary">

                    <section class="widget widget_search" style={{ marginBottom: '20px' }}>
                      <div className="centered-form">
                        {/* Votre interface utilisateur */}
                        <div className="searchBar">
                          <div className="container_searchBar flex justify-between items-center">
                            <Autosuggest
                              suggestions={[]}
                              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                              onSuggestionsClearRequested={onSuggestionsClearRequested}
                              getSuggestionValue={getSuggestionValue}
                              renderSuggestion={renderSuggestion}
                              inputProps={inputProps}
                            />
                            {/*  <span style={{marginTop:"-10px"}}  className="flex justify-center items-center">
                        <i  className={`fa fa-search ${suggestionsVisible ? 'visible' : 'hidden'}`} ref={suggestionListRef}></i>
                    </span>  */}
                          </div>
                        </div>

                        {/* Affichez les Postes filtrés ici */}
                       {/*  {filteredPostes.length > 0 && (
                          <div className={`suggestion-container ${suggestionsVisible ? 'visible' : 'hidden'}`} ref={suggestionListRef}>
                            <div className=" bg-white suggestion_search ">
                              <ul className='p-2 suggestion_list'>
                                {filteredPostes.map((poste, index) => (
                                  <span className='p-1' key={index}>
                                    <a style={{ color: "black" }} href={`/blogdetails/${poste.slug}`}>{poste.titre}</a>
                                    {index < filteredPostes.length - 1 && <hr className="suggestion-divider" />}
                                  </span>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )} */}

                        {/* Affichez la pagination ici si nécessaire */}
                      </div>
                    </section>

                    <section class="widget widget_pearo_posts_thumb widget_search" style={{ marginBottom: '20px' }}>
                      <h3 class="widget-title">Récentes publications</h3>
                      <hr />
                      {datarecentPub.map((item, index) => (
                        <article class="recentePub" key={index}>
                          {item.photo ? <img src={`${apiURL}${item.photo}`} style={{ width: "70px", height: "50px" }} /> : <img src={dummyImage} style={{ width: "70px", height: "50px" }}
                          />}
                          <div class="info">
                            <time datetime="2021-06-30">
                              {moment(item.date_post).format("MMM DD, YYYY")}
                            </time>
                            <h6 class="title usmall">
                              <a href={`/blogdetails/${item.slug}`} target='_blank'>{item.titre}</a>
                            </h6>
                          </div>


                        </article>
                      ))}
                    </section>

                    {/* <section class="widget widget_categories widget_search">
                      <h3 class="widget-title">Catégories</h3>
                      {datacategorie.length > 0 ? (
                        <ul>
                          {datacategorie.map((item, index) => (
                            <li key={index}><a href={`/blog-categorie/${item.slug}`} target='_blank'>{item.titre}</a></li>

                          ))}
                        </ul>
                      ) : (
                        <p>Aucune donnée disponible.</p>
                      )}
                    </section> */}
                  </aside>
                </div>

              </div>
            </div>
          </section>
          <div className="d-flex justify-content-center">
            <nav aria-label="Pagination">

              <ul className="pagination">
                <button className="btn page-link btn  m-2" onClick={handlePreviousPage}>&lt; Previous</button>
                {/* {Array.from({ length: lastPage }, (_, index) => (
                  (index + 1 <= 8 || index + 1 === lastPage) && (
                    <button
                      key={index}
                      onClick={() => handlePageChange(index + 1)}
                      className={`${currentPage === index + 1 ? 'btn btn-primary' : 'page-link'}`}
                    >
                      {index + 1}
                    </button>
                  )
                ))} */}
                {Array.from(
                  { length: lastPage },
                  (_, index) =>
                    ((index + 1 <= 4 && currentPage <= 4) ||
                      (index + 1 >= lastPage - 4 &&
                        currentPage >= lastPage - 4) ||
                      Math.abs(index + 1 - currentPage) <= 1) && (

                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={`${currentPage === index + 1 ? 'btn btn-primary' : 'btn page-link'}`}
                      >
                        {index + 1}
                      </button>

                    )
                )}
                <button className="btn page-link  m-2" onClick={handleNextPage}>Next &gt;</button>
              </ul>

            </nav>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </>
    )
}
