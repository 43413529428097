import React from 'react'
import Header from '../layouts/Header'
import Footer from '../layouts/Footer'

export default function Politiquecookies() {

    return (
        <>
            <div>
                <Header />
            </div>
            <div className="page-title-area page-title-bg-politiques-cookies">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>Politique cookies</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="blog-area ptb-60 pb-70">
                <div className="container" data-aos="fade-up"
                    data-aos-once="false">
                    <div className="col-lg-12 col-md-12 col-sm-12" style={{ marginTop: "-30px" }}>
                        <ol className='politiquecookiesListe' type="I">

                            <li>
                                <h4 className='mt-4'>Objet</h4>
                                <p className='justify-text'>
                                    La présente Politique est établie par BILAN SANTÉ INTERNATIONAL SARL, société éditrice du site internet <a href='/'>www.bilansanteinternational.com</a>   (ci-après le « site web »), immatriculée au RCS de Paris sous le numéro 519 688 550 dont le siège social est situé au 27 boulevard Saint-Martin, 75003 Paris.
                                </p>
                                <p className='justify-text'>
                                    BILAN SANTÉ INTERNATIONAL SARL (ci-après « Nous », « nos », « notre ») s’efforce de vous offrir le meilleur service possible
                                    et souhaite dès lors préserver la confiance que vous portez dans notre entreprise en vous informant de notre politique
                                    d’utilisation et de stockage des cookies.
                                </p>
                            </li>

                            <li>
                                <h4 className='mt-4'>Qu'est-ce qu'un cookie ?</h4>
                                <p className='justify-text'>
                                    Un cookie est un petit fichier texte déposé sur le disque dur de votre ordinateur ou de votre appareil mobile qui est émis
                                    par le serveur du site web que vous consultez.
                                </p>
                                <p className='justify-text'>
                                    Le cookie contient un code unique qui permet à son émetteur de reconnaître le terminal concerné
                                    (votre ordinateur ou votre appareil mobile) à chaque fois que le site web est visité.
                                </p>
                                <p className='justify-text'>
                                    Le cookie identifie le navigateur de votre terminal mais jamais vous personnellement. En outre, le serveur d’un site web peut
                                    uniquement lire les cookies qu’il a lui-même placés et n’a accès à aucune autre information se trouvant sur votre ordinateur
                                    ou sur votre appareil mobile.
                                </p>
                                <p className='justify-text'>
                                    Les cookies peuvent soit être placés par le serveur du site web que vous visitez « cookies d’origine » soit par des partenaires
                                    avec lesquels ce site web collabore « cookies tiers ».
                                </p>
                            </li>

                            <li>
                                <h4 className='mt-4'>Durée de validité d’un cookie</h4>
                                <p className='justify-text'>
                                    Certains cookies expirent lorsque vous fermez votre navigateur « cookies de session »,
                                    alors que d'autres restent plus longtemps sur votre terminal, parfois même jusqu'à ce que vous les supprimiez manuellement
                                    « cookies permanents ».
                                </p>
                                <p className='justify-text'>
                                    De manière générale, les cookies ont une durée de validité limitée. La durée de validité la plus commune est de 30 jours et la durée
                                    maximale de 13 mois à compter du jour où le cookie est déposé sur votre terminal.
                                </p>

                            </li>

                            <li>
                                <h4 className='mt-4'>Objectifs généraux des cookies</h4>
                                <p className='justify-text'>
                                    Le but premier est d’assurer une navigation plus efficace et rapide (p.ex. un cookie retient la langue que vous choisissez,
                                    vous identifie quand vous accédez à votre compte…). Les cookies mémorisent vos préférences et vous permettent ainsi d’accélérer
                                    et de faciliter vos accès au site.
                                </p>
                                <p className='justify-text'>
                                    Ils permettent également de comprendre comment vous naviguez sur notre site web.
                                </p>
                                <p className='justify-text'>
                                    En outre, ils offrent la possibilité de rendre le contenu d’un site web ou la publicité présente sur ce site, plus pertinents pour vous.
                                    Le site sera donc adapté à vos goûts personnels et à vos besoins.
                                </p>

                            </li>

                            <li>
                                <h4 className='mt-4'>Gestion des cookies</h4>
                                <p className='justify-text'>
                                    Pour placer certains cookies sur votre terminal, il est nécessaire que nous obtenions votre consentement préalable et explicite.
                                    Nous obtenons grâce à la bannière placée sur la page d’accueil du site web.
                                </p>
                                <p className='justify-text'>
                                    De plus, la plupart des navigateurs utilisent des cookies. Vous pouvez également supprimer ces cookies ou refuser leur installation
                                    à tout moment et gratuitement en modifiant les options de votre logiciel de navigation. La configuration de chaque navigateur est
                                    différente. Chaque procédure est décrite dans le menu d’aide de votre navigateur. Pour cela, veuillez-vous rendre
                                    sur les liens suivants :

                                    <ul>
                                        <li>Firefox : <a href='https://support.mozilla.org/fr/kb/effacer-les-cookies-pour-supprimer-les-information' target="_blank">https://support.mozilla.org/fr/kb/effacer-les-cookies-pour-supprimer-les-information</a> </li>
                                        <li>Chrome : <a href='https://support.google.com/chrome/answer/95647?hl=fr' target="_blank">https://support.google.com/chrome/answer/95647?hl=fr</a></li>
                                        <li>Safari : <a href='https://support.apple.com/kb/PH21411?locale=nl_NL&viewlocale=fr_FR' target="_blank">https://support.apple.com/kb/PH21411?locale=nl_NL&viewlocale=fr_FR</a></li>
                                        <li>Internet Explorer : <a href='https://support.microsoft.com/fr-be/help/17442/windows-internet-explorer-delete-manage-cookies' target="_blank">https://support.microsoft.com/fr-be/help/17442/windows-internet-explorer-delete-manage-cookies</a></li>
                                    </ul>
                                </p>
                                <p className='justify-text'>
                                    En refusant l’utilisation de cookies, vous pouvez accéder à notre site web. Toutefois, certaines fonctionnalités seront limitées,
                                    voire impossibles.
                                </p>

                            </li>

                            <li>
                                <h4 className='mt-4'>Types de cookies</h4>
                                <ol type="a">
                                    <li>
                                        <h5 className='mt-4'>Cookies techniques</h5>
                                        <p className='justify-text'>
                                            Ces cookies qui ne contiennent que votre adresse IP sont indispensables pour la visite du site. Ils permettent de :
                                            <ul>
                                                <li>surfer sur les différentes pages du site web et profiter de ses fonctionnalités ;</li>
                                                <li>compléter des formulaires ;</li>
                                                <li>assurer la vérification de votre identité lorsque vous accédez à vos données personnelles ;</li>
                                                <li>sécuriser les accès au site web.</li>
                                            </ul>

                                            <p className='justify-text'>
                                                Ces cookies ne nécessitent pas votre consentement puisqu’ils vous permettent, notamment, d’accéder au site web.
                                            </p>
                                        </p>
                                    </li>

                                    <li>
                                        <h5 className='mt-4'>Cookies fonctionnels</h5>
                                        <p className='justify-text'>
                                            Ces cookies facilitent et analysent le fonctionnement de notre site web. Grâce à ceux-ci,
                                            nous pouvons rendre votre expérience de navigation plus agréable et plus personnalisée.
                                            Grâce à eux nous pouvons :
                                            <ul>
                                                <li>personnaliser les services (langue, devise, localisation, données de navigation, etc.) ;</li>
                                                <li>retenir vos choix d’une visite précédente ;</li>
                                                <li>collecter les informations des formulaires en ligne ;</li>
                                                <li>établir des statistiques ;</li>
                                                <li>analyser l’utilisation du site.</li>
                                            </ul>
                                            <p className='justify-text'>
                                                À l’instar des cookies techniques, ces cookies sont utilisées pour améliorer votre navigation et ne nécessitent pas votre consentement.
                                            </p>

                                        </p>
                                    </li>

                                    <li>
                                        <h5 className='mt-4'>Cookies publicitaires</h5>
                                        <p className='justify-text'>
                                            Ces cookies nous donnent des indications concernant vos habitudes de navigation et permettent d’adapter le
                                            contenu publicitaire de notre site web à vos centres d’intérêts.
                                        </p>

                                        <p className='justify-text'>
                                            Sur base de ceux-ci nous pouvons analyser l’efficacité des campagnes publicitaires notamment en identifiant les actions
                                            ultérieures que vous effectuez en cliquant sur ces bannières publicitaires. En outre, sur base des données
                                            de géolocalisation, nous pouvons adapter les annonces qui vous sont faites pour vous faire connaître les
                                            opportunités disponibles près de là où vous êtes.
                                        </p>


                                        <p className='justify-text'>
                                            Ces cookies publicitaires peuvent également permettre de vous envoyer des messages publicitaires ciblés en utilisant
                                            des technologies qui couplent les données en fonction des sites que vous avez visités.
                                        </p>

                                        <p className='justify-text'>
                                            Les informations que nous collectons et partageons ne permettent d’identifier que votre terminal.
                                        </p>
                                        <p className='justify-text'>
                                            Pour ces cookies, votre consentement est nécessaire et nous vous le demandons lors de votre accès sur notre site web.
                                        </p>
                                    </li>

                                    <li>
                                        <h5 className='mt-4'>Cookies analytiques ou de performance</h5>
                                        <p className='justify-text'>
                                            Ces cookies sont utilisés pour recueillir des informations sur la manière dont vous visitez notre site web.
                                            Ceux-ci nous permettent de mesurer l’audience des différents contenus et rubriques de notre site web afin
                                            d’améliorer ceux-ci.
                                        </p>

                                        <p className='justify-text'>
                                            Grâce à ces cookies nous pouvons détecter des problèmes de navigation et améliorer l’ergonomie de notre site web.
                                        </p>

                                        <p className='justify-text'>
                                            Ces données sont rassemblées et sont donc complètement anonymisées.
                                        </p>

                                        <p className='justify-text'>
                                            Pour ces cookies, nous avons besoin de votre consentement.
                                        </p>
                                    </li>

                                    <li>
                                        <h5 className='mt-4'>Cookies de réseaux sociaux</h5>
                                        <p className='justify-text'>
                                            Les cookies « Réseaux sociaux » partagent les contenus que vous choisissez de partager via des boutons applicatifs
                                            qui appartiennent à des réseaux sociaux. Lorsque vous utilisez ces boutons, un cookie tiers est installé sur votre
                                            terminal et si vous êtes connecté au réseau social, cette information sera ajoutée à votre profil.
                                        </p>

                                        <p className='justify-text'>
                                            Concernant ces cookies, il convient de vérifier sur chaque réseau social quelle est l’étendue de la collecte de données et
                                            l’utilisation de celles-ci.
                                        </p>

                                        <p className='justify-text'>
                                            Ces cookies nécessitent également votre consentement.
                                            <ul>
                                                <li>Facebook : <a href='https://fr-fr.facebook.com/policies/cookies/' target="_blank">https://fr-fr.facebook.com/policies/cookies/</a> </li>
                                                <li>Twitter : <a href='https://help.twitter.com/fr/safety-and-security/twitter-do-not-track' target="_blank">https://help.twitter.com/fr/safety-and-security/twitter-do-not-track</a></li>
                                                <li>Google : <a href='https://support.google.com/accounts/answer/61416?hl=fr/' target="_blank">https://support.google.com/accounts/answer/61416?hl=fr/</a></li>
                                                <li>Instagram : <a href='https://help.instagram.com/1896641480634370' target="_blank">https://help.instagram.com/1896641480634370</a></li>
                                            </ul>
                                        </p>
                                    </li>

                                    <li>
                                        <h5 className='mt-4'>Cookies tiers</h5>
                                        <p className='justify-text'>
                                            Ces cookies sont déposés par des tiers sur notre site web et puis sur votre terminal. Nous n’avons pas le contrôle de ceux-ci.
                                            Pour en savoir plus, veuillez consulter les informations fournies par ces sites tiers sur leurs propres cookies.
                                        </p>
                                    </li>

                                </ol>

                            </li>

                            <li>
                                <h4 className='mt-4'>Vos droits sur le traitement de vos données à caractère personnel</h4>
                                <p className='justify-text'>
                                    Les cookies qui traitent des données à caractère personnel sont soumis aux règles en matière de protection des données à caractère
                                    personnel. Dans ce cadre, vous disposez de certains droits (droit d’accès, un droit de rectification, un droit d’opposition,
                                    un droit de retrait du consentement, etc.).
                                </p>
                                <p className='justify-text'>
                                    Nous vous renvoyons vers notre Politique vie privée (<a href='/politiquevieprivee'>www.bilansanteinternational.com/politique-vie-privee</a>) qui reprend le détail de
                                    vos droits et les conditions d’application de ceux-ci.
                                </p>
                            </li>

                            <li>
                                <h4 className='mt-4'>Contact</h4>
                                <p className='justify-text'>
                                    <ul>
                                        <li>Par email : <a href='mailto:info@bilansanteinternational.com'>info@bilansanteinternational.com</a>
                                        </li>
                                        <li>Par courrier : 27 boulevard Saint-Martin, 75003 Paris - France</li>
                                    </ul>
                                </p>
                            </li>

                            <li className='mt-4'>
                                <h4>Modification</h4>
                                <p className='justify-text'>
                                    Nous nous réservons le droit de modifier à tout moment les dispositions de la présente Politique.
                                    Nous publierons les modifications directement sur notre site web.
                                </p>
                            </li>

                            <li>
                                <h4 className='mt-4'>Droit applicable et juridictions compétentes</h4>
                                <p className='justify-text'>
                                    La présente Politique est régie par le droit national de notre lieu d'établissement principal repris ci-avant.
                                </p>
                                <p className='justify-text'>
                                    Tout litige relatif à l’interprétation ou l’exécution de la présente Politique sera soumis aux juridictions de ce droit national.
                                </p>
                                <p className='justify-text'>
                                    La présente version de la Politique entre en vigueur et a été mise à jour en date du O1/08/2022.
                                </p>
                            </li>
                        </ol>
                    </div>
                </div>
            </section>
            <div>
                <Footer />
            </div>
        </>
    )
}
