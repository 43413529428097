import React from 'react'
import nos_valeurs from '../styles/img/nos_valeurs.png'
import cartographie_image from '../styles/img/cartographie.png'
import { useEffect, useState } from "react";
import moment from 'moment';
import 'moment/locale/fr';
import { apiURL, getEntity } from "../services/api";
import ReactHtmlParser from 'react-html-parser';
import Loader from '../components/Loader';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
export default function Ebsi() {

    const [data, empdatachange] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [lastPage, setLastPage] = useState(1);
    const [datassurance, empdatassurance] = useState([]);
    useEffect(() => {
        setIsLoading(true)
        moment.locale('fr');
        getEntity(`/realisation?page=${currentPage}`).then((res) => {
            //return res.data.data;
            empdatachange(res.data.data);
            setLastPage(res.data.last_page);
            setIsLoading(false);

        }).catch((err) => {
            console.log(err.message);
            setIsLoading(false);
        });

        //assurance
        getEntity("/assurance-confiant").then((res) => {
            empdatassurance(res.data.data);


        }).catch((err) => {
            console.log(err.message);
        });

    }, [currentPage]);
    //finction de pagination
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < lastPage) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Affichez les éléments de la page courante
    const itemsPerPage = 1; // Nombre d'éléments à afficher par page
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    const displayedItems = data.slice(startIdx, endIdx);

    return isLoading ?
        <Loader /> : (
            <>
                <div style={{ overflowX: 'hidden' }}>
                    <div>
                        <Header />
                    </div>

                    <div className="page-title-area page-title-ebsi" >
                        <div className="d-table">
                            <div className="d-table-cell">
                                <div className="container">
                                    <div className="page-title-content">
                                        <h2>e-bsi, Créateur d'assurtechs !</h2>
                                        <p className="mt-2">
                                            Digitalisation de produits d'assurance et développement de progiciels métiers : c'est votre chiffre d'affaires qui va exploser 😉
                                        </p>
                                        <p>
                                            Consultez nos références et parlons de vos projets !
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="insurance-details-area mt-60 ptb-60">
                        <div className="container" data-aos="fade-up" data-aos-once="false" >
                            <div className="insurance-details-header">
                                <div className="row align-items-center">
                                    <div className="col-lg-8 col-md-12 col-12" data-aos="fade-left" data-aos-once="false">
                                        <div className="section-title-left">
                                            <h2>UNE "ASSURTECH" ENGAGÉE À VOS CÔTÉS.</h2>
                                        </div>
                                        <div className="content">
                                            <p className='justify-text'>
                                                <b style={{ color: "#C14742", fontSize: "20px" }}>e-bsi</b> est notre département “assurtech” dédié à la
                                                transformation digitale des assureurs : <b>Business System for
                                                    Insurance</b>.
                                            </p>
                                            <p className='justify-text'>
                                                Nous sommes experts métiers de l’assurance et utilisateurs
                                                : c’est pourquoi, mieux que personne, nous savons ce
                                                qu’assurés et intermédiaires attendent de leur assureur 3.0.
                                            </p>
                                            <p className='justify-text'>
                                                Pour digitaliser l’assurance, l’expertise technique est un
                                                prérequis mais également la compréhension du parcours
                                                client et le marketing produit. <b>Chez BSI, nous regroupons
                                                    ces différentes compétences pour faire avancer vos
                                                    projets avec une approche globale, en assurances IARD ou
                                                    Vie.</b>
                                            </p>

                                            <p className='justify-text'>
                                                Pour nos clients (compagnies d’assurance ou courtiers){" "}
                                                <b>nous concevons des outils web et des progiciels</b> dernière
                                                génération, indispensables à leur développement
                                                commercial comme à leur gestion : solution digitale
                                                innovante, ergonomique et sécurisée 100% RGPD.
                                            </p>

                                            <p className='justify-text'>
                                                <b>Avec notre conseil, ce ne sont pas les informaticiens qui
                                                    dictent leurs lois mais les utilisateurs !</b>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-12 col-12" data-aos="fade-right" data-aos-once="false">
                                        <div className="image text-center">
                                            <img className='fa-beat-fade' style={{ '--fa-animation-duration': "3s" }} src="assets/img/Logo-ebsi.webp" alt="e-bsi" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>



                    <section className="insurance-details-area ptb-60 bg-black-color">
                        <div className="container" data-aos="fade-up" data-aos-once="false">
                            <div className="insurance-details-header">
                                <div className="row align-items-center">
                                    <div className="col-lg-4 col-md-12 col-12" data-aos="fade-left">
                                        <div className="image text-center">
                                            <img src="assets/img/gagnez_en_performance.webp" alt="image" />
                                        </div>
                                    </div>

                                    <div className="col-lg-8 col-md-12 col-12" data-aos="fade-right">
                                        <div className="section-title-left">
                                            <h2>GAGNEZ EN CONFORT ET PERFORMANCE</h2>
                                        </div>
                                        <div className="content">
                                            <p className='justify-text'>
                                                Nous sommes <b>spécialistes de la zone CIMA</b> et de ses
                                                réglementations particulières. Ceci nous donne dans cette
                                                zone une longueur d’avance sur les SSII ou ESN
                                                traditionnelles et leurs équipes généralement issues du
                                                monde informatique et non de l’assurance.
                                            </p>
                                            <p className='justify-text'>
                                                Nous sommes en effet implantés également au Bénin
                                                comme assureur-conseil et pour <b>e-bsi</b> nous nous appuyons
                                                localement sur une équipe de développeurs et graphistes
                                                brillants, dynamiques et réactifs pour relever vos défis : nos
                                                propositions sont dès lors généralement <b>tarifairement plus
                                                    compétitives</b> que celles de nos confrères en Europe ou
                                                ailleurs. <b>Nous garantissons les délais de livraison et le SAV.</b>

                                            </p>
                                            <p className='justify-text'>
                                                Nous concevons des plateformes web et par extension
                                                proposons aux assureurs santé <b>nos services de
                                                    souscription médicale pour compte</b>, avec notre
                                                département interne et son médecin conseil. Vous pouvez
                                                par exemple visiter <b><a href='https://aps-benin.com/'>ICI</a></b> notre réalisation pour l’APS,
                                                association d’assurés avec Atlantique Assurances Bénin. Ou
                                                encore la <b><a href='https://tarification.aabvie.net/'>marketplace d’Atlantique Assurance Vie.</a></b>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="insurance-details-area ptb-60">
                        <div className="container" data-aos="fade-up" data-aos-once="false">
                            <div className="insurance-details-header">
                                <div className="section-title">
                                    <h2>INGÉNIERIE D'ASSURANCES</h2>
                                </div>
                                <div className="row ">
                                    <div className="col-lg-6 col-md-12 col-12" data-aos="fade-left">
                                        <div className="content">
                                            <p className='justify-text'>
                                                <b>Chaque client a des besoins spécifiques sur son marché.</b>
                                            </p>
                                            <p className='justify-text'>
                                                <p className='justify-text'>
                                                    Nous pouvons accompagner nos partenaires dans les <br />différentes étapes de
                                                    la vie d'un contrat d'assurance :
                                                </p>

                                                <ul>
                                                    <li>rédaction wording / relooking</li>
                                                    <li>documents techniques et commerciaux</li>
                                                    <li>modélisation tarifaire</li>
                                                    <li>réassurance</li>
                                                    <li>souscription médicale</li>
                                                    <li>digitalisation</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12 col-12" data-aos="fade-right">
                                        <div className="content">
                                            <p className='justify-text'>
                                                Comme nous sommes convaincus et passionnés, nous pensons que <b>la
                                                    digitalisation de nos réalisations est un fort vecteur de croissance</b> pour nos
                                                clients, indispensable à l'ère du numérique : nous nous sommes donnés les moyens de les
                                                accompagner en nous entourant de notre équipe de développeurs.
                                            </p>

                                            <p className='justify-text'>
                                                Pour digitaliser un contrat et des processus, nous maîtrisons parfaitement le cahier
                                                des charges et le fil d'Ariane... Nos développements s'intègrent dans les sites
                                                internet de nos clients et sont API compatibles.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="portfolio" className="portfolio ptb-60 bg-black-color" >
                        <div className="container" data-aos="fade-up" data-aos-once="false">
                            <div className="section-title">
                                <h2>CARTOGRAPHIE CHAÎNE DE VALEURS</h2>
                                <p className=''> Une solution complète et personnalisable pour gérer vos produits et vos intermédiaires de A à Z.</p>
                                <div><img src={cartographie_image} alt="cartographie_image" /></div>
                            </div>
                        </div>
                    </section>

                    <section id="portfolio" className="portfolio ptb-60" >
                        <div className="container" data-aos="fade-up" data-aos-once="false">
                            <div className="section-title ">
                                <h2>FONCTIONNALITÉS PRINCIPALES DE NOS MARKETPLACES</h2>
                            </div>

                            <div className="row insurance-details-desc" style={{ marginTop: "-30px" }}>
                                <div className="col-lg-6 col-md-6 col-12" >
                                    <ul className="features-list">
                                        <li> Tunnel de tarification et de souscription</li>
                                        <li> Extranets Clients et Intermédiaires</li>
                                        <li> Gestion et suivi exploitation</li>
                                        <li> Développement commercial</li>
                                    </ul>
                                </div>

                                <div className="col-lg-6 col-md-6 col-12">
                                    <ul className="features-list">
                                        <li> GED, signature électronique, outils CRM</li>
                                        <li> Business Intelligence</li>
                                        <li> Sécurité et gestion des habilitations</li>
                                        <li> Interopérabilité : API/EDI/Import/Export</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section className="insurance-details-area ptb-60 bg-black-color">
                        <div className="container" data-aos="fade-up" data-aos-once="false">
                            <div className="insurance-details-desc">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12">
                                        <img src={nos_valeurs} alt="nos_valeurs" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="portfolio" className="portfolio ptb-60">
                        <div className="container" data-aos="fade-up" data-aos-once="false">
                            <div className="section-title">
                                <h2>NOS DERNIÈRES RÉALISATIONS</h2>
                            </div>

                            <div className="row">

                                {data.map((item, index) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" data-aos="fade-left" key={index}>
                                        <div className="card text-center realisation-section">

                                            <div className="content">
                                                <h3><a href={item.lien}>{item.titre}</a></h3>

                                                <p className='justify-text'>{ReactHtmlParser(item.description)}
                                                </p>
                                            </div>

                                            <div className="text-center mt-2">
                                                <img src={`${apiURL}${item.photo}`} style={{ width: '80px' }} alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                ))}


                            </div>

                        </div>
                    </section>
                    <div className="d-flex justify-content-center">
                        <nav aria-label="Pagination">

                            <ul className="pagination">
                                <button className="page-link btn  m-2" onClick={handlePreviousPage}>&lt; Previous</button>
                                {Array.from(
                                    { length: lastPage },
                                    (_, index) =>
                                        ((index + 1 <= 4 && currentPage <= 4) ||
                                            (index + 1 >= lastPage - 4 &&
                                                currentPage >= lastPage - 4) ||
                                            Math.abs(index + 1 - currentPage) <= 1) && (

                                            <button
                                                key={index}
                                                onClick={() => handlePageChange(index + 1)}
                                                className={`${currentPage === index + 1 ? 'btn btn-primary' : 'btn page-link'}`}
                                            >
                                                {index + 1}
                                            </button>

                                        )
                                )}
                                <button className="page-link btn  m-2" onClick={handleNextPage}>Next &gt;</button>
                            </ul>

                        </nav>
                    </div>
                    <section className="blog-area bg-black-color ptb-60">
                        <div className="container" data-aos="fade-up" data-aos-once="false">
                            <div className="section-title" data-aos="fade-up" data-aos-once="false">
                                <h2>Ils nous ont fait confiance</h2>
                            </div>
                            <div
                                className="col-lg-12 col-md-12 col-sm-12 text-center"
                                style={{ marginTop: "-30px" }}
                            >
                                <div className="container">
                                    <div className="partner-slides ">
                                        <Swiper
                                            autoplay={{ delay: 3000, disableOnInteraction: true }}
                                            // observer={false}
                                            // observeslidechildren={false}
                                            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                            // spaceBetween={15}
                                            slidesPerView={1}
                                            // navigation
                                            // pagination={{ clickable: true }}
                                            // scrollbar={{ draggable: false }}
                                            onSlideChange={() => console.log("slide change")}
                                            onSwiper={(swiper) => {
                                                setTimeout(() => {
                                                    swiper.update(); // ------> this solution
                                                }, 3000);
                                            }}
                                            breakpoints={{
                                                200: {
                                                    width: 200,
                                                    slidesPerView: 1,
                                                },
                                                375: {
                                                    width: 375,
                                                    slidesPerView: 1,
                                                },
                                                300: {
                                                    width: 300,
                                                    slidesPerView: 1,
                                                },
                                                400: {
                                                    width: 400,
                                                    slidesPerView: 1,
                                                },
                                                640: {
                                                    width: 600,
                                                    slidesPerView: 2,
                                                },
                                                768: {
                                                    width: 768,
                                                    slidesPerView: 3,
                                                },
                                                900: {
                                                    width: 900,
                                                    slidesPerView: 4,
                                                },
                                            }}


                                        >

                                            {
                                                datassurance.map((item, index) => (
                                                    <SwiperSlide key={index}>
                                                        <a
                                                            href={`${item.lien}`}
                                                            className="single-assurance-item "
                                                        >
                                                            <img src={`${apiURL}${item.logo}`} alt={`image-${item.nom}`} />
                                                        </a>
                                                    </SwiperSlide>
                                                ))
                                            }

                                        </Swiper>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                    <div>
                        <Footer />
                    </div>
                </div>
            </>
        )
}
